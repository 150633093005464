
<template>
  <div>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      top
      :timeout="snackbar.timeout"
      >
      <v-row>
        <v-icon color="white" class="mx-3">{{ snackbar.icon}}</v-icon>
        <span class="py-2 white--text">{{ snackbar.text}}</span>
      </v-row>
    </v-snackbar>

    <v-container grid-list-md class="ma-0 pa-0" :class="{'pb-4': !selectClient}" fluid>
      <v-layout row wrap>
        <companySelector v-if="!selectClient" :showBranches="false" @companyChanged="loadData"></companySelector>
        <v-flex md6 xs12 v-if="!loading && selectClient" >
          <div>
            <v-btn
              fab dark small
              style="float: left; top: 6px;"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              depressed
              @click="backToClients()"
              >
              <v-icon darken>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="pl-2 headerName" style="display: flex; align-items: center;">
              <div class="avatarGen header-avatar" v-html="genAvatar(selectedClient.name + ' ' + selectedClient.surname)"></div>
              <h2 style="margin-left: 10px;">{{selectedClient.name}} {{selectedClient.surname}} <v-icon color="text" v-if="selectedClient.type == 'company'">mdi-domain</v-icon></h2>

              <v-chip v-if="selectedClient.minor" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" small left>mdi-human-female-boy</v-icon>
                {{ $t('general.'+ selectedClient.minorType, $store.state.locale) }}
              </v-chip>

            </div>
          </div>
        </v-flex>
        <v-flex :class="headerBarClass" xs12>
          <div v-if="editClient">
            <div class="text-right">
              <v-tooltip dark left v-if="selectClient">
                <template v-slot:activator="{on}">
                  <v-btn
                    v-on="on"
                    dark
                    small
                    fab
                    outlined
                    depressed
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                    class="mx-2"
                    @click="openMergClientDialog()"
                    >
                    <v-icon darken>mdi-call-merge</v-icon>
                  </v-btn>
                </template>
                <span class="tooltip-text-white">{{ $t('clients.mergeClientTooltip', $store.state.locale) }}</span>
              </v-tooltip>
              <v-btn
                v-if="selectClient"
                dark
                small
                fab
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                class="mx-2"
                @click="showDeleteDialog()"
                >
                <v-icon darken>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="text-right">
            <v-tooltip dark left v-if="!selectClient">
              <template v-slot:activator="{on}">
                <v-btn
                  v-on="on"
                  dark
                  small
                  fab
                  depressed
                  :disabled="clientExportDisabled || !editClient"
                  :loading="clientExportLoading"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                  class="mx-2"
                  @click="downloadClientsCSV()"
                  >
                  <v-icon darken>mdi-download</v-icon>
                </v-btn>
              </template>
              <span class="tooltip-text-white" v-if="clientFilterActive == 'all' && clientsFiltered.length > filteredClientsExport.length">{{ $t('clients.viewedClient', $store.state.locale) }} ({{ filteredClientsExport.length }}) {{ $t('clients.downloadCsv', $store.state.locale) }}</span>

              <span class="tooltip-text-white" v-else>{{ $t('clients.viewedClients', $store.state.locale) }} ({{ clientsFiltered.length }}) {{ $t('clients.downloadCsv', $store.state.locale) }}</span>
            </v-tooltip>

            <!-- <v-btn
              v-if="!loading && clients.length > 0 && !selectClient" 
              dark 
              outlined
              depressed
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
              class="mx-2"
              :disabled="!editClient"
              @click="addCompanyClientDialog = true"
              >
              {{ $t('clients.newCompany', $store.state.locale) }} 
            </v-btn> -->
            <v-btn
              v-if="!loading && !selectClient" 
              dark
              depressed
              :disabled="!editClient"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              class="mx-2"
              @click="addClient()"
              >
              {{ $t('clients.newClient', $store.state.locale) }} 
            </v-btn>
          </div>
          
        </v-flex>
      </v-layout>
    </v-container>

    <div v-if="loading" style="position: relative; padding: 200px 0;">
      <div class="thrive-loader"></div>
    </div>

    <div v-if="loading == false && selectClient == false">
      <v-container grid-list-md class="ma-0 pa-0">
        <v-layout row wrap>
          <v-flex md3 class="pr-3">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"  class="theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%">
              <v-text-field
                v-if="!loading && !selectClient"
                v-model="search"
                outlined
                dense
                hide-details
                background-color="background"  
                name="searchfield"
                :label=" $t('general.search', $store.state.locale)"
                prepend-inner-icon="mdi-magnify"
                single-line
                class="ma-2"
                append-icon="mdi-close"
                @click="filterClientView('all')"
                @click:append="search = '', filterClientView('all'), clientCounter++"
              ></v-text-field>

              <v-list class="pa-0 ma-0" dense>
                  <v-divider></v-divider>
                  <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('today')" @click="filterClientView('today')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('general.today', $store.state.locale) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('tomorrow')" @click="filterClientView('tomorrow')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('general.tomorrow', $store.state.locale) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('week')" @click="filterClientView('week')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('clients.week', $store.state.locale) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('all')" @click="filterClientView('all')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('clients.allClients', $store.state.locale) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!-- <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('companyaccounts')" @click="filterClientView('companyaccounts')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('clients.companyAccounts', $store.state.locale) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('companies')" @click="filterClientView('companies')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('general.companies', $store.state.locale) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider> -->
                  <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('leads')" @click="filterClientView('leads')">
                    <v-list-item-content>
                      <v-list-item-title>{{ $t('clients.leads', $store.state.locale) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
              </v-list>
            </v-card>

            <!-- CUSTOM FILTERS -->
            <div class="mt-4" v-if="customClientFilters.length > 0" >
              <h3 class="pa-2" >{{ $t('clients.customClientFilters', $store.state.locale) }}</h3>
              <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"  class="theme-card theme-shadow text-center" :class="$store.state.companyTheme" style="width: 100%">
                <v-list class="pa-0 ma-0" dense>
                    <span v-for="(customClientFilter, index) in customClientFilters" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                    <v-divider v-if="index > 0"></v-divider>
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView(customClientFilter.id)" @click="filterClientViewCustom(customClientFilter)">
                      <v-list-item-content >
                        <v-list-item-title class="text-left">{{ customClientFilter.name }} </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon class="py-1 mr-2" color="text" small @click.stop="editClientFilter(customClientFilter)">mdi-pencil</v-icon>
                        <v-icon class="py-1 mx-2" color="text" small @click.stop="removeClientFilter(customClientFilter, index)">mdi-delete</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    </span>
                </v-list>
              </v-card>
            </div>

            <div class="text-center ma-4">
              <v-btn
                dark
                small
                fab
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="newClientFilter()"
                >
                <v-icon darken>mdi-plus</v-icon>
              </v-btn>
            </div>

          </v-flex>

          <v-flex md9>
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"  class="theme-card theme-shadow" :class="$store.state.companyTheme" v-if="(search.length > 0 && clientsCount > 0) || search.length == 0 ">
              

              <div v-if="clientView == 'clients'">
                <div class="text-center client-count" v-if="!clientLoading">
                  <div v-if="clientFilterActive == 'companyaccounts'"> 
                    <p class="no-clients" v-if="clientsCount == 0">{{ $t('clients.noCompanyClients', $store.state.locale) }}</p>
                    <p v-if="clientsCount == 1">{{ clientsCount }} {{ $t('clients.companyClient', $store.state.locale) }}</p>
                    <p v-if="clientsCount > 1">{{ clientsCount }} {{ $t('clients.companyClients', $store.state.locale) }}</p>
                  </div>

                  <div v-else-if="clientFilterActive == 'today'"> 
                    <p class="no-clients" v-if="clientsCount == 0">{{ $t('clients.noClientsToday', $store.state.locale) }}</p>
                    <p v-if="clientsCount == 1">{{ clientsCount }} {{ $t('clients.clientToday', $store.state.locale) }}</p>
                    <p v-if="clientsCount > 1"> {{ clientsCount }} {{ $t('clients.clientsToday', $store.state.locale) }}</p>
                  </div>

                  <div v-else-if="clientFilterActive == 'tomorrow'"> 
                    <p class="no-clients" v-if="clientsCount == 0">{{ $t('clients.noClientsTomorrow', $store.state.locale) }}</p>
                    <p v-if="clientsCount == 1">{{ clientsCount }} {{ $t('clients.clientTomorrow', $store.state.locale) }}</p>
                    <p v-if="clientsCount > 1">{{ clientsCount }} {{ $t('clients.clientsTomorrow', $store.state.locale) }}</p>
                  </div>

                  <div v-else-if="clientFilterActive == 'week'"> 
                    <p class="no-clients" v-if="clientsCount == 0">{{ $t('clients.noClientsWeek', $store.state.locale) }}</p>
                    <p v-if="clientsCount == 1">{{ clientsCount }} {{ $t('clients.clientWeek', $store.state.locale) }}</p>
                    <p v-if="clientsCount > 1">{{ clientsCount }} {{ $t('clients.clientsWeek', $store.state.locale) }}</p>
                  </div>
                  <div v-else-if="clientFilterActive == 'all'"> 
                    <p class="no-clients" v-if="clientsCount == 0">{{ $t('clients.noClients', $store.state.locale) }}</p>
                    <p v-if="clientsCount == 1">{{ clientsCount }} {{ $t('clients.clientSent', $store.state.locale) }} </p>
                    <p v-if="clientsCount > 1">{{ clientsCount }} {{ $t('clients.clientsSent', $store.state.locale) }} </p>
                  </div>
                  <div v-else> 
                    <p class="no-clients" v-if="clientsCount == 0">{{ $t('clients.noClients', $store.state.locale) }}</p>
                    <p v-else-if="clientsCount > filteredClientsExport.length && filteredClientsExport.length == 1">{{ filteredClientsExport.length }} {{ $t('clients.clientSent', $store.state.locale) }}.</p>
                    <p v-else-if="clientsCount > filteredClientsExport.length">{{ filteredClientsExport.length }} {{ $t('clients.clientsSent', $store.state.locale) }}.</p>
                    <p v-else-if="clientsCount == 1">{{ clientsCount }} {{ $t('clients.clientSent', $store.state.locale) }}.</p>
                    <p v-else-if="clientsCount > 1">{{ clientsCount }} {{ $t('clients.clientsSent', $store.state.locale) }}.</p>
                  </div>
                </div>

                <div class="no-data-message" v-if="!loading && !clientLoading && clients.length == 0 && search.length == 0 && clientFilterActive == 'all'">
                  <v-avatar
                    size="58"
                    light
                    class="ma-0 mr-2 custom-avatar-style" 
                    rounded="lg"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                    >
                    <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-multiple</v-icon>
                  </v-avatar>
                  <p>{{ $t('clients.noClientsAdded', $store.state.locale) }}</p>
                  <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addClient()">
                    <v-icon left>mdi-plus</v-icon> {{ $t('clients.newClient', $store.state.locale) }} 
                  </v-btn>
                </div>

                <div v-if="clientSource == 'algolia'">
                  <div v-if="pageCount > 1" class="text-center pt-2">
                    <v-pagination :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :total-visible="9" v-model="clientPage" :length="pageCount"  @input="selectClientPage()"></v-pagination>
                  </div>

                  <v-data-table
                    v-if="!clientLoading && clientsCount > 0"
                    :headers="headers"
                    :items-per-page="itemsPerPage"
                    :items="clients"
                    hide-default-footer
                    :no-results-text="$t('clients.noResultsFound', $store.state.locale)"
                    :no-data-text="$t('clients.noClientsFound', $store.state.locale)"
                    dense
                    :key="clientCounter"
                    class="dense-table"
                    >
                    <template v-slot:item="{item}">
                      <tr @click="selectUser(item)">
                        <td>                    
                          <div style="display: block; float: left;" v-html="genAvatar(item.name + ' ' + item.surname)" class="avatarGenTable" ></div>
                        </td>
                        <td><v-icon v-if="getIcon(item.icon).icon" :color="getIcon(item.icon).color" small>{{ getIcon(item.icon).icon }}</v-icon></td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.surname }}
                          <v-chip v-if="item.company" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" ><v-icon class="ml-0" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" left>mdi-domain</v-icon>{{ item.company }}</v-chip>
                          <v-chip v-if="item.tags && item.tags.length > 0" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style" >Lead</v-chip>
                          <v-chip v-if="item.minor" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" >
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" x-small left>mdi-human-female-boy</v-icon>
                            {{ $t('general.'+ item.minorType, $store.state.locale) }}
                          </v-chip>
                        </td>
                        <td>
                          <span v-for="(phone, index) in item.phones" :key="index">
                            <span v-if="phone.primary">{{ phone.phone.national }}</span>
                          </span>
                        </td>
                        <td>
                          <span v-for="(email, index) in item.emails" :key="index">
                            <span v-if="email.primary">{{ email.email }}</span>
                          </span>
                        </td>
                        <td>
                          <span v-for="gender in genders" :key="gender.id">
                            <span v-if="gender.id == item.gender">{{ gender.name }}</span>
                          </span>
                        </td>
                        <td>
                          <v-btn color="background" icon depressed  >
                            <v-icon color="text" small>mdi-chevron-right</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>

                <div v-else>
                  <div v-if="clientFirebasePageCount > 1" class="text-center pt-2">
                    <v-pagination :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :total-visible="9" v-model="clientFirebasePage" :length="clientFirebasePageCount"  ></v-pagination>
                  </div>

                  <v-data-table
                    v-if="!clientLoading && clientsCount > 0"
                    :headers="headers"
                    :items-per-page="itemsPerPage"
                    :items="clients"
                    hide-default-footer
                    :no-results-text="$t('clients.noResultsFound', $store.state.locale)"
                    :no-data-text="$t('clients.noClientsFound', $store.state.locale)"
                    dense
                    :page.sync="clientFirebasePage"
                    @page-count="clientFirebasePageCount = $event"
                    class="dense-table"
                    >
                    <template v-slot:item="{item}">
                      <tr @click="selectUser(item)">
                        <td> <div v-html="genAvatar(item.name + ' ' + item.surname)" class="avatarGenTable" ></div> </td>
                        <td> <v-icon v-if="getIcon(item.icon).icon" :color="getIcon(item.icon).color" small>{{ getIcon(item.icon).icon }}</v-icon></td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.surname }}
                          <v-chip v-if="item.company" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" ><v-icon class="ml-0" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" left>mdi-domain</v-icon>{{ item.company }}</v-chip>
                          <v-chip v-if="item.tags && item.tags.length > 0" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style" >Lead</v-chip>
                          <v-chip v-if="item.minor" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" >
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" x-small left>mdi-human-female-boy</v-icon>
                            {{ $t('general.'+ item.minorType, $store.state.locale) }}
                          </v-chip>
                        </td>
                        <td>
                          <span v-for="(phone, index) in item.phones" :key="index">
                            <span v-if="phone.primary">{{ phone.phone.national }}</span>
                          </span>
                        </td>
                        <td>
                          <span v-for="(email, index) in item.emails" :key="index">
                            <span v-if="email.primary">{{ email.email }}</span>
                          </span>
                        </td>
                        <td>
                          <span v-for="gender in genders" :key="gender.id">
                            <span v-if="gender.id == item.gender">{{ gender.name }}</span>
                          </span>
                        </td>
                        <td>
                          <v-btn color="background" icon depressed  >
                            <v-icon color="text" small>mdi-chevron-right</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>

                
              </div>
              <div v-else>
                <div class="text-center client-count" v-if="!clientLoading">
                  <p class="no-clients" v-if="clientFilterActive == 'companies' && compClients.length == 0">{{ $t('clients.noCompanies', $store.state.locale) }}</p>
                  <p v-if="clientFilterActive == 'companies' && compClients.length == 1">{{ compClients.length }} {{ $t('clients.companySent', $store.state.locale) }}</p>
                  <p v-if="clientFilterActive == 'companies' && compClients.length > 1">{{ compClients.length }} {{ $t('clients.companiesSent', $store.state.locale) }}.</p>
                </div>

                <div v-if="compClients.length > itemsPerPage" class="text-center pt-2">
                  <v-pagination :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :total-visible="9" :disabled="clientLoading" v-model="compClientPage" :length="compClientPageCount"></v-pagination>
                </div>

                <v-data-table
                  v-if="!clientLoading && compClients.length > 0"
                  :headers="headersCompClients"
                  :items-per-page="itemsPerPage"
                  :items="compClients"
                  hide-default-footer
                  :page.sync="compClientPage"
                  @page-count="compClientPageCount = $event"
                  dense
                  class="dense-table"
                  >
                  <template v-slot:item="{item}">
                    <tr>
                      <td>{{ item.name }}</td>
                      <td>{{ item.phone }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.coc }}</td>
                      <td>{{ item.vat }}</td>
                      <td>
                        <v-btn icon  @click="editCompClient(item)" >
                          <v-icon color="text" small>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>

              <div v-if="clientLoading" style="position: relative; padding: 200px 0;">
                <div class="thrive-loader"></div>
              </div>
            </v-card>

            <div v-if="clientFilterActive == 'all' && clientsData && clientsCount == 0 && search && search.length > 0" class="text-center ma-4 pa-4">
              <v-avatar
                size="58"
                light
                class="ma-0 mr-2 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-magnify</v-icon>
              </v-avatar>
              <p class="pa-4">{{ $t('clients.noResultsFound', $store.state.locale) }}</p>
            </div>

            
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <div v-show="!loading && selectClient" class="special-content-box client">
      <client-information type="page" :selectedClient="selectedClient" :selectedClientOriginal="selectedClientOriginal" :componentType="'CLients'"></client-information>
    </div>
        
    <!-- / New Custom Client Filter Dialog \ -->
      <add-custom-clientfilter-form
        :criteria="criteria"
        :type="'list'"
        @updateData="updateCustomClientFilters"
      ></add-custom-clientfilter-form>
    <!-- \ New Custom Client Filter Dialog / -->

    <!-- / New Custom Client Filter Dialog \ -->
      <edit-custom-clientfilter-form
        :index="selectedClientFilterIndex"
        :selectedClientFilter="selectedClientFilter"
        :selectedClientFilterOriginal="selectedClientFilterOriginal"
        :criteria="criteria"
        @updateData="updateCustomClientFilters"
      ></edit-custom-clientfilter-form>
    <!-- \ New Custom Client Filter Dialog / -->


    <!-- / New ClientCompany Dialog \ -->
    <v-dialog v-model="addCompanyClientDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <add-companyclient-form
        @newClientCompDialogChange="addCompanyClientDialog = $event"
        @updateData="updateDataCompClient()"
      ></add-companyclient-form>
    </v-dialog>
    <!-- \ New ClientCompany Dialog / -->

    <!-- / Edit ClientCompany Dialog \ -->
    <v-dialog v-model="editCompanyClientDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <edit-companyclient-form
        :selectedCompanyOriginal="selectedCompanyOriginal"
        :selectedCompany="selectedCompany"
        @editCompClientDialogChange="editCompanyClientDialog = $event"
        @updateData="updateDataCompClient()"
      ></edit-companyclient-form>
    </v-dialog>
    <!-- \ Edit ClientCompany Dialog / -->

    <!-- / Delete Client Dialog \ -->
    <v-dialog v-model="deleteClientDialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <p class="ma-0">De cliënt zal worden verwijderd. Dit kan niet ongedaan worden gemaakt.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="deletingClient" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteClientDialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="deletingClient" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeClientFromCompany()">
                  Verwijderen
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ DELETE CLIENT DIALOG / -->

    <!-- / Merge Client Dialog \ -->
    <v-dialog v-model="mergeClientDialog" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>Cliënten samenvoegen</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeMergClientDialog()" v-if="!clientsMerge.merging">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
          <v-progress-circular
            v-else
            indeterminate
            color="white"
            :size="25"
            :width="3"
          ></v-progress-circular>
        </v-card-title>

        <v-alert class="ma-0 pa-2" v-if="clientMergFeedback" type="warning">{{ clientMergFeedback }} </v-alert>

        <v-divider></v-divider>

        <v-card-text>
          <v-container grid-list-xl class="ma-0 pa-0 pt-2 pb-4" fluid>
            <v-layout row wrap>
              <v-flex xs6>
                <v-text-field
                  class="ma-0 pa-0 mt-1"
                  label="Active cliënt"
                  :value="selectedClient.name + ' ' + selectedClient.surname"
                  outlined
                  disabled
                  dense
                  readonly
                  background-color="background"
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-select
                  class="ma-0 pa-0 mt-1"
                  :items="filteredClients"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  background-color="background"       
                  item-text="fullName"
                  item-value="id"
                  hide-details
                  dense
                  v-on:input="getClientsMergeData()"
                  v-model="clientsMerge.clientTwo"
                  no-data-text="Geen cliënten gevonden."
                  label="Selecteer cliënt"
                  outlined
                  return-object
                  >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content  class="pt-0" >
                        <v-text-field
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          placeholder="Zoeken"
                          prepend-inner-icon="mdi-magnify"
                          clearable
                          v-model="searchClients"
                          hide-details
                          dense
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <div v-if="searchClients && searchClients.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredClients.length }} van {{ clients.length }} cliënten</div>
                    <v-divider v-if="searchClients && searchClients.length > 0"></v-divider>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>

            <div v-if="clientsMerge.loading" style="margin: 30px 0;" class=" pa-4">
            <div style="position: relative;">
              <div class="thrive-loader"></div>
            </div>
          </div>

            <v-layout row wrap v-if="!clientsMerge.loading && clientsMerge.selected">
              <v-flex xs6>
                <v-card outlined color="background" class="pa-2">
                  <div><strong>Afspraken:</strong> {{ clientsMerge.clientOneData.bookingen.length}}</div>
                  <div><strong>Foto's:</strong> {{ clientsMerge.clientOneData.bodypictures.length}}</div>
                  <div><strong>Omvangsmetingen:</strong> {{ clientsMerge.clientOneData.measurements.length}}</div>
                  <div><strong>Lichaamssamenstellingen:</strong> {{ clientsMerge.clientOneData.compositions.length}}</div>
                  <div><strong>Bonnen:</strong> {{ clientsMerge.clientOneData.receipts.length}}</div>
                </v-card>
              </v-flex>
              <v-flex xs6>
                <v-card outlined color="background" class="pa-2">
                  <div><strong>Afspraken:</strong> {{ clientsMerge.clientTwoData.bookingen.length}}</div>
                  <div><strong>Foto's:</strong> {{ clientsMerge.clientTwoData.bodypictures.length}}</div>
                  <div><strong>Omvangsmetingen:</strong> {{ clientsMerge.clientTwoData.measurements.length}}</div>
                  <div><strong>Lichaamssamenstellingen:</strong> {{ clientsMerge.clientTwoData.compositions.length}}</div>
                  <div><strong>Bonnen:</strong> {{ clientsMerge.clientTwoData.receipts.length}}</div>
                </v-card>
              </v-flex>

               <v-flex xs12>
                <v-switch
                  class="ma-0 mt-4 pa-0"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                  :label="`${clientsMerge.clientTwo.fullName} verwijderen na samenvoegen`"
                  v-model="clientsMerge.disableSelectedClient"
                ></v-switch>
              </v-flex>
            </v-layout>


              
          </v-container>   
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  :disabled="clientsMerge.merging"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="closeMergClientDialog()"
                  >
                  Annuleren
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  :darken="mergingAvailable"
                  :depressed="!mergingAvailable"
                  :disabled="!mergingAvailable"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="mergeClients()"
                  >
                  Samenvoegen
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Merge Client Dialog / -->

    <!-- / New Client Dialog \ -->
    <add-client-form></add-client-form>
    <!-- \ New Client Dialog / -->  
  </div>
</template>
    
<script>
import '@firebase/firestore';
import db from "../firebase/init";
import _ from "lodash";
import { bus } from "../main.js";
import axios from "axios";
import moment from "moment";
import AddClientCard from "../components/modals/AddClientCard.vue";
import AddCompClientForm from "../components/modals/AddCompClientForm.vue";
import AddCustomClientFilterForm from "../components/modals/AddCustomClientFilterForm.vue";
import EditCustomClientFilterForm from "../components/modals/EditCustomClientFilterForm.vue";
import EditCompClientForm from "../components/modals/EditCompClientForm.vue";
import AddBodyCompositionDialog from "../components/modals/AddBodyCompositionDialog.vue";
import EditBodyCompositionDialog from "../components/modals/EditBodyCompositionDialog.vue";
import AddBodyMeasurementDialog from "../components/modals/AddBodyMeasurementDialog.vue";
import EditBodyMeasurementDialog from "../components/modals/EditBodyMeasurementDialog.vue";
import ImportPicturesDialog from "../components/modals/ImportPicturesDialog.vue";
import clientInformation from "../views/ClientInformation.vue";
import algoliasearch from "algoliasearch/lite";
import slugify from "slugify";
import "instantsearch.css/themes/satellite-min.css";
import companySelector from "@/components/elements/companySelector.vue";
import { generateMeshAvatar } from "@/modules/avatarGen.js";

import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    "add-client-form": AddClientCard,
    "add-composition-dialog": AddBodyCompositionDialog,
    "edit-composition-dialog": EditBodyCompositionDialog,
    "add-measurement-dialog": AddBodyMeasurementDialog,
    "edit-measurement-dialog": EditBodyMeasurementDialog,
    "import-pictures-dialog": ImportPicturesDialog,
    "add-companyclient-form": AddCompClientForm,
    "edit-companyclient-form": EditCompClientForm,
    "add-custom-clientfilter-form": AddCustomClientFilterForm,
    "edit-custom-clientfilter-form": EditCustomClientFilterForm,
    "client-information" : clientInformation,
    companySelector: companySelector,
    Carousel,
    Slide
  },
  props: {
    client: String,
  },
  name: "Clients",
  data: function () {
    return {
      clientCounter: 0,
      mergeClientDialog: false,
      deletingClient: false,
      algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
      clientsMerge:{
        disableSelectedClient: true,
        merging: false,
        selected: false,
        loading: false,
        clientOne: null,
        clientTwo: null,
        clientOneData: {
          bookingen: new Array(),
          bodypictures: new Array(),
          compositions: new Array(),
          measurements: new Array(),
          receipts: new Array()
        },
        clientTwoData: {
          bookingen: new Array(),
          bodypictures: new Array(),
          compositions: new Array(),
          measurements: new Array(),
          receipts: new Array()
        }
      },
      deleteClientDialog: false,
      searchClients: "",
      clientFilterActive: "today",
      treatmentsToday: new Array(),
      treatmentsTomorrow: new Array(),
      treatmentsWeek: new Array(),
      clientsFiltered: new Array(),
      clientLoading: false,
      addCompanyClientDialog: false,
      editCompanyClientDialog: false,
      selectedCompany: new Object(),
      selectedCompanyOriginal: new Object(),
      clientMergFeedback: null,
      clientExportLoading: false,

      customClientFilters: new Array(),
      selectedClientFilter: new Object(),
      selectedClientFilterOriginal: new Object(),
      selectedClientFilterIndex: null,

      tab: "1",
      compClients: new Array(),
      pages: -1,
      clientPage: 1,
      clientFirebasePage: 1,
      compClientPage: 1,
      pageCount: 0,
      clientFirebasePageCount: 0,
      compClientPageCount: 0,
      itemsPerPage: 50,
      searchCompanies: "",
      clientsCount: 0,
      clients: new Array(),
      search: "",
      dateDialog: false,
      addClientDialog: false,
      editClientDialog: false,
      clientView: 'clients',
      selectedClient: {
        companies: new Object(),
        address: new Object(),
        health: {
          macronutrients:new Object()
        },
        fullName: null,
        permissions:new Object(),
        emailSubscriptions: new Object()
      },
      selectedClientOriginal: {
        address: new Object(),
        emailSubscriptions: new Object()
      },
      pictureViewmode: {
        preview: true,
        compare: false,
        slider: false
      },
      max: null,
      date: null,
      selectClient: false,
      clientUseStartGoalDate: false,
      clientSource: "algolia",

      snackbar: {
        active: false,
        text: "",
        icon: "",
        timeout: -1,
        color: "",
      },
      
      // CLIENT LIST

      loading: true,
      options: {
        sortBy: "surname",
        rowsPerPage: 30,
        descending: false
      },
      headersCompClients: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "fullName",
          width: "150px",
          sortable: true,
        },
        {
          text: this.$t('general.phone', this.$store.state.locale),
          align: "left",
          value: "phone",
          width: "150px",
          sortable: true,
        },
        {
          text: this.$t('general.email', this.$store.state.locale),
          align: "left",
          value: "email",
          width: "150px",
          sortable: true,
        },
        {
          text: this.$t('clients.coc', this.$store.state.locale),
          align: "left",
          value: "coc",
          width: "150px",
          sortable: true,
        },
        {
          text: this.$t('clients.vat', this.$store.state.locale),
          align: "left",
          value: "vat",
          width: "150px",
          sortable: true,
        },
        {
          text: "",
          align: "right",
          value: "edit",
          width: "60px",
          sortable: false,
        }
      ],

      headers: [
        {
          text: "",
          align: "left",
          value: "avatar",
          width: "40px",
          sortable: false,
        },
        {
          text: "",
          align: "left",
          value: "icon",
          width: "20px",
          sortable: false,
        },

        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "name",
          width: "150px",
          sortable: true,
        },
        {
          text: this.$t('general.lastName', this.$store.state.locale),
          align: "left",
          value: "surname",
          sortable: true,
        },
        {
          text: this.$t('general.phone', this.$store.state.locale),
          align: "left",
          value: "phone",
          width: "130px",
          sortable: false,
        },
        {
          text: this.$t('general.email', this.$store.state.locale),
          align: "left",
          value: "email",
          sortable: false,
        },
        {
          text: this.$t('general.gender', this.$store.state.locale),
          align: "left",
          value: "gender",
          sortable: false,
        },
        {
          text: "",
          align: "right",
          value: "edit",
          width: "60px",
          sortable: false,
        }
      ],
      genders: [
        {
          id: 1,
          name: "Man"
        },
        {
          id: 2,
          name: "Vrouw"
        }
      ],
      criteria: [
        {
          id: "branch",
          name: this.$t('general.branch', this.$store.state.locale),
          conditions: {
            conditionType: 'hidden',
            conditionValue: "equals",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
              {
                id: "equals",
                 name: this.$t('clients.optionsEquals', this.$store.state.locale),
              },
            ],
            inputType: 'select',
            inputOptions: new Array(),
            inputValue: null,
            inputLabel: null,
            inputHint: null,
          },
        },
        {
          id: "gender",
          name: this.$t('general.gender', this.$store.state.locale),
          conditions: {
            conditionType: 'hidden',
            conditionValue: "equals",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
              {
                id: "equals",
                 name: this.$t('clients.optionsEquals', this.$store.state.locale),
              },
            ],
            inputType: 'select',
            inputOptions: [
                {
                  id: 1,
                  name: this.$t('general.male', this.$store.state.locale)
                },
                {
                  id: 2,
                  name: this.$t('general.female', this.$store.state.locale),
                },
              ],
            inputValue: 1,
            inputLabel: null,
            inputHint: null,
          },
        },
        {
          id: "age",
          name: this.$t('general.age', this.$store.state.locale),
          conditions: {
            conditionType: 'select',
            conditionValue: "greaterThan",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
                {
                  id: "greaterThan",
                  name: this.$t('clients.optionsOlderThan', this.$store.state.locale)
                },
                {
                  id: "greaterThanOrEqual",
                  name: this.$t('clients.optionsOlderThanOrEqual', this.$store.state.locale),
                },
                {
                  id: "equals",
                  name: this.$t('clients.optionsEquals', this.$store.state.locale),
                },
                {
                  id: "lessThanOrEqual",
                  name: this.$t('clients.optionsYoungerThanOrEqual', this.$store.state.locale),
                },
                {
                  id: "lessThan",
                  name: this.$t('clients.optionsYoungerThan', this.$store.state.locale),
                }
              ],
            inputType: 'number',
            inputOptions: null,
            inputValue: 30,
            inputLabel: null,
            inputHint: null,
          }
        },
        {
          id: "birthday",
          name: this.$t('general.birthday', this.$store.state.locale),
          conditions: {
            conditionType: 'hidden',
            conditionValue: "equals",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
              {
                id: "equals",
                 name: this.$t('clients.optionsEquals', this.$store.state.locale),
              },
            ],
            inputType: 'select',
            inputOptions: [
                {
                  id: "today",
                  name: this.$t('general.today', this.$store.state.locale)
                },
                {
                  id: "tomorrow",
                  name: this.$t('general.tomorrow', this.$store.state.locale),
                },
                {
                  id: "week",
                  name: this.$t('clients.week', this.$store.state.locale),
                },
                {
                  id: "thisMonth",
                  name: this.$t('clients.thisMonth', this.$store.state.locale),
                },
                {
                  id: "nextMonth",
                  name: this.$t('clients.nextMonth', this.$store.state.locale),
                },
              ],
            inputValue: "today",
            inputLabel: null,
            inputHint: null,
          },
        },
        {
          id: "appointment",
          name: this.$t('clients.newAppointment', this.$store.state.locale),
          conditions: {
            conditionType: 'hidden',
            conditionValue: "equals",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
              {
                id: "equals",
                 name: this.$t('clients.optionsEquals', this.$store.state.locale),
              },
            ],
            inputType: 'select',
            inputOptions: [
                {
                  id: "scheduled",
                  name: this.$t('clients.scheduled', this.$store.state.locale)
                },
                {
                  id: "notScheduled",
                  name: this.$t('clients.notScheduled', this.$store.state.locale),
                },
              ],
            inputValue: "scheduled",
            inputLabel: null,
            inputHint: null,
          },
        },
        {
          id: "visits",
          name: this.$t('clients.visits', this.$store.state.locale),
          conditions: {
            conditionType: 'select',
            conditionValue: "greaterThan",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
                {
                  id: "greaterThan",
                  name: this.$t('clients.optionsGreaterThan', this.$store.state.locale)
                },
                {
                  id: "greaterThanOrEqual",
                  name: this.$t('clients.optionsGreaterThanOrEqual', this.$store.state.locale),
                },
                {
                  id: "equals",
                  name: this.$t('clients.optionsEquals', this.$store.state.locale),
                },
                {
                  id: "lessThanOrEqual",
                  name: this.$t('clients.optionsLessThanOrEqual', this.$store.state.locale),
                },
                {
                  id: "lessThan",
                  name: this.$t('clients.optionsLessThan', this.$store.state.locale),
                }
              ],
            inputType: 'number',
            inputOptions: null,
            inputValue: 10,
            inputLabel: null,
            inputHint: null,
          }
        },
        {
          id: "lastVisit",
          name: this.$t('clients.lastVisit', this.$store.state.locale),
          conditions: {
            conditionType: 'select',
            conditionValue: "betweenNowAnd",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
                {
                  id: "betweenNowAnd",
                  name: this.$t('clients.betweenNowAnd', this.$store.state.locale)
                },
                {
                  id: "after",
                  name: this.$t('clients.laterThan', this.$store.state.locale),
                },
              ],
            inputType: 'select',
            inputOptions: [
                {
                  id: 7,
                  name: this.$t('clients.1week', this.$store.state.locale)
                },
                {
                  id: 14,
                  name: this.$t('clients.2weeks', this.$store.state.locale),
                },
                {
                  id: 21,
                  name: this.$t('clients.3weeks', this.$store.state.locale),
                },
                {
                  id: 30,
                  name: this.$t('clients.1month', this.$store.state.locale)
                },
                {
                  id: 60,
                  name: this.$t('clients.2months', this.$store.state.locale),
                },
                {
                  id: 120,
                  name: this.$t('clients.3months', this.$store.state.locale),
                },
                {
                  id: 180,
                  name: this.$t('clients.6months', this.$store.state.locale),
                },
                {
                  id: 270,
                  name: this.$t('clients.9months', this.$store.state.locale),
                },
                {
                  id: 365,
                  name: this.$t('clients.1year', this.$store.state.locale)
                },
              ],
            inputValue: 120,
            inputLabel: null,
            inputHint: null,
          }
        },
        {
          id: "firstVisit",
          name: this.$t('clients.firstVisit', this.$store.state.locale),
          conditions: {
            conditionType: 'select',
            conditionValue: "betweenNowAnd",
            conditionLabel: null,
            conditionHint: null,
            conditionOptions: [
                {
                  id: "betweenNowAnd",
                  name: this.$t('clients.betweenNowAnd', this.$store.state.locale)
                },
                {
                  id: "after",
                  name: this.$t('clients.laterThan', this.$store.state.locale),
                },
              ],
            inputType: 'select',
            inputOptions: [
                {
                  id: 7,
                  name: this.$t('clients.1week', this.$store.state.locale)
                },
                {
                  id: 14,
                  name: this.$t('clients.2weeks', this.$store.state.locale),
                },
                {
                  id: 21,
                  name: this.$t('clients.3weeks', this.$store.state.locale),
                },
                {
                  id: 30,
                  name: this.$t('clients.1month', this.$store.state.locale)
                },
                {
                  id: 60,
                  name: this.$t('clients.2months', this.$store.state.locale),
                },
                {
                  id: 120,
                  name: this.$t('clients.3months', this.$store.state.locale),
                },
                {
                  id: 180,
                  name: this.$t('clients.6months', this.$store.state.locale),
                },
                {
                  id: 270,
                  name: this.$t('clients.9months', this.$store.state.locale),
                },
                {
                  id: 365,
                  name: this.$t('clients.1year', this.$store.state.locale)
                },
              ],
            inputValue: 120,
            inputLabel: null,
            inputHint: null,
          }
        },
        // {
        //   id: "lastVisitWithTreatment",
        //   name: this.$t('clients.lastVisitWithTreatment', this.$store.state.locale),
        //   conditions: {
        //     conditionType: 'select',
        //     conditionValue: "betweenNowAnd",
        //     conditionLabel: null,
        //     conditionHint: null,
        //     conditionOptions: [
        //         {
        //           id: "betweenNowAnd",
        //           name: this.$t('clients.betweenNowAnd', this.$store.state.locale)
        //         },
        //         {
        //           id: "after",
        //           name: this.$t('clients.laterThan', this.$store.state.locale),
        //         },
        //       ],
        //     inputType: 'select',
        //     inputOptions: [
        //         {
        //           id: "1week",
        //           name: this.$t('clients.1week', this.$store.state.locale)
        //         },
        //         {
        //           id: "2weeks",
        //           name: this.$t('clients.2weeks', this.$store.state.locale),
        //         },
        //         {
        //           id: "3weeks",
        //           name: this.$t('clients.3weeks', this.$store.state.locale),
        //         },
        //         {
        //           id: "1month",
        //           name: this.$t('clients.1month', this.$store.state.locale)
        //         },
        //         {
        //           id: "2months",
        //           name: this.$t('clients.2months', this.$store.state.locale),
        //         },
        //         {
        //           id: "3months",
        //           name: this.$t('clients.3months', this.$store.state.locale),
        //         },
        //         {
        //           id: "6months",
        //           name: this.$t('clients.6months', this.$store.state.locale),
        //         },
        //         {
        //           id: "9months",
        //           name: this.$t('clients.9months', this.$store.state.locale),
        //         },
        //         {
        //           id: "1year",
        //           name: this.$t('clients.1year', this.$store.state.locale)
        //         },
        //       ],
        //     inputValue: "3months",
        //     inputLabel: null,
        //     inputHint: null,
        //     secondConditionName: this.$t('clients.treatmentType', this.$store.state.locale),
        //     secondConditionType: 'hidden',
        //     secondConditionValue: "equals",
        //     secondConditionLabel: null,
        //     secondConditionHint: null,
        //     secondConditionOptions: [
        //       {
        //         id: "equals",
        //          name: this.$t('clients.optionsEquals', this.$store.state.locale),
        //       },
        //     ],
        //     secondInputType: "select",
        //     secondInputOptions: new Array(),
        //     secondInputValue: "",
        //     secondInputLabel: null,
        //     secondInputHint: null,
        //   }
        // },
        

      ]
    }
  },

  created() {
    // this.checkPackages()
    this.loadData();


    bus.$on("removeClientFromCompany", (id) => {this.removeClientFromCompany() });  

    bus.$on('resetClientsPage',()=>{
      this.selectClient = false;
      this.selectedClient = new Object();
    });
    
    bus.$on('updateClient', (client)=>{
      this.updateClient(client);
    });
    bus.$on('addClient', (client)=>{
      console.log("Clientdata: ", client)
      this.clients.push(client);
      this.clientsCount++
    });

    bus.$on('viewClient', (client)=>{
      console.log("viewClient: ", client)
      this.selectUser(client)
    });
  },

  watch: {
    search (val) {
     if(val && val.length > 3){
      this.searchClientsAlgolia()
     }
     else if(val.length == 0 && this.clientFilterActive == "all"){
      console.log("alles laden")
      this.loadClients() 
     }
    }
  },


  methods: {
    async loadData(){
      await this.loadTreatments();
      await Promise.all([this.loadCustomClientFilters(), this.filterClientView("today")])
      let index = _.findIndex(this.criteria, ['id', 'branch']);
      this.criteria[index].conditions.inputOptions = this.$store.state.activeUserBranches;
      this.criteria[index].conditions.inputValue = this.$store.state.activeUserBranches[0].id;
      this.loading = false;
    },
    addClient(){
      bus.$emit("newClient", 'clients');
    },

    genAvatar(fullName){
      
      return generateMeshAvatar(fullName);
    },

    updateCustomClientFilters(type, index, data){
      if(type == 'new'){
        this.customClientFilters.push(data);
        this.filterClientViewCustom(data);
      }
      else{
        this.customClientFilters[index] = data;
        this.filterClientViewCustom(data);
      }
    },

    newClientFilter(){
      bus.$emit("newCustomClientFilter");
    },

    async removeClientFilter(item, index){
      await db.collection("customClientFilters").doc(item.id).delete()  
      this.customClientFilters.splice(index, 1); 
    },

    editClientFilter(item, index){
      this.selectedClientFilterIndex = index;
      this.selectedClientFilter = item;
      this.selectedClientFilterOriginal = _.cloneDeep(this.selectedClientFilter); 
      bus.$emit("editCustomClientFilter");
    },

    filterClientViewCustom(filter){
      this.clientLoading = true;
      this.clientsFiltered = new Array();
      this.clientFilterActive = filter.id;
      this.clientView = 'clients';

      let clientsToday = new Array();
      let counter = 0;

      axios.post('https://cf.hogans.nl/custom-client-filter', {criteria: filter.criteria, companyId: this.$store.state.activeCompany.id})
      .then((response) => {
        this.clientsFiltered = response.data;
        this.clientLoading = false;
      }, (error) => {
        this.clientLoading = false;
        console.error("Error getting custom filter clients: ", error);
      });
    },

    openMergClientDialog(){
      this.mergeClientDialog = true;
    },

    closeMergClientDialog(){
      this.mergeClientDialog = false;
      this.clientsMerge = {
        disableSelectedClient: true,
        merging: false,
        loading: false,
        selected: false,
        clientOne: null,
        clientTwo: null,
        clientOneData: {
          bookingen: new Array(),
          bodypictures: new Array(),
          compositions: new Array(),
          measurements: new Array(),
          receipts: new Array()
        },
        clientTwoData: {
          bookingen: new Array(),
          bodypictures: new Array(),
          compositions: new Array(),
          measurements: new Array(),
          receipts: new Array()
        }
      }
    },

    mergeClients(){
      this.clientsMerge.merging = true;
      new Promise(resolve =>{
        let counter = 0;
        // Bookingen
          if(this.clientsMerge.clientTwoData.bookingen.length > 0){
            let bookingenCounter = 0
            this.clientsMerge.clientTwoData.bookingen.forEach(booking=>{
              db.collection("bookingsummary").doc(booking.id).set({
                clientId: this.clientsMerge.clientOne.id,
                mergeData: {
                  fromClientId: this.clientsMerge.clientTwo.id,
                  date: new Date(),
                  user: this.$store.state.activeUserFirstName + " " + this.$store.state.activeUserLastName,
                  userCompany: this.$store.state.activeUserCompany,
                }
              }, { merge: true })
              .then(()=>{
                bookingenCounter++
                if(bookingenCounter == this.clientsMerge.clientTwoData.bookingen.length){
                  counter++
                  if(counter == 5){
                    resolve()
                  }
                }
              })
            })
          } 
          else{
            counter++
            if(counter == 5){
              resolve()
            }
          }
        // Body pictures
          if(this.clientsMerge.clientTwoData.bodypictures.length > 0){
            let bodypicturesCounter = 0
            this.clientsMerge.clientTwoData.bodypictures.forEach(bodyPicture=>{
              db.collection("bodypictures").doc(bodyPicture.id).set({
                clientId: this.clientsMerge.clientOne.id,
                mergeData: {
                  fromClientId: this.clientsMerge.clientTwo.id,
                  date: new Date(),
                  user: this.$store.state.activeUserFirstName + " " + this.$store.state.activeUserLastName,
                  userCompany: this.$store.state.activeUserCompany,
                }
              }, { merge: true })
              .then(()=>{
                bodypicturesCounter++
                if(bodypicturesCounter == this.clientsMerge.clientTwoData.bodypictures.length){
                  counter++
                  if(counter == 5){
                    resolve()
                  }
                }
              })
            })
          } 
          else{
            counter++
            if(counter == 5){
              resolve()
            }
          }
        // Body compositions
          if(this.clientsMerge.clientTwoData.compositions.length > 0){
            let compositionsCounter = 0
            this.clientsMerge.clientTwoData.compositions.forEach(composition=>{
              db.collection("compositions").doc(composition.id).set({
                clientId: this.clientsMerge.clientOne.id,
                mergeData: {
                  fromClientId: this.clientsMerge.clientTwo.id,
                  date: new Date(),
                  user: this.$store.state.activeUserFirstName + " " + this.$store.state.activeUserLastName,
                  userCompany: this.$store.state.activeUserCompany,
                }
              }, { merge: true })
              .then(()=>{
                compositionsCounter++
                if(compositionsCounter == this.clientsMerge.clientTwoData.compositions.length){
                  counter++
                  if(counter == 5){
                    resolve()
                  }
                }
              })
            })
          } 
          else{
            counter++
            if(counter == 5){
              resolve()
            }
          }
        // Body measurements
          if(this.clientsMerge.clientTwoData.measurements.length > 0){
            let measurementsCounter = 0;
            this.clientsMerge.clientTwoData.measurements.forEach(measurement=>{
              db.collection("measurements").doc(measurement.id).set({
                clientId: this.clientsMerge.clientOne.id,
                mergeData: {
                  fromClientId: this.clientsMerge.clientTwo.id,
                  date: new Date(),
                  user: this.$store.state.activeUserFirstName + " " + this.$store.state.activeUserLastName,
                  userCompany: this.$store.state.activeUserCompany,
                }
              }, { merge: true }) 
              .then(()=>{
                measurementsCounter++
                if(measurementsCounter == this.clientsMerge.clientTwoData.measurements.length){
                  counter++
                  if(counter == 5){
                    resolve()
                  }
                }
              })
            })
          } 
          else{
            counter++
            if(counter == 5){
              resolve()
            }
          }
        // Receipts
          if(this.clientsMerge.clientTwoData.receipts.length > 0){
            let receiptsCounter = 0;
            this.clientsMerge.clientTwoData.receipts.forEach(receipt=>{
              db.collection("measurements").doc(receipt.id).set({
                clientId: this.clientsMerge.clientOne.id,
                mergeData: {
                  fromClientId: this.clientsMerge.clientTwo.id,
                  date: new Date(),
                  user: this.$store.state.activeUserFirstName + " " + this.$store.state.activeUserLastName,
                  userCompany: this.$store.state.activeUserCompany,
                }
              }, { merge: true })
              .then(()=>{
                receiptsCounter++
                if(receiptsCounter == this.clientsMerge.clientTwoData.receipts.length){
                  counter++
                  if(counter == 5){
                    resolve()
                  }
                }
              })
            })
          } 
          else{
            counter++
            if(counter == 5){
              resolve()
            }
          }
      })
      .then(()=>{
        // Disable Selected client if this option is selected
        console.log("Merge Promise Resolved")
        if(this.clientsMerge.disableSelectedClient){
          let deleted = new Array();
          if(this.clientsMerge.clientTwo.deleted){
            deleted = this.clientsMerge.clientTwo.deleted;
          }
          let companies = _.cloneDeep(this.clientsMerge.clientTwo.companies);
          let companyIndex = _.indexOf(companies, this.$store.state.activeUserCompany);
          companies.splice(companyIndex, 1)
        
          let deletedInfo = {
            date: new Date(),
            user: this.$store.state.activeUserFirstName + " " + this.$store.state.activeUserLastName,
            userCompany: this.$store.state.activeUserCompany,
          };
          deleted.push(deletedInfo);
          db.collection("clients").doc(this.clientsMerge.clientTwo.id).set({
            companies: companies,
            deleted: deleted
          }, { merge: true })
          .then(()=>{
            this.clientsMerge.merging = false;
            this.closeMergClientDialog();
            this.backToClients();
            this.updateData();
            this.snackbar.active = true;
            this.snackbar.timeout = 5000;
            this.snackbar.color = "rgba(1, 230, 110, 0.7)";
            this.snackbar.icon = "mdi-thumb-up";
            this.snackbar.text = "Cliënten succesvol samengevoegd";
          })
        } 
        else{
          this.clientsMerge.merging = false;
          this.closeMergClientDialog();
          this.backToClients();
          this.updateData();
          this.snackbar.active = true;
          this.snackbar.timeout = 5000;
          this.snackbar.color = "rgba(1, 230, 110, 0.7)";
          this.snackbar.icon = "mdi-thumb-up";
          this.snackbar.text = "Cliënten succesvol samengevoegd";
        }
      })
      
    },

    showDeleteDialog(){ // Open General Delete Modal                                      
      let deleteInformation = new Object();
      deleteInformation.id = null;                                                             
      deleteInformation.emit = 'removeClientFromCompany';                                             

      deleteInformation.title ="Cliënt verwijderen? " // this.$t('products.removeProduct', this.$store.state.locale);
      deleteInformation.info = "De cliënt zal worden verwijderd. Dit kan niet ongedaan worden gemaakt." // this.$t('products.removeProductInfo', this.$store.state.locale);

      bus.$emit('deletedModal', deleteInformation, 'open');                                        
    },

    async removeClientFromCompany(){
      this.deletingClient = true;

      await db.collection("clients").doc(this.selectedClient.objectID).set({
        deleted: true,
        deletedInfo: {
          date: new Date(),
          user: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
          userCompany: this.$store.state.activeUserCompany,
        }
      }, { merge: true })
      .then(()=>{
        bus.$emit('deletedModal', null, 'close'); // Close General Delete Modal   
        this.backToClients();
        this.updateData();
        this.snackbar.active = true;
        this.snackbar.timeout = 5000;
        this.snackbar.color = "rgba(1, 230, 110, 0.7)";
        this.snackbar.icon = "mdi-thumb-up";
        this.snackbar.text = "Cliënt succesvol verwijderd";
        this.deletingClient = false;
      })
      .catch(error =>{
        console.log("Error bij verwijderen client: ", error);
        bus.$emit('deletedModal', null, 'close'); // Close General Delete Modal   
        this.backToClients();
        this.updateData();
        this.snackbar.active = true;
        this.snackbar.timeout = 5000;
        this.snackbar.color = "rgba(230, 53,53, 0.7)";
        this.snackbar.icon = "mdi-thumb-down";
        this.snackbar.text = "Cliënt verwijderen niet gelukt";
        this.deletingClient = false;
      })
      
    },

    getClientsMergeData(){
      this.clientsMerge.loading = true;
      this.clientsMerge.clientOne = _.cloneDeep(this.selectedClient);

      let clientDataOne = new Promise(resolve =>{
        let counter = 0;
        db.collection("bodypictures")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientOne.id)
          .where("deleted.deleted", "==", false)
          .orderBy("dateAdded", "desc")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let bodypicture = doc.data();
              bodypicture.id = doc.id;
              this.clientsMerge.clientOneData.bodypictures.push(bodypicture);
            })
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen bodypictures: ", error);
          })

        db.collection("compositions")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientOne.id)
          .orderBy("dateAdded")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let composition = doc.data();
              composition.id = doc.id;
              this.clientsMerge.clientOneData.compositions.push(composition);
            })
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen compositions: ", error);
          })

        db.collection("receipts")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientOne.id)
          .where("draft", "==", false)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let receipt = doc.data();
              receipt.id = doc.id;
              this.clientsMerge.clientOneData.receipts.push(receipt);
            });
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen receipts: ", error);
          })
        db.collection("bookingsummary")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientOne.id)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let booking = doc.data();
              booking.id = doc.id;
              this.clientsMerge.clientOneData.bookingen.push(booking);
            });
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen bookingsummary: ", error);
          })
        db.collection("measurements")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientOne.id)
          .orderBy("dateAdded")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let measurement = doc.data();
              measurement.id = doc.id;
              this.clientsMerge.clientOneData.measurements.push(measurement);
            });
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen measurements: ", error);
          })
      })

      let clientDataTwo = new Promise(resolve =>{
        let counter = 0;
        db.collection("bodypictures")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientTwo.id)
          .where("deleted.deleted", "==", false)
          .orderBy("dateAdded", "desc")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let bodypicture = doc.data();
              bodypicture.id = doc.id;
              this.clientsMerge.clientTwo.bodypictures.push(bodypicture);
            })
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen bodypictures: ", error);
          })

        db.collection("compositions")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientTwo.id)
          .orderBy("dateAdded")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let composition = doc.data();
              composition.id = doc.id;
              this.clientsMerge.clientTwoData.compositions.push(composition);
            })
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen compositions: ", error);
          })

        db.collection("receipts")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientTwo.id)
          .where("draft", "==", false)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let receipt = doc.data();
              receipt.id = doc.id;
              this.clientsMerge.clientTwoData.receipts.push(receipt);
            });
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen receipts: ", error);
          })
        db.collection("bookingsummary")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientTwo.id)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let booking = doc.data();
              booking.id = doc.id;
              this.clientsMerge.clientTwoData.bookingen.push(booking);
            });
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen bookingsummary: ", error);
          })
        db.collection("measurements")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("clientId", "==", this.clientsMerge.clientTwo.id)
          .orderBy("dateAdded")
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let measurement = doc.data();
              measurement.id = doc.id;
              this.clientsMerge.clientTwoData.measurements.push(measurement);
            });
          })
          .then(()=>{
            counter++
            if(counter == 5){
              resolve()
            }
          })
          .catch(error=>{
            counter++
            if(counter == 5){
              resolve()
            }
            console.error("Fout bij ophalen measurements: ", error);
          })
      })

      Promise.all([clientDataOne, clientDataTwo])
      .then(()=>{
        this.clientsMerge.loading = false;
        this.clientsMerge.selected = true;
      })
    },

    editCompClient(item){
      this.selectedCompany = item,
      this.selectedCompanyOriginal = _.cloneDeep(this.selectedCompany)
      this.editCompanyClientDialog = true;
    },

    filterClientView(id){
      this.clientLoading = true;
      this.clientsFiltered = new Array();
      this.clientFilterActive = id;
      this.clientView = 'clients';
      this.clientsCount = 0;
      console.log("id: ", id)

      // All clients
      if(id == 'all'){
        this.clientSource = "algolia";
        this.clientLoading = true;
        this.loadClients();
      }

      // Clients tomorrow
      else if(id == 'tomorrow' || id == 'today' || id == 'week'){
        this.clientSource = "firebase";
        let treatmentData = new Array();
        if(id == 'today'){
          this.search = "";
          this.clientFilterActive = "today";
          treatmentData =  _.cloneDeep(this.treatmentsToday)
        }
        else if(id == 'tomorrow'){
          this.search = "";
          this.clientFilterActive = "tomorrow";
          treatmentData = _.cloneDeep(this.treatmentsTomorrow)
        }
        else if(id == 'week'){
          this.search = "";
          this.clientFilterActive = "week";
          treatmentData = _.cloneDeep(this.treatmentsWeek)
        }

        treatmentData =  [...new Set(treatmentData.map(item => item.clientId))]
        this.clientsCount = treatmentData.length;
        this.clients = new Array();
        

        new Promise((resolve)=>{
          if(treatmentData.length > 0){
            let counter = 0
            treatmentData.forEach(treatment=> {
              db.collection("clients").doc(treatment).get()
              .then(doc => {
                if(doc.exists){
                  let client = {
                    objectID: treatment,
                    name: doc.data().name,
                    surname: doc.data().surname,
                    emails: doc.data().emails,
                    phones: doc.data().phones,
                    gender: doc.data().gender,
                    icon: doc.data().icon,
                    minor: doc.data().minor ? doc.data().minor: false,
                    minorType: doc.data().minorType ? doc.data().minorType: false,
                  }
                  this.clients.push(client)
                }
                counter++
                if(counter == treatmentData.length){
                  resolve()
                }
              }) //Add catch promise error with bus.$emit('eventError', error). Refactor this code 

              .catch((error) => { //Catch any errors that occur in the promise chain and emit an event with the error message  
          
                bus.$emit('eventError', error)  
              

              })  
            })
          }
          else{
            resolve()
          }
        })
        .then(() => {
          this.loading = false;
          this.clientLoading = false;
        })
        // .catch((error) => { 
        //   console.log("error: ", error)
        //   bus.$emit('eventError', error, null, null)  
       
        // })
      }
       

      // Clients company accounts
      else if(id == 'companyaccounts'){
        new Promise((resolve)=>{
          this.clients.forEach(client=>{
            if(client.type == 'company'){
              this.clientsFiltered.push(client)
            }
            counter++
            if(counter == this.clients.length){
              resolve()
            }
          })
        })
        .then(()=>{
          this.clientLoading = false;
        }) 
      }
      // Clients companies
      else if(id == 'companies'){
        this.clientView = 'companies';
        this.clientLoading = false;
      }
      else if(id == 'leads'){
        new Promise((resolve)=>{
          this.clients = new Array();
          let index =  this.algoSearch.initIndex("thrive_clients");
          var FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`; 
          index.search("lead", {
            facetFilters: [FacetFilterString],
            restrictSearchableAttributes: ['tags.type']
          },)
          .then(({ hits, nbHits }) => {
            this.clientsCount = nbHits;
            hits.forEach((client) => {
              this.clients.push(client)
            });
            resolve()
          });

          // this.clients.forEach(client=>{
          //   if(client.tags){
          //     let tags = _.findIndex(client.tags, { 'type': 'lead', "companyId" : this.$store.state.activeCompany.id });;
          //     if(tags >= 0){
          //       this.clientsFiltered.push(client)
          //     }
          //   }
          //   counter++
          //   if(counter == this.clients.length){
          //     resolve()
          //   }
          // })
        })
        .then(()=>{
          this.clientLoading = false;
        })
      }
    },

    backToClients(){ // Terug naar Clientenoverzicht
      this.selectClient = false;
      bus.$emit("resetClientView");
    },

    updateData() {
      this.loadClients();
    }, 

    updateDataCompClient(){
      this.loadCompClients();
    },

    updateClient(client) {
      let clientsIndex = _.findIndex(this.clients, {'id': client.clientId})
      let clientsFilteredIndex = _.findIndex(this.clientsFiltered, {'id': client.clientId})
      if(clientsIndex >= 0){
        this.clients[clientsIndex] = client;
      }
      if(clientsFilteredIndex >= 0){
        this.clientsFiltered[clientsFilteredIndex] = client;
      }
    },

    async searchClientsAlgolia() { // Get client data from Algolia
      this.clients = new Array();
      let index =  this.algoSearch.initIndex("thrive_clients");

      let FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
			let deletedFilterString = ['deleted:false', 'deleted:-'];

      await index.search(this.search, {
        facetFilters: [FacetFilterString],
        filters: 'deleted:false OR NOT deleted:true' // Correcte filterinstelling
      },)
      .then(({ hits, nbHits }) => {
        this.clientsCount = nbHits;
        hits.forEach((client) => {
          this.clients.push(client)
        });
      });
    },

    getIcon(icon){
      if(icon){
        if (icon == 'star'){
          return {
            icon: "mdi-star",
            color:"#FFD600"
          }
        }
        else if (icon == 'alert'){
          return {
            icon: "mdi-alert",
            color:"#FF5722"
          }
        }
        else if (icon == 'flag'){
          return {
            icon: "mdi-flag",
            color:this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary,
          }
        }
        else if (icon == 'flash'){
          return {
            icon: "mdi-flash",
            color:"#FFD600"
          }
        }
        else if (icon == 'clock'){
          return {
            icon: "mdi-clock-outline",
            color:"#FFD600"
          }
        }
        else{
          return {
            icon: null,
            color: null
          }
        }
      }
      else{
        return {
          icon: null,
          color: null
        }
      }
    },

    selectClientPage(){
      this.clientLoading = true;
      this.loadClients(this.clientPage)
    },

    async loadClients(page) { // Get client data from Algolia
      console.log("loadClients page:", page)
      
      this.clients = new Array();
      
      this.clientPage = page ? page : 1;
      let index =  this.algoSearch.initIndex("thrive_clients");
      var FacetFilterString = `companyId:${this.$store.state.activeCompany.id}`;
      await index.search("", {
        page: page ? page -1 : 0,
        facetFilters: [FacetFilterString],
      },)
      .then(({ hits, nbHits }) => {
        
        this.clientsCount = nbHits
        this.pageCount = Math.ceil(nbHits / this.itemsPerPage)
        hits.forEach((client) => {
          this.clients.push(client)
        });
      });
      this.clientCounter++
      if(!page){
        
      }
      this.clientLoading = false;
      this.loading = false;

      console.log("clientPage: ", this.clientPage)
      console.log("pageCount: ", this.pageCount)
    },

    loadCompClients(){
      db.collection("companyClients")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          this.compClients = new Array();
          snap.forEach(doc => {
            let company = doc.data();
            company.id = doc.id;
            this.compClients.push(company);
          });
        })
    },

    loadCustomClientFilters(){
      db.collection("customClientFilters")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("type", "==", "list")
        .get()
        .then(snap => {
          this.customClientFilters = new Array();
          snap.forEach(doc => {
            let filter = doc.data();
            filter.id = doc.id;
            this.customClientFilters.push(filter);
          });
        })
    },

   async loadTreatments() {
      this.treatmentsToday = new Array();
      this.treatmentsTomorrow = new Array();
      this.treatmentsWeek = new Array();

      let today = moment().format("YYYY-MM-DD");
      let tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
      let weekStart = moment().startOf('week').format("YYYY-MM-DD");
      let weekEnd = moment().endOf('week').add(1, 'days').format("YYYY-MM-DD");

      await db.collection("bookingsummary")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("visit", ">=", weekStart)
      .where("visit", "<=", weekEnd)
      .where("deleted", "==", false)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let treatment = doc.data();
          treatment.id = doc.id;
          this.treatmentsWeek.push(treatment);
          if(doc.data().visit == today){
            this.treatmentsToday.push(treatment);
          }
          if(doc.data().visit == tomorrow){
            this.treatmentsTomorrow.push(treatment);
          }
        });
      })
      return
    },

    // editUser(item) {
    //   this.editClientDialog = true;
    //   this.selectedClient = Object.assign(new Object(), item);
    //   this.selectedClientOriginal = _.cloneDeep(this.selectedClient);
    // },

    selectUser(item) {
      if(this.viewClient){
        this.selectedClient = item;
        bus.$emit("getClientInformation", item.objectID);
        this.selectClient = true;
      }
      else{
        // No permission
        this.snackbar.active = true;
        this.snackbar.timeout = 3000;
        this.snackbar.color = "rgba(213, 0, 0, 0.7)";
        this.snackbar.icon = "mdi-thumb-down";
        this.snackbar.text = "Geen rechten om cliënten te bekijken.";
      }      
    },

    // Bereken aantal items in een Object
    getObjectSize(obj){
      var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    },

    formatDate(date) {
      if (!date) return null;
      else if (date) {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },

    // Controleer welke Pakketten het bedrijf heeft.
    checkPackages(){
      let companyDataPackages = this.$store.state.companySubscription.packages;
      let companyPackages = new Object()
      companyPackages.type = null
      companyPackages.list = new Object()

      if(this.getObjectSize(companyDataPackages) > 0){
        // Packages gevonden. Nu kijken of ze nog actief zijn.
        let activePackages = 0
        let today = moment().unix()
        let counter = 0

         // Door Object heen loopen met Promise
        new Promise((resolve, reject) => {
          for (var key in companyDataPackages) {
            if(companyDataPackages[key].endDate){
              // Kijken of pakket opgezegd is.
              if(companyDataPackages[key].endDate.seconds > today){
                // Pakket is opgezegd. Kijken of de einddatum in de toekomst ligt
                companyPackages.list[key] = true
                activePackages++
              } 
            } else{
              companyPackages.list[key] = true
              activePackages++
            }
            counter++
            if(counter == this.getObjectSize(companyDataPackages)){
              resolve()
            }
          }
        })
        .then(()=>{
          if(activePackages > 0){
            // Actieve pakketen gevonden.

            this.companyPackageStatus = 'paidVersion'
            companyPackages.type = 'paidVersion'

            this.$store.dispatch("companyPackages", companyPackages);
          } else{
            // Geen actieve pakketen gevonden. De gebruiker maakt gebruik van de gratis versie
            this.companyPackageStatus = 'freeVersion'
            companyPackages.type = 'freeVersion'
            this.$store.dispatch("companyPackages", companyPackages);
          }
        })
      } else {
        // Geen pakketen gevonden. De gebruiker maakt gebruik van de gratis versie
        this.companyPackageStatus = 'freeVersion'
        companyPackages.type = 'freeVersion'
        this.$store.dispatch("companyPackages", companyPackages);
      }
    },

    downloadClientsCSV(){
      this.clientExportLoading = true;
      let filename = moment().format("YYYY-MM-DD") + "-client-export.csv";
      let exportClients = null;
      if(this.clientFilterActive == 'all'){
        exportClients = _.cloneDeep(this.filteredClientsExport);  
      }
      else{
        exportClients = _.cloneDeep(this.clientsFiltered);
      }
     
      let csvContent = "data:text/csv;charset=utf-8,";
      let counter = 0;
      
      new Promise((resolve) =>{    
        let row = "Voornaam, Achternaam, E-mail, Telefoonnummer, Geslacht";
        csvContent += row + "\r\n";
        exportClients.forEach(client=> {
          let firstName = "";
          let lastName = "";
          let phone = "";
          let email = "";
          let gender = "";
          if(client.name){ firstName = client.name}
          if(client.surname){ lastName = client.surname}
          if(client.phone){ phone = client.phone}
          if(client.email){ email = client.e3mail}
          if(client.gender == 1){ gender = 'Man'}
          if(client.gender == 2){ gender = 'Vrouw'}

          let row = firstName + "," + lastName + "," + email + "," + phone + "," + gender;
          csvContent += row + "\r\n";
          counter++
          if(counter == exportClients.length){
            resolve();
          }
        })
      })
      .then(()=>{
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.href = encodedUri;
        link.download = filename;
        link.click();
        this.clientExportLoading = false;
      })
    }
  },

  computed: {
    selectedView(){
        return id => {
        if(this.clientFilterActive == id){
          return true;
        }
        else {
          return false;
        }
      }
    },

    clientsData(){
      if(!this.search || this.search.length == 0){
        return this.clientsFiltered;
      }
      else{
        return this.clientsFiltered.filter(client => {
          let match = false;

          if(client.name.toLowerCase().includes(this.search.toLowerCase())){
            match = true;
          }
          if(client.surname.toLowerCase().includes(this.search.toLowerCase())){
            match = true;
          }

          if(client.phones && client.phones.length > 0){
            client.phones.forEach(phone=>{
              if((slugify(phone.phone.international, {replacement: "",remove: /[!@#$%^&*()"';:.,]/g,lower: true})).includes(this.search.toLowerCase())){
                match = true;
              }
              if((slugify(phone.phone.national, {replacement: "",remove: /[!@#$%^&*()"';:.,]/g,lower: true})).includes(this.search.toLowerCase())){
                match = true;
              }
            })
          }

          if(client.emails && client.emails.length > 0){
            client.emails.forEach(email=>{
              if((email.email).includes(this.search.toLowerCase())){
                match = true;
              }
            })
          }
          return match ? client : null; 
        });
      }
    },

    viewClient() {
      if(this.$store.state.userRules){
        if(this.$store.state.userRules.free && (_.indexOf(this.$store.state.userRules.free, 'viewClient') >= 0)){
          return true; // Rule found in free
        }
        else{ // Check if some user is logged in
          if(this.$store.state.connectedEmployee){ // Employee is logged in
            if(this.$store.state.activeUserRoleNumber < 4){
              return true; // userRole OVERRULES the rules
            }
            else{
              if(this.$store.state.userRules.restricted && (_.indexOf(this.$store.state.userRules.free, 'viewClient') >= 0)){
                return true; // Rule found in restricted
              }
              else{ return false;  } // Rule NOT found in restricted
            }
          }
          else{ return false; } // Employee is NOT logged in
        }
      }
      else{ return true; } // No branch is logged in
    },

    editClient() {
      if(this.$store.state.userRules){
        if(this.$store.state.userRules.free && (_.indexOf(this.$store.state.userRules.free, 'editClient') >= 0)){
          return true; // Rule found in free
        }
        else{ // Check if some user is logged in
          if(this.$store.state.connectedEmployee){ // Employee is logged in
            if(this.$store.state.activeUserRoleNumber < 4){
              return true; // userRole OVERRULES the rules
            }
            else {
              if(this.$store.state.userRules.restricted && (_.indexOf(this.$store.state.userRules.free, 'editClient') >= 0)){
                return true; // Rule found in restricted
              }
              else{ return false; } // Rule NOT found in restricted
            }
          }
          else{ return false; } // Employee is NOT logged in
        }
      }
      else{ return true; } // No branch is logged in
    },

    mergeClientsSelected(){
      if(this.clientsMerge.clientOne && this.clientsMerge.clientTwo && !this.clientsMerge.loading){
        return true
      } else{
        return false
      }
    },
    mergingAvailable(){
      if(this.clientsMerge.clientOne && this.clientsMerge.clientTwo && !this.clientsMerge.loading && !this.clientsMerge.merging){
        return true;
      }
      else{
        return false;
      }
    },
    filteredClients(){
      return this.clients
    },
    filteredClientsExport(){
        if(this.search && this.search.length > 0){
          return this.clientsFiltered.filter(client => {

            return client.name.toLowerCase().includes(this.search.toLowerCase())
  
            // return client.name.toLowerCase().includes(this.search.toLowerCase()) || phone.includes(this.search.toLowerCase())|| email.toLowerCase().includes(this.search.toLowerCase())
          });
      }
      else{
        return this.clientsFiltered;
      }
    },

    headerBarClass(){
      if((!this.$store.state.userCompanies || this.$store.state.userCompanies.length == 1) || this.$store.state.demoMode){
        return this.selectClient ? "md6" : "md12"
      }
      else{
        return "md6"
      }
    },

    clientExportDisabled(){
      if(this.clientFilterActive == 'all'){
        if(this.filteredClients.length > 0){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        if(this.clientsFiltered.length > 0){
          return false;
        }
        else{
          return true;
        }
      }      
    },
  }
};
</script>

<style>
  .client-count p {
    font-size: 13px;
    margin: 0;
    padding: 15px 0 10px 0;
    font-style: italic;
  }
  .client-count p.no-clients {

    padding: 50px 0;
  }

  .avatarGenTable svg {
    margin-top: 4px;
    width: 22px;
    height: 22px;
  }

  .avatarGen .header-avatar svg {
    width: 28px;
    height: 28px;
  }

  .v-data-table.dense-table.v-data-table--dense .v-data-table__empty-wrapper td{
    padding: 20px!important;
  }
</style>