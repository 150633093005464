<template>
  <div class="pa-4">
    
    <div class="text-center" v-if="!newclient" >
      <v-row d-flex align="center">
        <v-col :cols=" $store.state.clientSaveData ? 6 : 12" d-flex>
          <h5 style="font-weight: 700">{{ $t('clients.createNewClient', $store.state.locale) }}</h5>
          <p class="client-info-input-intro ma-0 pb-3">{{ $t('clients.chooseGender', $store.state.locale) }}</p>
          <div>
            <v-row>
              <v-col cols="6">
                <v-btn
                  depressed
                  dark
                  block
                  @click="createNewClient(2)"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  rounded
                  >{{ $t('general.woman', $store.state.locale) }}
                </v-btn>
              </v-col>
              <v-col cols="6" >
                <v-btn
                  depressed
                  block
                  @click="createNewClient(1)"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  outlined
                  rounded
                  >{{ $t('general.man', $store.state.locale) }}
                </v-btn>
              </v-col>
            </v-row>

          </div>
        </v-col>
        <v-col cols="6" d-flex>
          <v-card outlined class="text-center pa-1 mb-0" v-if="!newclient && $store.state.clientSaveData" d-flex :style="[{borderColor: saveClientSelected ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].text},{'border-size': saveClientSelected ? '2px!important' : '1px'}]">
            <v-row>
              <v-col cols="2" class="text-right"></v-col>
              <v-col cols="8" class="text-center"> <h5 style="font-weight: 700;">{{ $t('clients.useSavedClient', $store.state.locale) }}</h5></v-col>
              <v-col cols="2" class="text-right"><v-icon small @click="$store.state.clientSaveData = null">mdi-close</v-icon></v-col>
            </v-row>
            
            <!-- <p class="client-info-input-intro ma-0 pb-3">{{ $t('clients.chooseGender', $store.state.locale) }}</p> -->
            <v-chip small label :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 mt-2 custom-chip-style" > <v-icon small left>mdi-account</v-icon>{{ $store.state.clientSaveData.firstName }} {{ $store.state.clientSaveData.lastName }}</v-chip>
            
            <div style="font-size: 12px;" :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].text }">
              {{ $store.state.clientSaveData.email }}
            </div>

            <div v-if="!saveClientSelected">
              <v-btn
                v-if="$store.state.clientSaveData.clientId || $store.state.clientSaveData.minor.minorId"
                depressed
                class="mt-2 mb-1"
                small 
                @click="useSavedClient()"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                outlined
                rounded
                >{{ $t('clients.selectClient', $store.state.locale) }}
              </v-btn>
              <v-btn
                v-else
                depressed
                class="mt-2 mb-1"
                small 
                @click="createSavedClient()"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                outlined
                rounded
                >{{ $t('clients.selectAndCreateClient', $store.state.locale) }}
              </v-btn>
            </div>
            <div v-else style="font-size: 12px; padding: 5px 0 0 0">
              <i>{{ $t('general.itemSelected', $store.state.locale) }}</i>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- NEW CLIENT INFO -->
    <div v-if="newclient" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }" >
      <div class="client-info-input-wrapper">
        <h5 style="font-weight: 700">{{ $t('clients.createNewClient', $store.state.locale) }}</h5>
        <p class="client-info-input-intro">{{ $t('clients.clientBasicInfo', $store.state.locale) }}</p>

        <v-form ref="clientData" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="6" >
              <v-text-field class="mt-0" hide-details dense :label="` ${$t('general.firstName', $store.state.locale)}*`" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-model="clientData.firstName" :rules="clientData.firstNameRules" required ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field class="mt-0" hide-details dense :label="` ${$t('general.lastName', $store.state.locale)}*`" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-model="clientData.surname" :rules="clientData.surnameRules" required></v-text-field>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-card :flat="!clientData.minor" :outlined="clientData.minor" :color="clientData.minor ? $themes[$store.state.companyTheme][$store.state.themeModus].card : $themes[$store.state.companyTheme][$store.state.themeModus].background" class="ma-0 px-2" :class="{'py-3' : clientData.minor}"> 
                <v-switch
                  class="pa-0 ma-0"
                  v-model="clientData.minor"
                  inset
                  :label="$t('clients.minorLabel', $store.state.locale)"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :hint="`<i>${$t('clients.minorChoiceHint', $store.state.locale)}</i>`"
                  persistent-hint
                  @change="setMinorData()"
                  >
                  <template v-slot:message="{ message, key }">
                    <span v-html="message"></span>
                  </template>
                </v-switch>

                <v-select
                  v-if="clientData.minor" 
                  :label="$t('general.type', $store.state.locale)"
                  outlined
                  class="my-2"
                  dense
                  :item-text="item => $t('general.'+item.name, $store.state.locale)"
                  item-value="id"
                  hide-details
                  content-class="pa-0 ma-0 mr-4"
                  :items="minorTypes"
                  v-model="clientData.minorType"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
                  >
                </v-select>

                <label v-if="clientData.minor" style="font-size: 12px">{{ $t('clients.clientControlSearch', $store.state.locale) }}</label>
                <div v-if="clientData.minor && clientData.guardianClientIds.length > 0">
                  <v-text-field
                    readonly
                    :value="guardianClientName"
                    dense
                    class="mt-0"
                    outlined
                    :placeholder="$t('clients.clientControlSearch', $store.state.locale)"
                    append-icon=""
                    prepend-inner-icon="mdi-account"
                    hide-details
                    append-icon="mdi-close"
                    @click:append="resetGuardianClientId()"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background"
                    ></v-text-field>
                </div>

                <div v-if="clientData.minor && clientData.guardianClientIds.length == 0">
                  <clientSearch :source="'addClient'" @clientSelect="getSelectedClientData"></clientSearch>  
                </div>

              </v-card>
             </v-col>
            <v-col cols="12" class="pt-0">
              <v-text-field
                :label="` ${$t('general.email', $store.state.locale)}`"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                v-model="clientData.email"
                :rules="clientData.emailRules"
                required
                dense
                hide-details
                @click:append-outer="openSpellingData(clientData.email)"
                append-outer-icon="mdi-alphabetical-variant"
                ></v-text-field>
            </v-col>
            <v-col cols="6" class="pt-0">
                <v-select
                  :label="$t('general.language', $store.state.locale)"
                  outlined
                  dense
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  content-class="pa-0 ma-0 mr-4"
                  :items="availableLanguages"
                  v-model="clientData.language"
                  item-text="id"
                  >
                  <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                    <country-flag :country="data.item.flagCode" size='small'/>
                    <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                  </template>
                  <template slot="item" slot-scope="data">
                    <country-flag :country="data.item.flagCode" size='small'/>
                    <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                  </template>
                </v-select>
            </v-col>
            <v-col cols="6" class="pt-0">
              <v-select
                :label="$t('general.gender', $store.state.locale)"
                :items="genders"
                item-text="name"
                item-value="id"
                dense
                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                v-model="clientData.gender"
                outlined
                hide-details
              ></v-select>
            </v-col>

            
          </v-row>
          
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="mt-4">

            <h3 class="text-center py-3 pb-2">{{ $t('general.phones', $store.state.locale) }}</h3>

            <div v-if="!clientData.phones || clientData.phones.length == 0" class="text-center pa-1 pt-0">
              <v-avatar 
                size="46"
                light
                class="ma-4 custom-avatar-style" 
                rounded="lg"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                >
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone-ring</v-icon>
              </v-avatar>
              <p class="ma-0">{{ $t('clients.noPhonenumbersAdded', $store.state.locale) }}</p>
            </div>

            <div v-if="clientData.phones && clientData.phones.length > 0">
              <v-data-table
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :headers="phoneHeaders"
                :items-per-page="-1"
                :items="clientData.phones"
                hide-default-footer
                style="background: none"
                >
                <template v-slot:item="{item}">
                  <tr>
                    <td class="text-center">
                      <v-icon :size="18" v-if="item.type == 'mobile'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-cellphone</v-icon>
                      <v-icon :size="18" v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-deskphone</v-icon>
                    </td>
                    <td v-if="item.region == 'NL'">{{ item.phone.national }}</td>
                    <td v-else>{{ item.phone.international }}</td>
                    <td class="text-center">
                      <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                      <v-btn @click="setPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                    </td>
                    <td>
                      <v-btn icon small @click="removePhone(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-divider></v-divider>
            </div>

            <div class="text-center pa-4 pt-3">
              <v-btn fab small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addPhonenumber()"><v-icon>mdi-plus</v-icon></v-btn>
            </div>
          </v-card>

        </v-form>
      </div>
    </div>

    <p style="margin: auto; padding: 6px; font-size: 16px; font-weight: 700; text-align: center; display: block">{{ $t('clients.or', $store.state.locale) }}</p>

    <div class="text-center"  v-if="newclient" >
      <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark  @click="resetNewClient()">{{ $t('clients.searchExistingClient', $store.state.locale) }}</v-btn>
    </div>
    
    <div v-if="!newclient" style="border-radius: 5px" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].background }">
      <div class="pa-3">
        <div class="text-center">
          <h3 class="pb-2" style="font-weight: 700">{{ $t('clients.searchExistingClient', $store.state.locale) }}</h3>
        </div>

        <clientSearch :source="'clientSelector'" @clientSelect="selectClient"></clientSearch>  

      </div>
    </div>

    <!-- / New Phonenumber Dialog \ -->
    <add-phonenumber-dialog :type="'clientSelector'" @newPhoneNumber="addNewPhonenumber($event)"></add-phonenumber-dialog>
          <!-- \ New Phonenumber Dialog / -->
  </div>
</template>

<script>
  import { bus } from "../../main";
  import 'instantsearch.css/themes/satellite-min.css';
  import AddPhonenumber from "../modals/AddPhoneNumber.vue";
  import clientSearch from "@/components/elements/clientSearch.vue";
  import { parsePhoneNumber } from 'awesome-phonenumber';
  import '@firebase/firestore';
  import db from "@/firebase/init";

  export default {
    name: "clientSelector",
    props: {
      source: String,
    },
    components: {
      "add-phonenumber-dialog": AddPhonenumber,
      clientSearch
    },
    data() {
      return {
        clientData: {
          firstName: "",
          firstNameRules: [(v) => !!v || this.$t('booking.firstnameRequired', this.$store.state.locale)],
          surname: "",
          surnameRules: [(v) => !!v || this.$t('booking.lastnameRequired', this.$store.state.locale)],
          emails: new Array(),
          selectedEmail: "",
          id: null,
          minor: false,
          guardianClientIds: new Array(),
          minorType: null,
          gender: 2,
          language: null,
          phones: new Array(),
          selectedPhone: "",
          emailRules: [this.$t('booking.emailInvalid', this.$store.state.locale)],
        },
        guardianClientName: "",
        newclient: false, //Nieuwe client invoeren.
        search: "",
        clients: [],
        valid: false, //Zijn persoongevens goed ingevuld?
        phoneHeaders: [
          {
            text: this.$t('general.type', this.$store.state.locale),
            align: "center",
            value: "type",
            width: "50px",
            sortable: false,
          },
          {
            text: this.$t('general.phone', this.$store.state.locale),
            align: "left",
            value: "phone",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "50px",
            sortable: false,
          },
          {
            text: "",
            align: "center",
            value: "primary",
            width: "50px",
            sortable: false,
          }  
        ],
        genders: [
          {
            id: 1,
            name: this.$t('general.man', this.$store.state.locale)
          },
          {
            id: 2,
            name: this.$t('general.woman', this.$store.state.locale)
          }
        ],
        minorTypes: [ 
          {name:'child', id:'child'}, 
          {name:'needy', id:'needy'}, 
          {name:'partner', id:'partner'},   
        ],
        selectedClient: null,
        saveClientSelected: false
      }   
    },

    watch: {
      clientData: {
        handler() {
          if(this.newclient){
            if(this.clientData.firstName && this.clientData.firstName.length > 0 && this.clientData.surname && this.clientData.surname.length > 0){
              if(this.source == "changTreatmentTime"){
                bus.$emit("changeClientSelectedNewValid", this.clientData, true);
              }
              else{
                bus.$emit("bookingClientNewValid", this.clientData, true);
              }
            }
            else{
              if(this.source == "changTreatmentTime"){
                bus.$emit("changeClientSelectedNewValid", null, false);
              }
              else{
                bus.$emit("bookingClientNewValid", null, false);
              }
            }
          }
          else{
            if(this.source == "changTreatmentTime"){
              bus.$emit("changeClientSelectedNewValid", null, false);
            }
            else{
              bus.$emit("bookingClientNewValid", null, false);
            }
          }
        },
        deep: true
      }
    },

    created(){   
      this.clientData.language =this.availableLanguages[0].id;
      bus.$on("resetClientSelect", () => {
        this.resetNewClient()   
      });
    },

    methods:{

      openSpellingData(email){
      bus.$emit("spellingHelpModal", {email:email}, 'open', 'clientSelector');
    },

      async useSavedClient(){
        let clientId = this.$store.state.clientSaveData.minorId ? this.$store.state.clientSaveData.minorId : this.$store.state.clientSaveData.clientId;
        await db.collection("clients")
        .doc(clientId).get()
        .then(doc=>{
          if(doc.exists){
            this.clientData.firstName = doc.data().name;
            this.clientData.surname = doc.data().surname;
            this.clientData.emails = doc.data().emails && doc.data().emails.length > 0 ? doc.data().emails : new Array();
            this.clientData.selectedEmail = doc.data().emails && doc.data().emails.length > 0 ? doc.data().emails.find(email =>  email.primary ) : doc.data().emails && doc.data().emails.length > 0 ? doc.data().emails[0]: null;
            this.clientData.phones = doc.data().phones && doc.data().phones.length > 0 ? doc.data().phones : new Array();
            this.clientData.selectedPhone = doc.data().phones && doc.data().phones.length > 0 ? doc.data().phones.find(phone =>  phone.primary ) : doc.data().phones && doc.data().phones.length > 0 ? doc.data().phones[0]: null;
            this.clientData.id = doc.id;
            this.clientData.tags = doc.data().tags;
            this.clientData.minor = doc.data().minor ? doc.data().minor : false;
            this.clientData.minorType = doc.data().minor ? doc.data().minorType : null;
            this.clientData.guardianClientIds = doc.data().guardianClientIds ? doc.data().guardianClientIds : new Array();
          }
        })
        .catch((error) => {
          console.error("Error getting useSavedClient data: ", error);
        });
        this.saveClientSelected = true;
        this.emitSelection();
      },

      async createSavedClient(){
        this.newclient = true;
        this.clientData.gender = 2;
        this.clientData.firstName = this.$store.state.clientSaveData.firstName;
        this.clientData.surname = this.$store.state.clientSaveData.lastName;
        this.clientData.email = this.$store.state.clientSaveData.email;
        this.clientData.emails = new Array();
        this.clientData.selectedEmail = null;
        this.clientData.phones = new Array();
        this.clientData.selectedPhone = null;
        this.clientData.minor = this.$store.state.clientSaveData.minor ? this.$store.state.clientSaveData.minor : false;
        this.clientData.minorType = this.$store.state.clientSaveData.minorType ? this.$store.state.clientSaveData.minorType : null;
        this.clientData.guardianClientIds = this.$store.state.clientSaveData.minor &&  this.$store.state.clientSaveData.clientId ? [this.$store.state.clientSaveData.clientId] : new Array();
        this.search = "";
        this.selectedClient = null;

        const pn = await parsePhoneNumber(this.$store.state.clientSaveData.phone); // Check and add phonenumber from widget
        if(pn.g && pn.g.valid){
          let phoneData = {
            phone: pn.g.number,
            primary:true,
            type: pn.g.type,
            region: pn.g.regionCode,
          };

          this.clientData.phones.push(phoneData);
        }
      },

      async createNewClient(gender){
        // await this.checkDownPaymentSettings();
        this.newclient = true;
        this.clientData.gender = gender;
        this.clientData.firstName = null;
        this.clientData.surname = null;
        this.clientData.email = null;
        this.clientData.emails = new Array();
        this.clientData.selectedEmail = null;
        this.clientData.phones = new Array();
        this.clientData.selectedPhone = null;
        this.clientData.minor = false;
        this.clientData.minorType = null;
        this.clientData.guardianClientIds = new Array();
        this.search = "";
        this.selectedClient = null;
        this.saveClientSelected = false;

        if(this.source == "changTreatmentTime"){
          bus.$emit("changeClientSelected", null);
        }
      },

      setMinorData(){
        if(!this.clientData.minor){
          this.resetGuardianClientId();
          this.clientData.minorType = null;
        }
        else{
          this.clientData.minorType = 'child';
        }
      },

      async getSelectedClientData(clientData) {
        this.clientData.guardianClientIds = [clientData.objectID];
        this.guardianClientName = `${clientData.name} ${clientData.surname}`;
      },

      resetGuardianClientId(){
        this.clientData.guardianClientIds = new Array();
        this.guardianClientName = "";
      },

      addPhonenumber(){
        bus.$emit('addPhonenumberDialog', this.clientData.phones, 'clientSelector');
      },

      addNewPhonenumber(data){
        this.clientData.phones.push(data)
      },

      removePhone(item){
        this.clientData.phones = this.clientData.phones.filter(phone => {return phone.phone.e164 != item.phone.e164})
        this.checkForPrimaryNumber()
      },

      async checkForPrimaryNumber(){
        let primary = this.clientData.phones.filter(phone => {return phone.primary})
        if(!primary || primary.length == 0){
          let mobilePhones = this.clientData.phones.filter(phone => {return phone.type == 'mobile'})
          if(!mobilePhones || primary.mobilePhones == 0){
            this.setPrimary(this.clientData.phones[0])
          }
          else{
            this.setPrimary(mobilePhones[0])
          }
        }
      },
      
      setPrimary(item){
        this.clientData.phones.map(phone => { phone.phone.e164 == item.phone.e164 ? phone.primary = true : phone.primary = false })
      },

      resetNewClient(){
        this.search = "";
        this.newclient = false;
        this.clientData.firstName = null;
        this.clientData.surname = null;
        this.clientData.email = null;
        this.clientData.emails = null;
        this.clientData.selectedEmail = null;
        this.clientData.phones = null;
        this.clientData.selectedPhone = null;
        this.clientData.id = null;
        this.clientData.tags = null;
        this.selectedClient = null;
        this.clientData.minor = false;
        this.clientData.guardianClientIds = new Array();
        this.clientData.minorType = null;
        this.saveClientSelected = false;
      },

      selectClient(selectedClient) {
        this.newclient = false;
        if(this.clientData.id == selectedClient.objectID){
          this.clientData.firstName = null;
          this.clientData.surname = null;
          this.clientData.emails = null;
          this.clientData.selectedEmail = null;
          this.clientData.phones = null;
          this.clientData.selectedPhone = null;
          this.clientData.id = null;
          this.clientData.tags = null;
        }
        else{
          this.clientData.firstName = selectedClient.name;
          this.clientData.surname = selectedClient.surname;
          this.clientData.emails = selectedClient.emails && selectedClient.emails.length > 0 ? selectedClient.emails : new Array();
          this.clientData.selectedEmail = selectedClient.emails && selectedClient.emails.length > 0 ? selectedClient.emails.find(email =>  email.primary ) : selectedClient.emails && selectedClient.emails.length > 0 ? selectedClient.emails[0]: null;
          this.clientData.phones = selectedClient.phones && selectedClient.phones.length > 0 ? selectedClient.phones : new Array();
          this.clientData.selectedPhone = selectedClient.phones && selectedClient.phones.length > 0 ? selectedClient.phones.find(phone =>  phone.primary ) : selectedClient.phones && selectedClient.phones.length > 0 ? selectedClient.phones[0]: null;
          this.clientData.id = selectedClient.objectID;
          this.clientData.tags = selectedClient.tags;
          this.clientData.minor = selectedClient.minor ? selectedClient.minor : false;
          this.clientData.guardianClientIds = selectedClient.guardianClientIds ? selectedClient.guardianClientIds : new Array();
        }
        this.saveClientSelected = false;
        this.emitSelection()
      },

      emitSelection(){ // Return Selection to source
        if(this.source == "changTreatmentTime"){
          bus.$emit("changeClientSelected", this.clientData);
        }
        if(this.source == "subscriptionModal"){
          bus.$emit("subscriptionClientSelected", this.clientData);
        }
        if(this.source == "bookingWidgetCalendar"){
          bus.$emit("bookingClientSelected", this.clientData);
        }
      },

      getGender(gender){
        if(gender == 1){
          return "Man"
        }
        else if(gender == 2){
          return "Vrouw"
        }
        else{
          return "Onbekend"
        }
      },
    },

    computed: {
      availableLanguages(){
        let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
          if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
        });
        // Show languages in the same order as company languages
        return availableLanguages.sort((a, b) => this.$store.state.companyLanguages.indexOf(a.id) - this.$store.state.companyLanguages.indexOf(b.id)); 
      },
      
    }
  };
</script>
