
<template>
  <div>
    <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-4" fluid>
      <v-layout row wrap>
        <companySelector :showBranches="true" @companyChanged="loadData" @branchChanged="loadData"></companySelector>
        <v-flex md6 xs12 class="text-right"></v-flex>
      </v-layout>
    </v-container>

    <div v-if="loading" style="position: relative; padding: 200px 0;">
      <div class="thrive-loader"></div>
    </div>

    <div v-else>
      <v-container grid-list-md class="ma-0 pa-0">
        <v-layout row wrap>
          <v-flex md3 class="pr-4">
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%">
              <v-list class="pa-0 ma-0" expand dense>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('dashboard')" @click="selectReportView('dashboard')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('reports.dashboard', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-group :value="true" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('general.revenue', $store.state.locale) }}</v-list-item-title>
                  </template>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('revenueCurrent')" @click="selectReportView('revenueCurrent')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.revenue.current', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider> 
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('revenueOverview')" @click="selectReportView('revenueOverview')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.revenue.overview', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider> 
                    <!-- <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('revenueSpecific')" @click="selectReportView('revenueSpecific')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.revenue.specific', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> 
                    <v-divider></v-divider> -->
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('revenueVat')" @click="selectReportView('revenueVat')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('general.vat', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group :value="true" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('payment.deposits', $store.state.locale) }}</v-list-item-title>
                  </template>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('deposits')" @click="selectReportView('deposits')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.dashboard', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group :value="true" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('reports.sales.name', $store.state.locale) }}</v-list-item-title>
                  </template>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('salesTreatments')" @click="selectReportView('salesTreatments')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.sales.treatments', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider> 
                    <!-- <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('salesTreatmentTypes')" @click="selectReportView('salesTreatmentTypes')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.sales.treatmentTypes', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>  -->
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('salesProducts')" @click="selectReportView('salesProducts')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.sales.products', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-divider></v-divider>  -->
                    <!-- <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('salesProductTypes')" @click="selectReportView('salesProductTypes')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.sales.productTypes', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                      <v-divider></v-divider>  -->
                    <!-- <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('salesBrands')" @click="selectReportView('salesBrands')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.sales.brands', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->
                  </div>
                </v-list-group>
                  <v-divider></v-divider>
                <v-list-group :value="true" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('reports.register.register', $store.state.locale) }}</v-list-item-title>
                  </template>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('registerStatus')" @click="selectReportView('registerStatus')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.register.registerStatus', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider> 
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('cashUp')" @click="selectReportView('cashUp')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.register.cashUp', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-group>
                  <v-divider></v-divider>
                <v-list-group :value="true" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('reports.receipts.receipts', $store.state.locale) }}</v-list-item-title>
                  </template>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('receiptNumber')" @click="selectReportView('receiptNumber')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.receipts.number', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider> 
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('receiptAppointment')" @click="selectReportView('receiptAppointment')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.receipts.appointments', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider> 
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('receiptList')" @click="selectReportView('receiptList')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.receipts.list', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider> 
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('receiptOpen')" @click="selectReportView('receiptOpen')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.receipts.unfinished', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group :value="true" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('reports.clients.clients', $store.state.locale) }}</v-list-item-title>
                  </template>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('employeePerformance')" @click="selectReportView('branchRevenue')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.clients.spending', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                   <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('branchClients')" @click="selectReportView('branchClients')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.clients.bestClients', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-group :value="true" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template v-slot:activator>
                    <v-list-item-title>{{ $t('general.employees', $store.state.locale) }}</v-list-item-title>
                  </template>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('employeePerformance')" @click="selectReportView('employeePerformance')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.employees.performance', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('employeePersonalPerformance')" @click="selectReportView('employeePersonalPerformance')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.employees.personal', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  <v-divider></v-divider> 
                  <div :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
                    <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('employeeSicknessLeave')" @click="selectReportView('employeeSicknessLeave')">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('reports.employees.sicknessLeave', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-group>
                <!-- <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('appointments')" @click="selectReportView('appointments')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('reports.appointments', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
              </v-list>
            </v-card>

          </v-flex>

          <v-flex md9>
            <div v-if="dataLoading" style="position: relative; padding: 200px 0;">
              <div class="thrive-loader"></div>
            </div>

            <div v-show="!dataLoading && activeFilter">
                <div v-show="activeFilter == 'dashboard'">
                  <v-container grid-list-md class="ma-0 pa-0 pb-4" fluid v-show="indicators.length > 0">
                    <v-layout row wrap>
                      <v-flex md4 xs12>
                        <period-select :dataType="'dashboard'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select> 
                      </v-flex>
                      <v-flex md4 xs12>
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="employees"
                          item-text="name"
                          item-value="id"
                          v-model="dashboardDataFilter.employee"
                          :label="$t(`reports.table.employee`, $store.state.locale)"
                          single-line
                          @input="getDashboardData()"
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                              <img v-show="data.item.employeePhotoThumb" :src="data.item.employeePhotoThumb">
                              <v-icon v-show="!data.item.employeePhotoThumb">
                                mdi-account
                              </v-icon>
                            </v-avatar>
                            <span>{{ data.item.name }} </span>
                          </template>

                          <template v-slot:item="{item, attrs, on }" class="pa-0 ma-0">
                            <v-list-item v-on="on" v-bind="attrs" style="border-bottom: 1px solid #eee">
                              <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                                <img v-show="item.employeePhotoThumb" :src="item.employeePhotoThumb">
                                <v-icon v-show="!item.employeePhotoThumb">
                                  mdi-account
                                </v-icon>
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex md4 xs12 class="text-right">
                        <v-btn icon v-if="activeFilter == 'dashboard' && userRoleNumber < 2" fab dark small  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="moveGrid ? moveGrid = false : moveGrid = true;" class="mr-3">
                          <v-icon v-if="!moveGrid">mdi-arrow-all</v-icon> 
                          <v-icon v-if="moveGrid">mdi-cursor-default</v-icon> 
                        </v-btn>
                        <v-btn v-if="activeFilter == 'dashboard' && userRoleNumber < 2" fab dark small  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addNewIndicator()">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>

                  <div style="margin: -20px" v-if="indicators.length > 0"> 
                    <!-- <grid-layout 
                        v-if="dashboardSkeletonLoaded && !dashboardFullLoaded"
                        :layout.sync="indicators"
                        :col-num="12"
                        :row-height="30"
                        :is-draggable="false"
                        :is-resizable="false"
                        :is-mirrored="false"
                        :vertical-compact="true"
                        :margin="[20, 20]"
                        :use-css-transforms="true"
                      >
                      <grid-item 
                        v-for="(indicator, index) in indicators"
                        :x="indicator.x"
                        :y="indicator.y"
                        :w="indicator.w"
                        :h="indicator.h"
                        :i="indicator.i"
                        :key="index"
                        >
                        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="pa-3 theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%; height: 100%"> 
                            <v-list class="ma-0 pa-0 no-hover-effect" flat inactive>
                              <v-list-item class="pa-0 ma-0 pb-3">
                                <v-list-item-content  class="pa-0 ma-0">
                                  <v-list-item-subtitle>
                                    <v-skeleton-loader
                                      style="max-width: 30%"
                                      type="text"
                                    ></v-skeleton-loader>
                                  </v-list-item-subtitle>
                                  <v-skeleton-loader
                                    style="max-width: 60%"
                                    type="text"
                                  ></v-skeleton-loader>
                            
                                </v-list-item-content>
                              
                              </v-list-item>
                            </v-list>

                          <div v-if="indicator.w == 4">

                            <v-card-text  class="pa-0 ma-0">
                              <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-if="indicator.graphType == 'number'" >
                                <v-list-item class="pa-0 pl-0 ma-0">
                                  <v-list-item-avatar
                                    size="56"
                                    light
                                    rounded="lg"
                                    color="rgba(0,0,0,.12)" 
                                    >
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                    <v-skeleton-loader
                                      type="chip"
                                    ></v-skeleton-loader>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="indicator.graphType == 'number' && (indicator.treatmentTypes.length == 1 || indicator.productTypes.length == 1)">
                                      <v-chip v-if="indicator.treatmentTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getTreatmentTypeName(indicator.treatmentTypes[0]) }} </v-chip>
                                      <v-chip v-if="indicator.productTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getProductTypeName(indicator.productTypes[0]) }} </v-chip>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>


                              <div class="indictor-items pb-2" v-if="(indicator.graphType == 'number' && indicator.treatments.length > 0) || (indicator.graphType == 'number' && indicator.products.length > 0) || (indicator.graphType == 'number' && indicator.treatmentTypes.length > 0) || (indicator.graphType == 'number' && indicator.productTypes.length > 0) || indicator.graphType != 'number'"> 
                                <v-skeleton-loader
                                  style="max-width: 15%"
                                  type="text"
                                ></v-skeleton-loader>
                              </div>

                              <div v-if="indicator.graphType == 'number'">
                                <div v-if="indicator.useGoal" class="pt-1">
                                  <v-skeleton-loader
                                    type="text"
                                  ></v-skeleton-loader>
                                </div>
                              </div>

                            </v-card-text>
                          </div>

                          <div v-if="indicator.w == 8">
                            <v-layout row wrap>
                            <v-flex :class="[{'xs12' :indicator.graphType == 'number' },{'xs6' :indicator.graphType != 'number' }]">
                              <v-list class="ma-0 pa-0 pt-0 clear" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :class="{'pt-2': indicator.graphType != 'number'}">
                                <v-list-item class="pa-0 pl-0 ma-0">
                                  <v-list-item-avatar
                                    v-if="indicator.graphType == 'number'"
                                    size="56"
                                    light
                                    rounded="lg"
                                    color="rgba(0,0,0,.12)" 
                                    >
                                  </v-list-item-avatar>
                                  <v-list-item-content class="pa-0 ma-0">
                                  <v-skeleton-loader
                                      type="chip"
                                    ></v-skeleton-loader>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                              
                            </v-flex> 
                            <v-flex xs6 v-if="indicator.graphType != 'number'">

                            </v-flex>

                            <v-flex xs12 class="indictor-items pt-0 pb-2" v-if="(indicator.graphType == 'number' && indicator.treatments.length > 0) || (indicator.graphType == 'number' && indicator.products.length > 0) || (indicator.graphType == 'number' && indicator.treatmentTypes.length > 0) || (indicator.graphType == 'number' && indicator.productTypes.length > 0) || indicator.graphType != 'number'">
                              <v-skeleton-loader
                                class="pt-4"
                                style="max-width: 10%"
                                type="text"
                              ></v-skeleton-loader>
                            </v-flex>
                            <v-flex xs12 v-if="indicator.graphType == 'number' && indicator.useGoal" class="pt-0">
                              <div v-if="indicator.useGoal" class="px-1">
                                <v-skeleton-loader
                                  type="text"
                                ></v-skeleton-loader>
                              </div>
                            </v-flex>
                          </v-layout>
                          </div>
                        </v-card> 
                      </grid-item>
                    </grid-layout> -->

                    <grid-layout 
                        v-show="!dataLoading"
                        :layout.sync="indicators"
                        :col-num="12"
                        :row-height="30"
                        :is-draggable="moveGrid"
                        :is-resizable="false"
                        :is-mirrored="false"
                        :vertical-compact="true"
                        :margin="[20, 20]"
                        :use-css-transforms="true"
                      >
                      <grid-item 
                        v-for="(indicator, index) in indicators"
                        :x="indicator.x"
                        :y="indicator.y"
                        :w="indicator.w"
                        :h="indicator.h"
                        :i="indicator.i"
                        :key="index"
                        @moved="movedIndicator(indicator)"
                        >
                        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="pa-3 theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%; height: 100%">
                            <v-list class="ma-0 pa-0 no-hover-effect" flat inactive>
                              <v-list-item class="pa-0 ma-0 pb-3">
                                <v-list-item-content  class="pa-0 ma-0">
                                  <v-list-item-subtitle>
                                    <h4 class="indicator-subheading smooth">{{ $t(`indicator.${indicator.dataType}`, userLanguage) }}</h4>
                                  </v-list-item-subtitle>
                                  <div v-if="indicator.w == 8 || (indicator.w == 4  && indicator.graphType == 'number')">
                                    <v-list-item-title class="indicator-heading" v-if="indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, userLanguage) }}</v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length == 1"> {{ getTreatmentName(indicator.treatments[0]) }} </v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length == 1">  {{ getProductName(indicator.products[0]) }}</v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length > 0">{{ $t(`indicator.datafilter.treatments`, userLanguage) }}</v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length > 0">{{ $t(`indicator.datafilter.products`, userLanguage) }}</v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length == 1">{{ $t(`indicator.datafilter.treatmentCategory`, userLanguage) }}</v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length == 1">{{ $t(`indicator.datafilter.productCategory`, userLanguage) }}</v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 0">{{ $t(`indicator.datafilter.treatmentCategories`, userLanguage) }}</v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 0">{{ $t(`indicator.datafilter.productCategories`, userLanguage) }}</v-list-item-title>
                                  </div>
                                  <div v-else>  
                                    <v-list-item-title class="indicator-heading" v-if="indicator.dataType == 'revenue'">  
                                      {{ formatReportNumber(indicator.revenue, 0) }} 
                                    </v-list-item-title>
                                    <v-list-item-title class="indicator-heading" v-else>  
                                      {{ indicator.number }} 
                                    </v-list-item-title>
                                  </div>
                                </v-list-item-content>
                              
                                <v-list-item-action class="mt-0 ml-0"  style="z-index: 999">
                                  <v-menu
                                    v-if="userRoleNumber < 2" 
                                    transition="slide-y-transition"
                                    v-model="indicator.menu"
                                    :close-on-content-click="true"
                                    offset-y
                                    open-on-hover
                                    :content-class="`theme-shadow ${$store.state.companyTheme}`"
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                                    >
                                    <template v-slot:activator="{ on }">
                                      <v-btn v-on="on" icon>
                                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-dots-vertical</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-card max-width="200px" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                      <v-list dense class="ma-0 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                        <v-list-item class="ma-0 px-2" @click="editIndicator(indicator)">
                                          <v-list-item-icon class="mr-2">
                                            <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Bewerken</v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>

                                        <v-divider></v-divider>

                                        <v-list-item class="ma-0 px-2" >
                                          <v-list-item-icon class="mr-2">
                                            <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-delete</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Verwijderen</v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>

                                      </v-list>
                                    </v-card>
                                  </v-menu>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>

                          <div v-if="indicator.w == 4">

                            <v-card-text  class="pa-0 ma-0">
                              <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" v-if="indicator.graphType == 'number'" >
                                <v-list-item class="pa-0 pl-0 ma-0">
                                  <v-list-item-avatar
                                    size="56"
                                    light
                                    rounded="lg"
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" 
                                    >
                                    <v-icon v-if="indicator.dataType == 'revenue'" color="#fff">mdi-trending-up</v-icon>
                                    <v-icon v-else color="#fff">mdi-label</v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title v-if="indicator.dataType == 'revenue'">
                                      <h2 class="indicator-number">{{ formatReportNumber(indicator.revenue, 0) }} </h2>
                                    </v-list-item-title>
                                    <v-list-item-title v-else>
                                      <h2 class="indicator-number">{{ indicator.number }} </h2>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="indicator.graphType == 'number' && (indicator.treatmentTypes.length == 1 || indicator.productTypes.length == 1)">
                                      <v-chip v-if="indicator.treatmentTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getTreatmentTypeName(indicator.treatmentTypes[0]) }} </v-chip>
                                      <v-chip v-if="indicator.productTypes.length == 1" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getProductTypeName(indicator.productTypes[0]) }} </v-chip>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>

                              <div v-if="indicator.graphType == 'bar' || indicator.graphType == 'line'" >
                                <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && !indicator.useGoal" class="pt-3">
                               
                                  <apexchart width="100%" height="50" :type="indicator.graphType" :options="barometerChartOptions[indicator.color]"  :series="indicator.chartData"></apexchart>
                                </div>

                                <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && indicator.useGoal" class="pb-0" style="margin: -45px 0 0 0">
                                 
                                  <apexchart width="100%" height="270" type="radialBar" :options="barometerChartOptions[indicator.color]" :series="indicator.goalRadial"></apexchart>
                                </div>
                              </div>

                              <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, userLanguage) }}</h5>
                              <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length  > 1">{{ $t(`indicator.datafilter.treatments`, userLanguage) }}</h5>
                              <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length  > 1">{{ $t(`indicator.datafilter.products`, userLanguage) }}</h5>
                              <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length < 2">{{ $t(`indicator.datafilter.treatmentCategory`, userLanguage) }}</h5>
                              <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length < 2">{{ $t(`indicator.datafilter.productCategory`, userLanguage)  }}</h5>
                              <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 0">{{ $t(`indicator.datafilter.treatmentCategories`, userLanguage) }}</h5>
                              <h5 class="indicator-subheading smooth text-center" v-if="indicator.graphType != 'number' && indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 0">{{ $t(`indicator.datafilter.productCategories`, userLanguage) }}</h5>

                              <div class="indictor-items pb-2" v-if="(indicator.graphType == 'number' && indicator.treatments.length > 0) || (indicator.graphType == 'number' && indicator.products.length > 0) || (indicator.graphType == 'number' && indicator.treatmentTypes.length > 0) || (indicator.graphType == 'number' && indicator.productTypes.length > 0) || indicator.graphType != 'number'">
                                <v-chip v-for="(treatment, index) in indicator.treatments" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style"> {{ getTreatmentName(treatment) }}</v-chip>
                                <v-chip v-for="(product, index) in indicator.products" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getProductName(product) }}</v-chip>
                                <v-chip v-for="(treatmentType, index) in indicator.treatmentTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getTreatmentTypeName(treatmentType) }}</v-chip>
                                <v-chip v-for="(productType, index) in indicator.productTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mx-1 my-1 custom-chip-style">{{ getProductTypeName(productType) }}</v-chip>
                              </div>

                              <div v-if="indicator.graphType == 'number'">
                                <div v-if="indicator.useGoal" class="px-1">
                                  <strong>{{ indicator.goal }}%</strong>
                                  <v-progress-linear :value="indicator.goal" :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" ></v-progress-linear>
                                </div>
                              </div>

                            </v-card-text>
                          </div>

                          <div v-if="indicator.w == 8">
                            <v-layout row wrap>
                            <!-- <v-flex xs12>
                              <v-list class="ma-0 pa-0 no-hover-effect" flat inactive>
                                <v-list-item class="pa-0 ma-0" style="min-height: 0px;">
                                  <v-list-item-content  class="pa-0 ma-0">
                                    <div>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.dataFilter == 'all'">{{ $t(`indicator.${indicator.data}`, userLanguage) }}</v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length == 1"> {{ getTreatmentName(indicator.treatments[0]) }} </v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length == 1">  {{ getProductName(indicator.products[0]) }}</v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'selected' && indicator.treatments.length > 0">{{ $t(`indicator.datafilter.treatments`, userLanguage) }}</v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'selected' && indicator.products.length > 0">{{ $t(`indicator.datafilter.products`, userLanguage) }}</v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length < 2">{{ $t(`indicator.datafilter.treatmentCategory`, userLanguage) }}</v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length < 2">{{ $t(`indicator.datafilter.productCategory`, userLanguage) }}</v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'treatments' && indicator.dataFilter == 'categories' && indicator.treatmentTypes.length > 0">{{ $t(`indicator.datafilter.treatmentCategories`, userLanguage) }}</v-list-item-title>
                                      <v-list-item-title class="indicator-heading" :class="'size-'+ indicator.w" v-if="indicator.data == 'products' && indicator.dataFilter == 'categories' && indicator.productTypes.length > 0">{{ $t(`indicator.datafilter.productCategories`, userLanguage) }}</v-list-item-title>
                                    </div>
                                  </v-list-item-content>
                                  <v-list-item-action class="mt-0 mb-0 ml-0"  style="z-index: 999">
                                    <v-btn icon>
                                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-flex>
                            -->
                            <v-flex :class="[{'xs12' :indicator.graphType == 'number' },{'xs6' :indicator.graphType != 'number' }]">
                              <v-list class="ma-0 pa-0 pt-0 clear" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :class="{'pt-2': indicator.graphType != 'number'}">
                                <v-list-item class="pa-0 pl-0 ma-0">
                                  <v-list-item-avatar
                                    v-if="indicator.graphType == 'number'"
                                    size="56"
                                    light
                                    rounded="lg"
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" 
                                    >
                                    <v-icon v-if="indicator.dataType == 'revenue'" color="#fff">mdi-trending-up</v-icon>
                                    <v-icon v-else color="#fff">mdi-label</v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content class="pa-0 ma-0">
                                    <!-- <v-list-item-subtitle>
                                      <h4 class="indicator-subheading smooth">{{ $t(`indicator.${indicator.dataType}`, userLanguage) }}</h4>
                                    </v-list-item-subtitle> -->
                                    <v-list-item-title v-if="indicator.dataType == 'revenue'">
                                      <h2 class="indicator-number" :class="'size-'+ indicator.w">{{ formatReportNumber(indicator.revenue, 0) }} </h2>
                                    </v-list-item-title>
                                    <v-list-item-title v-else>
                                      <h2 class="indicator-number" :class="'size-'+ indicator.w">{{ indicator.number }} </h2>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                              
                            </v-flex> 
                            <v-flex xs6 v-if="indicator.graphType != 'number'">
                              <div v-if="indicator.graphType == 'bar' || indicator.graphType == 'line'" >
                                <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && !indicator.useGoal" class="pt-1">
                                  <apexchart width="100%" height="70" :type="indicator.graphType" :options="barometerChartOptions[indicator.color]"  :series="indicator.chartData"></apexchart>
                                </div>

                                <div v-if="(indicator.graphType == 'bar' || indicator.graphType == 'line') && indicator.useGoal" class="pb-0" style="margin: -45px 0 0 0">
                                  <apexchart width="100%" height="270" type="radialBar" :options="barometerChartOptions[indicator.color]" :series="indicator.goalRadial"></apexchart>
                                </div>
                              </div>

                            </v-flex>

                            <v-flex xs12 class="indictor-items pt-0 pb-2" v-if="(indicator.graphType == 'number' && indicator.treatments.length > 0) || (indicator.graphType == 'number' && indicator.products.length > 0) || (indicator.graphType == 'number' && indicator.treatmentTypes.length > 0) || (indicator.graphType == 'number' && indicator.productTypes.length > 0) || indicator.graphType != 'number'">
                              <v-chip v-for="(treatment, index) in indicator.treatments" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style"> {{ getTreatmentName(treatment) }}</v-chip>
                              <v-chip v-for="(product, index) in indicator.products" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getProductName(product) }}</v-chip>
                              <v-chip v-for="(treatmentType, index) in indicator.treatmentTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getTreatmentTypeName(treatmentType) }}</v-chip>
                              <v-chip v-for="(productType, index) in indicator.productTypes" :key="index" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" light class="mr-2 mb-1 custom-chip-style">{{ getProductTypeName(productType) }}</v-chip>
                            </v-flex>
                            <v-flex xs12 v-if="indicator.graphType == 'number' && indicator.useGoal" class="pt-0">
                              <div v-if="indicator.useGoal" class="px-1">
                                <strong>{{ indicator.goal }}%</strong>
                                <v-progress-linear :value="indicator.goal" :color="$themes[$store.state.companyTheme][$store.state.themeModus][indicator.color]" ></v-progress-linear>
                              </div>
                            </v-flex>
                          </v-layout>
                          </div>
                        </v-card> 
                      </grid-item>
                    </grid-layout>
                  </div>
                  <div v-else class="text-center ma-4" style="padding: 100px 0 0 0;">
                    <v-avatar 
                      size="66"
                      light
                      class="ma-4 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-line</v-icon>
                    </v-avatar>
                    
                    <div style="font-size: 16px;">{{ $t('reports.noIndicators', $store.state.locale) }}</div>

                    <v-btn class="ma-4" v-if="userRoleNumber < 2" dark small  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addNewIndicator()">
                      <v-icon left>mdi-plus</v-icon> {{ $t('indicator.newIndicator', $store.state.locale) }}
                    </v-btn>
                  </div>
                </div>
          
                <v-container grid-list-md class="ma-0 pa-0" v-if="activeFilter == 'revenueCurrent'">
                  <v-layout row wrap>
                    <v-flex md9>
                      <h2>{{ $t('reports.actualRevenue', $store.state.locale) }}</h2>
                    </v-flex>
                    <v-flex md3>
                      <v-select
                        style="max-width: 300px; float: right"  
                        :label="$t('reports.revenue.view', $store.state.locale)"
                        :items="vatViewTypes"
                        :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                        item-value="id"
                        v-model="vatViewType"
                        outlined
                        dense
                        @change="getCurrentRevenue()"
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        required
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap v-if="actualRevenueLoading">
                    <v-flex md12 class="text-center">
                      <div style="position: relative; padding: 200px 0;">
                        <div class="thrive-loader"></div>
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap v-if="!actualRevenueLoading">
                    <v-flex md9 d-flex>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" style="width: 100%">
                        <div class="pa-2">
                          <v-row>
                            <v-col>
                              <h3>{{ $t('general.week', $store.state.locale) }} {{ actualRevenueDates.weekNumber}}</h3>
                              <p class="ma-0"><span class="captialize-first-word">{{ actualRevenueDates.startDay}}</span> - <span class="captialize-first-word">{{ actualRevenueDates.endDay}}</span></p>
                            </v-col>
                            <v-col class="text-right">
                              <v-btn icon class="mx-1" @click="getCurrentRevenue('previous')"><v-icon>mdi-chevron-left</v-icon></v-btn><v-btn icon class="mx-1" @click="getCurrentRevenue('next')"><v-icon>mdi-chevron-right</v-icon></v-btn>
                            </v-col>
                          </v-row>
                        </div>
                        <apexchart class="actual-chart-height"  width="100%" :height="actualChartHeight" :options="actualChartOptions" :series="actualChartseries"></apexchart>
                      </v-card>
                    </v-flex>

                    <v-flex md3>
                      <v-card v-if="actualRevenueDates.weekType == 'now' || actualRevenueDates.weekType == 'past' ":color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-2" :class="$store.state.companyTheme">
                        <v-card-text class="ma-0">
                          <div style="display: flex; justify-content: space-between;">
                            {{ $t('reports.currentRevenue', $store.state.locale) }} 
                            <v-tooltip left style="cursor: pointer;">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].chartSecondary" size="14">mdi-help-circle</v-icon>                                                                   
                              </template>
                              <span style="color: #fff"> {{ $t('reports.currentRevenueHint', $store.state.locale) }}</span>
                            </v-tooltip>
                          </div>
                          <p class="display-1 text--primary ma-0"> {{ formatReportNumber(actualRevenue.current.view, 0) }}</p>
                        </v-card-text>
                      </v-card>

                      <v-layout row wrap d-flex v-if="actualRevenueDates.weekType == 'now' || actualRevenueDates.weekType == 'past'" class="mb-2">
                        <v-flex md6 >
                          <v-card d-flex :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" style="height: 100%" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-card-text class="ma-0">
                              <div style="display: flex; justify-content: space-between;">
                                {{ $t('general.treatments', $store.state.locale) }}
                                <v-tooltip left style="cursor: pointer;">
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].chartSecondary" size="14">mdi-help-circle</v-icon>                                                                   
                                  </template>
                                  <span style="color: #fff"> {{ $t('reports.numberOfTreatmentsHint', $store.state.locale) }}</span>
                                </v-tooltip>
                              </div>
                              <p class="display-1 text--primary ma-0 mt-1">
                                {{ actualRevenue.treatments}}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                        <v-flex md6 >
                          <v-card d-flex :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" style="height: 100%" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-card-text class="ma-0">
                              <div style="display: flex; justify-content: space-between;">
                                {{ $t('general.products', $store.state.locale) }}
                                <v-tooltip left style="cursor: pointer;">
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].chartSecondary" size="14">mdi-help-circle</v-icon>                                                                   
                                  </template>
                                  <span style="color: #fff"> {{ $t('reports.numberOfProductsHint', $store.state.locale) }}</span>
                                </v-tooltip>
                              </div>
                              <p class="display-1 text--primary ma-0 mt-1" >
                                {{ actualRevenue.products}}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>

                      <v-card v-if="actualRevenueDates.weekType == 'now'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-3" :class="$store.state.companyTheme">
                        <v-card-text class="ma-0">
                          <div style="display: flex; justify-content: space-between;">
                            {{ $t('reports.expectedRevenue', $store.state.locale) }}
                            <v-tooltip left style="cursor: pointer;">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].chartSecondary" size="14">mdi-help-circle</v-icon>                                                                   
                              </template>
                              <span style="color: #fff"> {{ $t('reports.expectedRevenueHint', $store.state.locale) }}</span>
                            </v-tooltip>
                          </div>
                          <p v-if="vatViewType =='excluded'" class="display-1 text--primary ma-0"> {{ formatReportNumber(actualRevenue.estimated.netto, 0) }}</p>
                          <p v-else class="display-1 text--primary ma-0"> {{ formatReportNumber(actualRevenue.estimated.bruto, 0) }}</p>
                        </v-card-text>
                      </v-card>

                      <v-card v-if="actualRevenueDates.weekType == 'now' || actualRevenueDates.weekType == 'future'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-0" :class="$store.state.companyTheme">
                        <v-card-text class="ma-0">
                          <div style="display: flex; justify-content: space-between;">
                            {{ $t('reports.expectedTotalRevenue', $store.state.locale) }}
                            <v-tooltip left style="cursor: pointer;">
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].chartSecondary" size="14">mdi-help-circle</v-icon>                                                                   
                              </template>
                              <span style="color: #fff" v-if="actualRevenueDates.weekType == 'now'""> {{ $t('reports.expectedTotalRevenueHint', $store.state.locale) }}</span>
                              <span style="color: #fff" v-else> {{ $t('reports.expectedRevenueHint', $store.state.locale) }}</span>
                            </v-tooltip>
                          </div>
                          <p class="display-1 text--primary ma-0"> {{ formatReportNumber(actualRevenue.estimatedTotal.view, 0) }}</p>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>

                <!-- / OMZET \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-if="activeFilter == 'revenueOverview'">
                  <v-layout row wrap class="pa-0 pb-1" >
                    <v-flex md4>
                      <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.revenueOverview', $store.state.locale) }}</h2>
                    </v-flex>
                    <v-flex md8 class="text-right">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-on="on" v-bind="attrs"><v-icon>mdi-download</v-icon></v-btn>        
                        </template>
                      <v-list dense class="ma-0 pa-0">
                        <v-list-item-group class="ma-0 pa-0">
                          <v-list-item @click.native="downloadDataToPDF('revenue-overview-data')">
                            <v-list-item-icon>
                              <v-icon>mdi-file-pdf-box</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Download als PDF</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click.native="downloadDataToPDF('revenue-overview-data')">
                            <v-list-item-icon>
                              <v-icon>mdi-file-delimited</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title >Download als CSV</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                      <v-select
                        style="max-width: 300px; float: right"  
                        :label="$t('reports.revenue.view', $store.state.locale)"
                        :items="vatViewTypes"
                        :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                        item-value="id"
                        v-model="vatViewType"
                        outlined
                        dense
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        required
                      ></v-select>
                      <v-select
                        style="max-width: 300px; float: right"
                        class="mx-2"
                        :label="$t('conversion.selectYear', $store.state.locale)"
                        :items="branchYears"
                        v-model="selectedYear"
                        outlined
                        dense
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        required
                        @change="selectReportView('revenueOverview')"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap id="revenue-overview-data">
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                      <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-view-grid</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.quarter', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :items-per-page="itemsPerPage"
                          :items="revenueOverview.quarters"
                          :headers="revenueHeader"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ $t('general.quarter', $store.state.locale) }} {{ item.quarter }}</td>

                              <td v-if="vatViewType != 'included' && item.treatmentsNetto > 0" class="text-right"> {{formatReportNumber(item.treatmentsNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.treatmentsBruto > 0" class="text-right"> {{formatReportNumber(item.treatmentsBruto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td v-if="vatViewType != 'included' && item.productsNetto > 0" class="text-right"> {{formatReportNumber(item.productsNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.productsBruto > 0" class="text-right"> {{formatReportNumber(item.productsBruto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td v-if="vatViewType != 'included' && item.totalNetto > 0" class="text-right"> {{formatReportNumber(item.totalNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.totalBruto > 0" class="text-right"> {{ formatReportNumber(item.totalBruto) }}</td>
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td v-if="vatViewType != 'included'" class="text-right"> <strong>{{getRevenueTotal('treatmentsNetto') }}</strong></td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('treatmentsBruto') }}</strong> </td>

                              <td v-if="vatViewType != 'included'" class="text-right"><strong>{{getRevenueTotal('productsNetto') }}</strong> </td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('productsBruto') }}</strong> </td>

                              <td v-if="vatViewType != 'included'" class="text-right"><strong>{{getRevenueTotal('totalNetto') }}</strong> </td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('totalBruto') }}</strong> </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                      <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-month</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.month', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>

                        <v-data-table
                          :items-per-page="itemsPerPage"
                          :items="revenueOverview.months"
                          hide-default-footer
                          :headers="revenueHeader"
                          dense
                          class="revenue-overview-table"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.name }}</td>

                              <td v-if="vatViewType != 'included' && item.treatmentsNetto > 0" class="text-right"> {{formatReportNumber(item.treatmentsNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.treatmentsBruto > 0" class="text-right"> {{formatReportNumber(item.treatmentsBruto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td v-if="vatViewType != 'included' && item.productsNetto > 0" class="text-right"> {{formatReportNumber(item.productsNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.productsBruto > 0" class="text-right"> {{formatReportNumber(item.productsBruto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td v-if="vatViewType != 'included' && item.totalNetto > 0" class="text-right"> {{formatReportNumber(item.totalNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.totalBruto > 0" class="text-right"> {{formatReportNumber(item.totalBruto) }}</td>
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td v-if="vatViewType != 'included'" class="text-right"> <strong>{{getRevenueTotal('treatmentsNetto') }}</strong></td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('treatmentsBruto') }}</strong> </td>

                              <td v-if="vatViewType != 'included'" class="text-right"><strong>{{getRevenueTotal('productsNetto') }}</strong> </td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('productsBruto') }}</strong> </td>

                              <td v-if="vatViewType != 'included'" class="text-right"><strong>{{getRevenueTotal('totalNetto') }}</strong> </td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('totalBruto') }}</strong> </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-week</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.week', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :items-per-page="itemsPerPage"
                          :items="revenueOverview.weeks"
                          hide-default-footer
                          :headers="revenueHeader"
                          dense
                          class="revenue-overview-table"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ $t('general.week', $store.state.locale) }} {{ item.week }}</td>

                              <td v-if="vatViewType != 'included' && item.treatmentsNetto > 0" class="text-right"> {{formatReportNumber(item.treatmentsNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.treatmentsBruto > 0" class="text-right"> {{formatReportNumber(item.treatmentsBruto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td v-if="vatViewType != 'included' && item.productsNetto > 0" class="text-right"> {{formatReportNumber(item.productsNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.productsBruto > 0" class="text-right"> {{formatReportNumber(item.productsBruto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td v-if="vatViewType != 'included' && item.totalNetto > 0" class="text-right"> {{formatReportNumber(item.totalNetto) }}</td>
                              <td v-else-if="vatViewType == 'included' && item.totalBruto > 0" class="text-right"> {{formatReportNumber(item.totalBruto) }}</td>
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td v-if="vatViewType != 'included'" class="text-right"> <strong>{{getRevenueTotal('treatmentsNetto') }}</strong></td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('treatmentsBruto') }}</strong> </td>

                              <td v-if="vatViewType != 'included'" class="text-right"><strong>{{getRevenueTotal('productsNetto') }}</strong> </td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('productsBruto') }}</strong> </td>

                              <td v-if="vatViewType != 'included'" class="text-right"><strong>{{getRevenueTotal('totalNetto') }}</strong> </td>
                              <td v-else class="text-right"><strong>{{getRevenueTotal('totalBruto') }}</strong> </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ OMZET / -->

                <!-- / BTW \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-if="activeFilter == 'revenueVat'">
                  <v-layout row wrap class="pa-0 pt-0 pb-1" >
                    <v-flex md6>
                      <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.revenueVat', $store.state.locale) }}</h2>
                    </v-flex>
                    <v-flex md6>
                      <v-select
                        style="max-width: 300px; float: right"
                        :label="$t('conversion.selectYear', $store.state.locale)"
                        :items="branchYears"
                        v-model="selectedYear"
                        outlined
                        dense
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        required
                        @change="selectReportView('revenueVat')"
                      ></v-select>
                    </v-flex>
                    
                  </v-layout>
                  <v-layout row wrap>

                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-view-grid</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.quarter', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>

                        <v-data-table
                          :headers="revenueVatHeader"
                          :items-per-page="itemsPerPage"
                          :items="vatOverview.quarters"
                          dense
                          hide-default-footer
                          class="revenue-overview-table"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td v-if="item.quarter !== 'total'">{{ $t('general.quarter', $store.state.locale) }} {{ item.quarter }}</td>
                              <td v-else><strong>{{ $t('general.total', $store.state.locale)  }}</strong></td>
                              <td class="text-right" v-if="item.vatPercentages[9] && item.vatPercentages[9].totalNetto > 0" :style="{ fontWeight: getFontWeight(item.quarter)}" > {{ formatReportNumber(item.vatPercentages[9].totalNetto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td class="text-right" v-if="item.vatPercentages[9] && item.vatPercentages[9].totalVat > 0" :style="{ fontWeight: getFontWeight(item.quarter)}" > {{ formatReportNumber(item.vatPercentages[9].totalVat) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td class="text-right" v-if="item.vatPercentages[21] && item.vatPercentages[21].totalNetto > 0" :style="{ fontWeight: getFontWeight(item.quarter)}" > {{ formatReportNumber(item.vatPercentages[21].totalNetto) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td class="text-right" v-if="item.vatPercentages[21] && item.vatPercentages[21].totalVat > 0" :style="{ fontWeight: getFontWeight(item.quarter)}" > {{ formatReportNumber(item.vatPercentages[21].totalVat) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td class="text-right" v-if="item.totalVat > 0" :style="{ fontWeight: getFontWeight(item.quarter)}" > {{ formatReportNumber(item.totalVat) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td class="text-right" v-if="item.totalBruto > 0" :style="{ fontWeight: getFontWeight(item.quarter)}" > {{ formatReportNumber(item.totalBruto) }}</td>
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-month</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.month', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="revenueVatHeaderMonth"
                          :items-per-page="itemsPerPage"
                          :items="vatOverview.months"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td v-if="item.name !== 'total'">{{ item.name }}</td>
                              <td v-else><strong>{{ $t('general.total', $store.state.locale)  }}</strong></td>
                              <td class="text-right" v-if="item.vatPercentages[9] && item.vatPercentages[9].totalNetto > 0" :style="{ fontWeight: getFontWeight(item.name)}" > {{ formatReportNumber(item.vatPercentages[9].totalNetto) }}</td>
                              <td class="text-right" v-else>-</td>
                              <td class="text-right" v-if="item.vatPercentages[9] && item.vatPercentages[9].totalVat > 0" :style="{ fontWeight: getFontWeight(item.name)}" > {{ formatReportNumber(item.vatPercentages[9].totalVat) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td class="text-right" v-if="item.vatPercentages[21] && item.vatPercentages[21].totalNetto > 0" :style="{ fontWeight: getFontWeight(item.name)}" > {{ formatReportNumber(item.vatPercentages[21].totalNetto) }}</td>
                              <td class="text-right" v-else>-</td>
                              <td class="text-right" v-if="item.vatPercentages[21] && item.vatPercentages[21].totalVat > 0" :style="{ fontWeight: getFontWeight(item.name)}" > {{ formatReportNumber(item.vatPercentages[21].totalVat) }}</td>
                              <td class="text-right" v-else>-</td>

                              <td class="text-right" v-if="item.totalVat > 0" :style="{ fontWeight: getFontWeight(item.name)}" > {{ formatReportNumber(item.totalVat) }}</td>
                              <td class="text-right" v-else>-</td>
                              <td class="text-right" v-if="item.totalBruto > 0" :style="{ fontWeight: getFontWeight(item.name)}" >  {{ formatReportNumber(item.totalBruto) }}</td>
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ BTW / -->

                <!-- / Aanbetalingen \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'deposits'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-2" fluid>
                    <v-layout row wrap>
                      <v-flex md12 class="pt-0">
                          <h2 class="pb-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('payment.deposits', $store.state.locale) }}</h2>
                        </v-flex>
                      <v-flex md4 xs12>
                        <period-select :dataType="'deposit'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                      </v-flex>
                      <!-- <v-flex md4 xs12>
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="employees"
                          item-text="name"
                          item-value="id"
                          v-model="treatmentsDataFilter.employee"
                          :label="$t(`reports.table.employee`, $store.state.locale)"
                          single-line
                          @input="getTreatmentData()"
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                              <img v-show="data.item.employeePhotoThumb" :src="data.item.employeePhotoThumb">
                              <v-icon v-show="!data.item.employeePhotoThumb">
                                mdi-account
                              </v-icon>
                            </v-avatar>
                            <span>{{ data.item.name }}</span>
                          </template>

                          <template v-slot:item="{item, attrs, on }" class="pa-0 ma-0">
                            <v-list-item v-on="on" v-bind="attrs" style="border-bottom: 1px solid #eee">
                              <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                                <img v-show="item.employeePhotoThumb" :src="item.employeePhotoThumb">
                                <v-icon v-show="!item.employeePhotoThumb">
                                  mdi-account
                                </v-icon>
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.name }} 
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex md4 xs12>
                          <v-select
                            style="width: 100%;"
                            :label="$t('reports.revenue.view', $store.state.locale)"
                            :items="vatViewTypes"
                            :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                            item-value="id"
                            v-model="vatViewType"
                            outlined
                            dense
                            hide-details
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            required
                          ></v-select>
                      </v-flex> -->
                    </v-layout>
                  </v-container>
                  
                  <v-layout row wrap>
                    <v-flex md12 v-if="depositData.total.number > 0">
                      <v-layout row wrap class="pa-0" style="margin:6px -4px!important">
                        <v-flex md8 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-row>
                              <v-col>
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="76"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                      >
                                      <v-icon size="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-check</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="pa-0">
                                      <v-list-item-title>
                                        <h3>{{ $t(`reports.downPayment.total`, $store.state.locale) }}</h3>
                                      </v-list-item-title>

                                      <v-list-item-title>
                                        <h4 :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ formatReportNumber(depositData.total.amount) }} </h4>
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="text-transform: lowercase;font-weight:300;">{{ depositData.total.number }}x </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                              <v-col>
                                <v-sparkline
                                  :labels="depositData.graph.labels"
                                  :value="depositData.graph.value"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                  line-width="4"
                                  height="60"
                                  padding="6"
                                  :gradient="[$themes[$store.state.companyTheme][$store.state.themeModus].primary, $themes[$store.state.companyTheme][$store.state.themeModus].primary + 30]"
                                  smooth
                                  class="mr-3"
                                  ></v-sparkline>
                                <div class="text-center pb-1" style="font-size: 9px">
                                  {{ $t(`general.`+depositData.graph.type, $store.state.locale) }}
                                </div>
                              </v-col>
                            </v-row>
                            
                          </v-card>
                        </v-flex>

                        <v-flex md4 xs6>
                          <v-tooltip dark bottom> 
                            <template v-slot:activator="{ on }">         
                              <v-card flat  v-on="on" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="76"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                                      >
                                      <v-icon size="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-cash-register</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <h3>{{ $t(`reports.downPayment.open`, $store.state.locale) }} <v-icon x-small>mdi-help-circle</v-icon></h3>
                                      </v-list-item-title>
                                      <v-list-item-title>
                                        <h4 :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}">{{ formatReportNumber(depositData.open.amount) }} </h4>
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="text-transform: lowercase; font-weight:300;" >{{ depositData.open.number }}x </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </template>
                            <span class="tooltip-text-white">{{ $t(`reports.downPayment.openHint`, $store.state.locale) }}</span>
                          </v-tooltip>
                        </v-flex>

                        <v-flex md4 xs6>
                          <v-tooltip dark bottom> 
                            <template v-slot:activator="{ on }">         
                              <v-card flat  v-on="on" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="76"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].quaternary}" 
                                      >
                                      <v-icon size="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary">mdi-cash-minus</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <h3>{{ $t(`reports.downPayment.settled`, $store.state.locale) }} <v-icon x-small>mdi-help-circle</v-icon></h3>
                                      </v-list-item-title>
                                      <v-list-item-title>
                                        <h4 :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].quaternary}">{{ formatReportNumber(depositData.settled.amount) }} </h4>
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="text-transform: lowercase;font-weight:300;">{{ depositData.settled.number }}x </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </template>
                            <span class="tooltip-text-white">{{ $t(`reports.downPayment.settledHint`, $store.state.locale) }}</span>
                          </v-tooltip>
                        </v-flex>

                        <v-flex md4 xs6>
                          <v-tooltip dark bottom> 
                            <template v-slot:activator="{ on }">         
                              <v-card flat  v-on="on" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="76"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].quaternary}" 
                                      >
                                      <v-icon size="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary">mdi-cash-refund</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <h3>{{ $t(`reports.downPayment.refunded`, $store.state.locale) }} <v-icon x-small>mdi-help-circle</v-icon></h3>
                                      </v-list-item-title>
                                      <v-list-item-title>
                                        <h4 :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].quaternary}">{{ formatReportNumber(depositData.refunded.amount) }} </h4>
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="text-transform: lowercase;font-weight:300;">{{ depositData.refunded.number }}x </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </template>
                            <span class="tooltip-text-white">{{ $t(`reports.downPayment.refundedHint`, $store.state.locale) }}</span>
                          </v-tooltip>
                        </v-flex>
                        <v-flex md4 xs6>
                          <v-tooltip dark bottom> 
                            <template v-slot:activator="{ on }">         
                              <v-card flat  v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="76"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
                                      >
                                      <v-icon size="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-cash-lock</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="pa-0">
                                      <v-list-item-title>
                                        <h3 >{{ $t(`reports.downPayment.compensation`, $store.state.locale) }} <v-icon x-small>mdi-help-circle</v-icon></h3>
                                      </v-list-item-title>
                                        
                                      <v-list-item-title>
                                        <h4 :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].secondary}">{{ formatReportNumber(depositData.compensation.amount) }} </h4>
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="text-transform: lowercase;font-weight:300;">{{ depositData.compensation.number }}x </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </template>
                            <span class="tooltip-text-white">{{ $t(`reports.downPayment.compensationHint`, $store.state.locale) }}</span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex md12>

                      <!-- / Compensation downpayments \ -->
                      <v-card v-if="depositData.compensation.number > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-cash-lock</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.downPayment.compensationTitle', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="depositsHeader"
                          :items-per-page="itemsPerPage"
                          :items="depositData.compensation.data"
                          hide-default-footer
                          dense
                          :key="depositData.compensation.data.length"
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.clientName }}</td>
                              <td>
                                <v-chip v-if="item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style">{{ $t('branch.widgetSettings', $store.state.locale) }} </v-chip>
                                <v-chip v-if="!item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downpaymentRequiredEmailShort', $store.state.locale) }} </v-chip>
                              </td>
                              <td>{{ formatReportDate(item.timestamp.seconds, "X", "YYYY-MM-DD") }} om {{ formatReportDate(item.timestamp.seconds, "X", "H:mm") }}u</td>
                              <td class="text-right">{{ formatReportNumber(item.amount.number) }}</td>
                              <td>{{ formatReportDate(item.status.timestamp.seconds, "X", "YYYY-MM-DD") }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                      <!-- \ Compensation downpayments / -->

                      <!-- / Open downpayments \ -->
                      <v-card v-if="depositData.total.number > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-cash-register</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.downPayment.openTitle', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="depositsHeader"
                          :items-per-page="itemsPerPage"
                          :items="depositData.prepayments"
                          hide-default-footer
                          dense
                          :key="depositData.prepayments.length"
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.clientName }}</td>
                              <td>
                                <v-chip v-if="item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style">{{ $t('branch.widgetSettings', $store.state.locale) }} </v-chip>
                                <v-chip v-if="!item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downpaymentRequiredEmailShort', $store.state.locale) }} </v-chip>

                              </td>
                              <td>{{ formatReportDate(item.timestamp.seconds, "X", "YYYY-MM-DD") }} om {{ formatReportDate(item.timestamp.seconds, "X", "H:mm") }}u</td>
                              <td class="text-right">{{ formatReportNumber(item.amount.number) }}</td>
                              <td></td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                      <!-- \ Open downpayments / -->

                      

                      <!-- / Developer data downpayments \ -->
                      <v-card v-if="depositData.total.number > 0 && $store.state.activeUserRoleNumber == 0 && !$store.state.demoMode" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-register</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.downPayment.developerTitle', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="depositsHeaderDevelop"
                          :items-per-page="itemsPerPage"
                          :items="depositData.allPrepayments"
                          hide-default-footer
                          dense
                          :key="depositData.allPrepayments.length"
                          class="revenue-overview-table"
                          style="background-color: none!important"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.clientName }}<br>
                                <span class="info-data" @click="copyTextToClipboard(item.clientId , 'clientId')"><i>{{ item.id }} </i>  
                                  <span v-if="copyType =='clientId' && copyValue == item.clientId" style="font-style: italic; font-size: 10px!important">Gekopieërd</span>
                                  <v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon>
                                </span>
                              </td>
                              <td>
                                <v-chip v-if="item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style">{{ $t('branch.widgetSettings', $store.state.locale) }} </v-chip>
                                <v-chip v-if="!item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downpaymentRequiredEmailShort', $store.state.locale) }} </v-chip>
                              </td>
                              <td>{{ formatReportDate(item.timestamp.seconds, "X", "YYYY-MM-DD") }} om {{ formatReportDate(item.timestamp.seconds, "X", "H:mm") }}u</td>
                              <td class="text-right">{{ formatReportNumber(item.amount.number) }}</td>
                              <td>
                                <v-chip v-if="item.paymentStatus == 'paid'" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" light class="mx-1 my-1 custom-chip-style">{{ item.paymentStatus }} </v-chip>
                                <v-chip v-else-if="item.paymentStatus == 'failed'" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" light class="mx-1 my-1 custom-chip-style">{{ item.paymentStatus }} </v-chip>
                                <v-chip v-else-if="item.paymentStatus == 'expired'" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style">{{ item.paymentStatus }} </v-chip>
                                <v-chip v-else small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].text" light class="mx-1 my-1 custom-chip-style">{{ item.paymentStatus }} </v-chip>

                                <v-chip v-if="item.status?.type " small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style">{{ item.status.type  }} </v-chip>
                              </td>
                              <td class="text-left">
                                <span class="info-data" @click="copyTextToClipboard(item.id , 'downPaymentId')"><i>{{ item.id }} </i>  
                                  <span v-if="copyType =='downPaymentId' && copyValue == item.id" style="font-style: italic; font-size: 10px!important">Gekopieërd</span>
                                  <v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon>
                                </span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>


                      <div v-if="depositData.total.number == 0" class="text-center pa-8">
                        <v-avatar
                          size="58"
                          light
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-off</v-icon>
                        </v-avatar>
                        <p class="pa-4">{{$t('reports.downPayment.noPeriodData', $store.state.locale) }} </p>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ Aanbetalingen / -->

                <!-- / Verkoop Behandelingen \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'salesTreatments'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-2" fluid>
                    <v-layout row wrap>
                      <v-flex md12 class="pt-0">
                          <h2 class="pb-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.sales.treatments', $store.state.locale) }}</h2>
                        </v-flex>
                      <v-flex md4 xs12>
                        <period-select :dataType="'treatments'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                      </v-flex>
                      <v-flex md4 xs12>
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="employees"
                          item-text="name"
                          item-value="id"
                          v-model="treatmentsDataFilter.employee"
                          :label="$t(`reports.table.employee`, $store.state.locale)"
                          single-line
                          @input="getTreatmentData()"
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                              <img v-show="data.item.employeePhotoThumb" :src="data.item.employeePhotoThumb">
                              <v-icon v-show="!data.item.employeePhotoThumb">
                                mdi-account
                              </v-icon>
                            </v-avatar>
                            <span>{{ data.item.name }}</span>
                          </template>

                          <template v-slot:item="{item, attrs, on }" class="pa-0 ma-0">
                            <v-list-item v-on="on" v-bind="attrs" style="border-bottom: 1px solid #eee">
                              <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                                <img v-show="item.employeePhotoThumb" :src="item.employeePhotoThumb">
                                <v-icon v-show="!item.employeePhotoThumb">
                                  mdi-account
                                </v-icon>
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.name }} 
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex md4 xs12>
                          <v-select
                            style="width: 100%;"
                            :label="$t('reports.revenue.view', $store.state.locale)"
                            :items="vatViewTypes"
                            :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                            item-value="id"
                            v-model="vatViewType"
                            outlined
                            dense
                            hide-details
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            required
                          ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  
                  <v-layout row wrap>
                    <v-flex md12 v-if="treatmentData.total.number > 0">
                      <v-layout row wrap class="pa-0" style="margin:6px -4px!important">
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-animation</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`indicator.numberGoal`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{ treatmentData.total.number }}</v-list-item-subtitle>
                                </v-list-item-content>
                                
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-chart-bell-curve</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`reports.table.average`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="vatViewType == 'included'"> {{ formatReportNumber(treatmentData.total.totalBrutoRevenue / treatmentData.total.number) }}</v-list-item-subtitle>
                                  <v-list-item-subtitle v-else> {{ formatReportNumber(treatmentData.total.totalNettoRevenue / treatmentData.total.number) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-chart-areaspline-variant</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`indicator.revenue`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="vatViewType == 'included'"> {{ formatReportNumber(treatmentData.total.totalBrutoRevenue) }}</v-list-item-subtitle>
                                  <v-list-item-subtitle v-else> {{ formatReportNumber(treatmentData.total.totalNettoRevenue) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-sale</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`treatments.discount`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="vatViewType == 'included'"> {{ formatReportNumber(treatmentData.total.totalBrutoValue - treatmentData.total.totalBrutoRevenue) }}</v-list-item-subtitle>
                                  <v-list-item-subtitle v-else> {{ formatReportNumber(treatmentData.total.totalNettoValue - treatmentData.total.totalNettoRevenue) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex md12>
                      <v-card v-if="treatmentData.total.number > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-pie</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.treatmentCategoryOverview', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="categoriesHeader"
                          :items-per-page="itemsPerPage"
                          :items="treatmentData.categories"
                          hide-default-footer
                          dense
                          :key="vatViewType + treatmentData.categories.length"
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, getProductTypeName(item.category) )  }}</td>
                              <td class="text-center">{{ item.number }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right"> {{ formatReportNumber(item.totalBrutoRevenue / item.number) }}</td>
                              <td v-else class="text-right"> {{ formatReportNumber(item.totalNettoRevenue / item.number) }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right"> {{ formatReportNumber(item.totalBrutoRevenue) }}</td>
                              <td v-else class="text-right"> {{ formatReportNumber(item.totalNettoRevenue) }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                      <v-card v-if="treatmentData.total.number > 0" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cart-outline</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.treatmentDataOverview', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="treatmentsHeader"
                          :items-per-page="itemsPerPage"
                          :items="treatmentData.treatments"
                          hide-default-footer
                          dense
                          :key="vatViewType + treatmentData.treatments.length"
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, getTreatmentName(item.treatmentId))  }}</td>
                              <td>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, getProductTypeName(item.category) )  }}</td>
                              <td class="text-center">{{ item.number }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right">{{ formatReportNumber(item.totalBrutoRevenue / item.number) }}</td>
                              <td v-else class="text-right">{{ formatReportNumber(item.totalNettoRevenue / item.number) }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right"> {{ formatReportNumber(item.totalBrutoRevenue) }}</td>
                              <td v-else class="text-right"> {{ formatReportNumber(item.totalNettoRevenue) }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                      <div v-if="treatmentData.total.number == 0" class="text-center pa-8">
                        <v-avatar
                          size="58"
                          light
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-areaspline-variant</v-icon>
                        </v-avatar>
                        <p class="pa-4">{{$t('reports.noPeriodDataFoundFor', $store.state.locale) }} {{ employeeName(treatmentsDataFilter.employee) }}</p>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ Verkoop Behandelingen / -->

                <!-- / Verkoop Producten \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'salesProducts'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-2" fluid>
                    <v-layout row wrap>
                      <v-flex md12 class="pt-0">
                          <h2 :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.sales.products', $store.state.locale) }}</h2>
                        </v-flex>
                      <v-flex md4 xs12>
                        <period-select :dataType="'products'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                      </v-flex>
                      <v-flex md4 xs12>
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="employees"
                          item-text="name"
                          item-value="id"
                          v-model="productsDataFilter.employee"
                          :label="$t(`reports.table.employee`, $store.state.locale)"
                          single-line
                          @input="getProductData()"
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                              <img v-show="data.item.employeePhotoThumb" :src="data.item.employeePhotoThumb">
                              <v-icon v-show="!data.item.employeePhotoThumb">
                                mdi-account
                              </v-icon>
                            </v-avatar>
                            <span>{{ data.item.name }}</span>
                          </template>

                          <template v-slot:item="{item, attrs, on }" class="pa-0 ma-0">
                            <v-list-item v-on="on" v-bind="attrs" style="border-bottom: 1px solid #eee">
                              <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                                <img v-show="item.employeePhotoThumb" :src="item.employeePhotoThumb">
                                <v-icon v-show="!item.employeePhotoThumb">
                                  mdi-account
                                </v-icon>
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            
                          </template>
                        </v-select>
                      </v-flex>
                      <v-flex md4 xs12>
                        <v-select
                          style="width: 100%;"
                          :label="$t('reports.revenue.view', $store.state.locale)"
                          :items="vatViewTypes"
                          :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                          item-value="id"
                          v-model="vatViewType"
                          outlined
                          dense
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          required
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-layout row wrap>
                    <v-flex md12 v-if="productData.total.number > 0">
                      <v-layout row wrap class="pa-0" style="margin:6px -4px!important">
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-animation</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`indicator.numberGoal`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle>{{ productData.total.number }}</v-list-item-subtitle>
                                </v-list-item-content>
                                
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-chart-bell-curve</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`reports.average`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="vatViewType == 'included'">{{ formatReportNumber(productData.total.totalBrutoRevenue / productData.total.number) }} </v-list-item-subtitle>
                                  <v-list-item-subtitle v-else> {{ formatReportNumber(productData.total.totalNettoRevenue / productData.total.number) }} </v-list-item-subtitle>
                                </v-list-item-content>
                                
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-chart-areaspline-variant</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`reports.revenue.name`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="vatViewType == 'included'">{{ formatReportNumber(productData.total.totalBrutoRevenue) }}</v-list-item-subtitle>
                                  <v-list-item-subtitle v-else>{{ formatReportNumber(productData.total.totalNettoRevenue) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                        <v-flex md3 xs6>
                          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                            <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                              <v-list-item class="pa-0 pr-4 ma-0">
                                <v-list-item-avatar
                                  size="56"
                                  light
                                  class="ma-4 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-sale</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <h3>{{ $t(`treatments.discount`, $store.state.locale) }}</h3>
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="vatViewType == 'included'">{{ formatReportNumber(productData.total.totalBrutoValue - productData.total.totalBrutoRevenue) }}</v-list-item-subtitle>
                                  <v-list-item-subtitle v-else>{{ formatReportNumber(productData.total.totalNettoValue - productData.total.totalNettoRevenue) }}</v-list-item-subtitle>
                                </v-list-item-content>           
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    
                    <v-flex md12>
                      <v-card v-if="productData.total.number > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-pie</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.productCategoryOverview', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="categoriesHeader"
                          :items-per-page="itemsPerPage"
                          :items="productData.categories"
                          hide-default-footer
                          :key="vatViewType + productData.categories.length"
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ getTreatmentTypeName(item.category) }}</td>
                              <td class="text-center">{{ item.number }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right">{{ formatReportNumber(item.totalBrutoRevenue / item.number) }}</td>
                              <td v-else class="text-right">{{ formatReportNumber(item.totalNettoRevenue / item.number) }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right">{{ formatReportNumber(item.totalBrutoRevenue) }}</td>
                              <td v-else class="text-right">{{ formatReportNumber(item.totalNettoRevenue) }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                      <v-card v-if="productData.total.number > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cart-outline</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.productDataOverview', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          :headers="productsHeader"
                          :items-per-page="itemsPerPage"
                          :items="productData.products"
                          :key="vatViewType + productData.products.length"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ getProductName(item.productId) }}</td>
                              <td>{{ getTreatmentTypeName(item.category) }}</td>
                              <td class="text-center">{{ item.number }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right">{{ formatReportNumber(item.totalBrutoRevenue / item.number) }}</td>
                              <td v-else class="text-right"> {{ formatReportNumber(item.totalNettoRevenue / item.number) }}</td>
                              <td v-if="vatViewType == 'included'" class="text-right"> {{ formatReportNumber(item.totalBrutoRevenue) }}</td>
                              <td v-else class="text-right"> {{ formatReportNumber(item.totalNettoRevenue) }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                      <div v-if="productData.total.number == 0" class="text-center pa-8">
                        <v-avatar
                          size="58"
                          light
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-areaspline-variant</v-icon>
                        </v-avatar>
                        <p class="pa-4">{{$t('reports.noPeriodDataFoundFor', $store.state.locale) }} {{ employeeName(treatmentsDataFilter.employee) }}</p>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ Verkoop Producten / -->

                
                <!--/  KASSTAAT \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'registerStatus'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-2" fluid>
                    <v-layout row wrap >
                      <v-flex md8 s12 class="pt-0">
                        <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.register.registerStatus', $store.state.locale) }}</h2>
                      </v-flex>
                      <v-flex md4 xs12 class="pt-0">
                        <period-select :dataType="'register'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                      </v-flex>
                      
                    </v-layout>
                  </v-container>
                  
                  <!-- / KASSTAAT OMZET \ -->
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-areaspline-variant</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.revenue', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        
                        <v-data-table
                          v-if="registerData.revenue && registerData.revenue.length > 0"
                          :headers="registerRevenueHeader"
                          :items-per-page="itemsPerPage"
                          :items="registerData.revenue"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td v-for="(data, index) in registerRevenueHeader" :key="index">
                                <span v-if="data.value == 'day'">{{ item[data.value].value | moment("D-M-YYYY")}}</span>
                                <div class="text-right" v-else>
                                  <div class="ma-0" v-if="!item[data.value] || item[data.value] == 0">-</div> 
                                  <div class="ma-0" v-else> {{ formatReportNumber(item[data.value]) }}</div>
                                </div>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td v-for="(data, index) in registerRevenueHeader" :key="index">
                                <span v-if="data.value == 'day'"><strong>{{ $t('general.total', $store.state.locale) }}</strong></span>
                                <div class="text-right" v-else> 
                                  <strong>{{ getRegisterRevenueTotal(data.value) }}</strong>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <!-- \ KASSTAAT OMZET / -->

                  <!-- / KASSTAAT KASOPMAAK \ -->
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-register</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{  $t('reports.register.cashUp', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="registerData.cashUp.data && registerData.cashUp.data.length > 0"
                          :headers="registerRevenueCashUpHeader"
                          :items-per-page="itemsPerPage"
                          :items="registerData.cashUp.data"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>
                                {{ item.day | moment("D-M-YYYY")}}
                              </td>
                              <td class="text-right" v-if="item.pin && item.pin > 0"> {{ formatReportNumber(item.pin) }}</td>
                              <td class="text-right" v-else>-</td>    

                              <td class="text-right" v-if="item.creditcard && item.creditcard > 0">{{ formatReportNumber(item.creditcard) }}</td> 
                              <td class="text-right" v-else>-</td>


                              <td class="text-right" v-if="item.cashUpDepositToBank && item.cashUpDepositToBank > 0"> {{ formatReportNumber(item.cashUpDepositToBank) }}</td> 
                              <td class="text-right" v-else>-</td>  
                              
                              <td class="text-right" v-if="(item.pin + item.creditcard + item.cashUpDepositToBank > 0)"> {{ formatReportNumber(item.pin + item.creditcard + item.cashUpDepositToBank) }}</td> 
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td v-if="registerData.cashUp.totalPin > 0" class="text-right"><strong> {{ formatReportNumber(registerData.cashUp.totalPin) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>
                              <td v-if="registerData.cashUp.totalCreditcard > 0" class="text-right"><strong> {{ formatReportNumber(registerData.cashUp.totalCreditcard) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>
                              <td v-if="registerData.cashUp.totalDeposit > 0" class="text-right"><strong> {{ formatReportNumber(registerData.cashUp.totalDeposit) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>
                              <td v-if="registerData.cashUp.totalToBank > 0" class="text-right"><strong>{{ formatReportNumber(registerData.cashUp.totalToBank) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <!-- \ KASSTAAT KASOPMAAK / -->

                  <!-- / KASSTAAT KASVERSCHIL \ -->
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-scale-unbalanced</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{  $t('reports.register.deficit', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="registerData.deficit.data && registerData.deficit.data.length > 0"
                          :headers="registerRevenueDeficitHeader"
                          :items-per-page="itemsPerPage"
                          :items="registerData.deficit.data"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.day | moment("D-M-YYYY")}}</td>

                              <td class="text-right" v-if="!item.cashRounding || item.cashRounding == 0">-</td>
                              <td class="text-right" v-else>{{ formatReportNumber(item.cashRounding) }}</td>  

                              <td class="text-right" v-if="item.surplus == 0 && item.deficit == 0">-</td>
                              <td class="text-right" v-else-if="item.surplus > 0"> {{ formatReportNumber(item.surplus) }}</td>    
                              <td class="text-right" v-else> {{ formatReportNumber(item.deficit) }}</td>

                              <td class="text-right" v-if="item.surplus + item.cashRounding <= 0">-</td>
                              <td class="text-right" v-else> {{ formatReportNumber(item.surplus+item.cashRounding) }}</td>   

                              <td class="text-right" v-if="item.deficit + item.cashRounding >= 0">-</td>
                              <td class="text-right" v-else>{{ formatReportNumber(item.deficit+item.cashRounding) }}</td>       
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>

                              <td v-if="registerData.deficit.totalRounded > 0" class="text-right"><strong> {{ formatReportNumber(registerData.deficit.totalRounded) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>

                              <td v-if="registerData.deficit.totalCashUp > 0"  class="text-right"><strong> {{ formatReportNumber(registerData.deficit.totalCashUp) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>

                              <td v-if="registerData.deficit.totalSurplus > 0"  class="text-right"><strong> {{ formatReportNumber(registerData.deficit.totalSurplus) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>

                              <td v-if="registerData.deficit.totalDeficit > 0"  class="text-right"><strong> {{ formatReportNumber(registerData.deficit.totalDeficit) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noCashDificitFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <!-- \ KASSTAAT KASVERSCHIL / -->

                  <!-- / KASSTAAT VERLOOP KASINHOUD \ -->
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-repeat</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t(`reports.registerCashMovement`, $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="registerData.register.data && registerData.register.data.length > 0"
                          :headers="registerContentHeader"
                          :items-per-page="itemsPerPage"
                          :items="registerData.register.data"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.day | moment("D-M-YYYY")}}</td>
                              <td class="text-right">{{ formatReportNumber(item.startCashUpSum) }}</td>
                              <td class="text-right">{{ formatReportNumber(item.endCashUpSum) }}</td> 
                              <td class="text-right" v-if="item.endCashUpSum - item.startCashUpSum < 0">{{ formatReportNumber(Math.abs(item.endCashUpSum - item.startCashUpSum)) }}</td>
                              <td class="text-right" v-else>-</td>
                              <td class="text-right" v-if="item.endCashUpSum  - item.startCashUpSum >= 0">{{ formatReportNumber(item.endCashUpSum - item.startCashUpSum ) }}</td>
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td></td>
                              <td></td>
                              <td v-if="registerData.register.totalSurplus" class="text-right"><strong>{{ formatReportNumber(Math.abs(registerData.register.totalSurplus)) }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>
                              <td v-if="registerData.register.totalDeficit" class="text-right"><strong>{{ formatReportNumber(registerData.register.totalDeficit)  }}</strong></td>
                              <td v-else class="text-right"><strong>-</strong></td>   
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noCashDificitFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <!-- \ KASSTAAT VERLOOP KASINHOUD / -->

                  <!-- / KASSTAAT UITGAVEN  \ -->
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-minus</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.register.expenses', $store.state.locale) }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="registerData.expenses && registerData.expenses.length > 0"
                          :headers="registerRevenueExpensesHeader"
                          :items-per-page="itemsPerPage"
                          :items="registerData.expenses"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>
                                {{ item.day | moment("D-M-YYYY")}}
                              </td>
                              <td>
                                  <!-- {{ item.resource }} -->
                                  {{ userName(item.resource) }} 
                              </td>
                              <td>
                                {{ item.typeExpenses }}
                              </td>
                              <td>
                                {{ item.expenseName }}
                              </td>
                              <td>
                                <span v-if="!item.expense || item.expense == 0">-</span> 
                                <span v-else>{{ formatReportNumber(item.expense) }}</span>     
                              </td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <strong>{{ getRegisterRevenueExpensesTotal() }}</strong>   
                              </td>
                              
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noExpencesFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <!-- \ KASSTAAT UITGAVEN  / -->

                </v-container>
                <!-- \ KASSTAAT / -->

                <!-- / KASOPMAAK \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'cashUp'">
                  <v-layout row wrap class="pa-0 pt-0 pb-1" >
                    <v-flex md6 class="pt-0">
                      <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.register.cashUp', $store.state.locale) }}</h2>
                    </v-flex>
                    <v-flex md6 class="pt-0">
                      <div style="display: inline-block; float: right">
                        <v-container grid-list-md class="pa-0 ma-0 ">
                          <v-layout row wrap>
                            <v-flex xs12>
                              <div class="text-left">
                                <v-btn icon @click.native="cashUpPrev()">
                                  <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-left</v-icon>
                                </v-btn>
                                <span style="font-size: 16px"> {{ cashUpDataFilter.day | moment("dddd D MMMM YYYY")}}</span>
                                <v-menu ref="cashUpCalendar" :close-on-content-click="true" v-model="cashUpCalendar" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-calendar</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-date-picker v-model="cashUpDataFilter.day" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  scrollable :first-day-of-week="1" :max="today" v-on:input="getCashUpData"></v-date-picker>
                                </v-menu>
                                <v-btn icon @click.native="cashUpNext()" :disabled="today == cashUpDataFilter.day">
                                  <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-chevron-right</v-icon>
                                </v-btn>
                              </div>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </div>
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex md12>
                      <div v-if="cashUpData && cashUpData.length > 0">
                        <div v-for="(cashUp, index) in cashUpData" :key="index">
                          <v-layout row wrap class="pa-0" style="margin:6px -4px!important">
                            <v-flex md4 xs6>
                              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="56"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                      >
                                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <h3>{{ $t(`reports.registerResource`, $store.state.locale) }}</h3>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>{{ userName(cashUp.byResource) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-flex>
                            <v-flex md4 xs6>
                              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="56"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].secondary}" 
                                      >
                                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-clock</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <h3>{{ $t(`reports.registerTime`, $store.state.locale) }}</h3>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>{{ cashUp.created.value | moment("H:mm") }}u </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-flex>
                            <v-flex md4 xs6>
                              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                                <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                  <v-list-item class="pa-0 pr-4 ma-0">
                                    <v-list-item-avatar
                                      size="56"
                                      light
                                      class="ma-4 custom-avatar-style" 
                                      rounded="lg"
                                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                                      >
                                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-scale-unbalanced</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <h3>{{ $t(`reports.registerDifference`, $store.state.locale) }}</h3>
                                      </v-list-item-title>
                                      <v-list-item-subtitle>{{ formatReportNumber(cashUp.totalDifference) }}</v-list-item-subtitle>
                                    </v-list-item-content>           
                                  </v-list-item>
                                </v-list>
                              </v-card>
                            </v-flex>
                          </v-layout>
                          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5 mt-3" :class="$store.state.companyTheme">
                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-avatar
                                  size="36"
                                  light
                                  style="float: left"
                                  class="ma-4 mb-2 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-fast</v-icon>
                                </v-avatar>
                                <h3 class="pa-4 mt-1 pb-0">{{ $t(`reports.registerIncome`, $store.state.locale) }}</h3>
                              </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-data-table
                              v-if="cashUp.income && cashUp.income.length > 0"
                              :headers="cashUpIncomeHeader"
                              :items-per-page="itemsPerPage"
                              :items="cashUp.income"
                              hide-default-footer
                              dense
                              class="revenue-overview-table"
                              :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                              >
                              <template v-slot:item="{item}">
                                <tr>
                                  <td>{{ $t(`reports.cashUp.${item.type }`, $store.state.locale) }}</td>
                                  <td class="text-right" v-if="item.start">{{ formatReportNumber(item.start) }}</td><td class="text-right" v-else>-</td>
                                  <td class="text-right" v-if="item.counted && item.counted != 0"> {{ formatReportNumber(item.counted) }}</td><td class="text-right" v-else>-</td>
                                  <td class="text-right" v-if="item.income && item.income != 0"> {{ formatReportNumber(item.income) }}</td><td class="text-right" v-else>-</td>
                                  <td class="text-right" v-if="item.expected && item.expected != 0"> {{ formatReportNumber(item.expected) }}</td><td class="text-right" v-else>-</td>
                                  <td class="text-right" v-if="item.difference && item.difference != 0"> {{ formatReportNumber(item.difference) }}</td><td class="text-right" v-else>-</td>
                                </tr>
                              </template>
                               <template v-slot:body.append>
                                <tr></tr>
                              </template>
                              <template v-slot:foot>
                                <tr>
                                  <td><strong>{{ $t(`reports.cashUp.total`, $store.state.locale) }}</strong></td>
                                  <td class="text-right"><strong> {{ formatReportNumber(cashUp.totalStart) }}</strong></td>
                                  <td class="text-right"><strong> {{ formatReportNumber(cashUp.totalCounted) }}</strong></td>
                                  <td class="text-right"><strong> {{ formatReportNumber(cashUp.totalIncome) }}</strong></td>
                                  <td class="text-right"><strong> {{ formatReportNumber(cashUp.totalExpected) }}</strong></td>
                                  <td class="text-right"><strong> {{ formatReportNumber(cashUp.totalDifference) }}</strong></td>
                                </tr>
                              </template>
                            </v-data-table>
                            <div v-else class="pa-4 text-center">
                              {{ $t('reports.noDataFound', $store.state.locale) }}
                            </div>
                          </v-card> 

                          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                            <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-avatar
                                  size="36"
                                  light
                                  style="float: left"
                                  class="ma-4 mb-2 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-register</v-icon>
                                </v-avatar>
                                <h3 class="pa-4 mt-1 pb-0">{{ $t(`reports.registerContents`, $store.state.locale) }} </h3>
                              </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-data-table
                              v-if="cashUp.register && cashUp.register.length > 0"
                              :headers="cashUpRegisterHeader"
                              :items-per-page="itemsPerPage"
                              :items="cashUp.register"
                              hide-default-footer
                              dense
                              class="revenue-overview-table"
                              :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                              >
                              <template v-slot:item="{item}">
                                <tr>
                                  <td>{{ $t(`reports.cashUp.${item.type }`, $store.state.locale) }}</td>
                                  <td class="text-right" v-if="item.value"> {{ formatReportNumber(item.value) }}</td>
                                  <td class="text-right" v-else>-</td>
                                  <td class="text-right"> {{ formatReportNumber(item.total) }}</td>
                                </tr>
                              </template>
                            </v-data-table>
                            <div v-else class="pa-4 text-center">
                              {{ $t('reports.noDataFound', $store.state.locale) }}
                            </div>
                          </v-card>

                          <v-card  :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                            <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                            <v-layout row wrap>
                              <v-flex xs12>
                                <v-avatar
                                  size="36"
                                  light
                                  style="float: left"
                                  class="ma-4 mb-2 custom-avatar-style" 
                                  rounded="lg"
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                  >
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash</v-icon>
                                </v-avatar>
                                <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.countedCurrency', $store.state.locale) }}</h3>
                              </v-flex>
                            </v-layout>
                            <v-divider></v-divider> 
                            <v-data-table
                              v-if="cashUp.cash && cashUp.cash.length > 0"
                              :headers="cashUpCashHeader"
                              :items-per-page="itemsPerPage"
                              :items="cashUp.cash"
                              hide-default-footer
                              dense
                              :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                              class="revenue-overview-table"
                              >
                              <template v-slot:item="{item}">
                                <tr>
                                  <td>{{ $t(`reports.cashUp.${item.type }`, $store.state.locale) }}</td>
                                  <td class="text-center">{{ item.number }}</td>
                                  <td class="text-right" v-if="item.value"> {{ formatReportNumber(item.value) }}</td>
                                  <td class="text-right" v-else></td>
                                  <td class="text-right"> {{ formatReportNumber(item.total) }}</td>
                                </tr>
                              </template>
                              <template v-slot:body.append>
                                <tr></tr>
                              </template>
                              <template v-slot:foot>
                                <tr>
                                  <td><strong>{{ $t(`reports.cashUp.total`, $store.state.locale) }}</strong></td>
                                  <td></td>
                                  <td></td>
                                  <td class="text-right"><strong>  {{ formatReportNumber(cashUp.cashUpSumTotal) }}</strong></td>
                                </tr>
                              </template>
                            </v-data-table>
                            <div v-else class="pa-4 text-center">
                              {{ $t('reports.noDataFound', $store.state.locale) }}
                            </div>
                          </v-card>
                          <!-- <p class="pa-0"><strong>{{ $t('reports.cashUp.registerAt', $store.state.locale) }} {{ cashUp.created.value | moment("H:mm") }}u {{ $t('reports.cashUp.registerUpBy', $store.state.locale) }} {{ cashUp.byResourceName }}</strong></p>  -->
                        </div>
                      </div>
                      
                      <div v-else class="text-center" style="padding: 150px;">
                          <v-avatar 
                            size="76"
                            light
                            class="ma-4 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                            >
                            <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cash-register</v-icon>
                          </v-avatar>
                          
                          <!-- <div style="font-size: 18px;">{{ $t('calendar.noEmployees', $store.state.locale) }}</div> -->
                          <div style="font-size: 18px;">{{ $t('reports.cashUp.noCashUp', $store.state.locale) }}</div>
                        </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ KASOPMAAK / -->

                <!-- / BONNEN Aantallen \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'receiptNumber'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-4" fluid>
                    <v-layout row wrap>
                      <v-flex md4 xs12 class="pt-0">
                        <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.receipts.receipts', $store.state.locale) }}</h2>
                      </v-flex>
                      <v-flex md4 xs12 class="pt-0">
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="branchYears"
                          item-text="id"
                          item-value="id"
                          v-model="receiptDataFilter.year"
                          label="Periode"
                          single-line
                          @input="getReceiptNumbersData()"
                          >
                        </v-select>
                      </v-flex>
                      <v-flex md4 xs12 class="pt-0">
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="users"
                          item-text="name"
                          item-value="id"
                          v-model="receiptDataFilter.user"
                          :label="$t(`reports.table.employee`, $store.state.locale)"
                          single-line
                          @input="getReceiptNumbersData()"
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <v-avatar color="#fff" size="28px" class="mr-2">
                              <v-icon>
                                mdi-account
                              </v-icon>
                            </v-avatar>
                            <span>{{ data.item.firstName }} {{ data.item.lastName }}</span>
                          </template>

                          <template v-slot:item="{item, attrs, on }" class="pa-0 ma-0">
                            <v-list-item v-on="on" v-bind="attrs" style="border-bottom: 1px solid #eee">
                              <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                                <v-icon>
                                  mdi-account
                                </v-icon>
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.firstName }} {{ item.lastName }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            
                          </template>
                        </v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-grid-large</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.quarter', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptData.quarter && receiptData.quarter.length > 0"
                          :headers="receiptsDataQuarterHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptData.quarter"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td v-if="item.quarter != 'Total'">{{ $t('general.quarter', $store.state.locale) }} {{ item.quarter }}</td>
                              <td :style="{ fontWeight: item.quarter == 'Total' ? 600 : 'normal' }" v-else>{{ $t('general.total', $store.state.locale)  }}</td>

                              <td :style="{ fontWeight: item.quarter == 'Total' ? 600 : 'normal' }" v-if="item.with_treatment"> {{ item.with_treatment }}</td>
                              <td :style="{ fontWeight: item.quarter == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td :style="{ fontWeight: item.quarter == 'Total' ? 600 : 'normal' }" v-if="item.without_treatment"> {{ item.without_treatment }}</td>
                              <td :style="{ fontWeight: item.quarter == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td :style="{ fontWeight: item.quarter == 'Total' ? 600 : 'normal' }"> {{ item.total }} </td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>

                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-month</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.month', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptData.month && receiptData.month.length > 0"
                          :headers="receiptsDataMonthHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptData.month"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td v-if="item.month != 'Total'">{{ item.month | moment("MMMM")}}</td>
                              <td :style="{ fontWeight: item.month == 'Total' ? 600 : 'normal' }" v-else>{{ $t('general.total', $store.state.locale)  }}</td>

                              <td :style="{ fontWeight: item.month == 'Total' ? 600 : 'normal' }" v-if="item.with_treatment"> {{ item.with_treatment }}</td>
                              <td :style="{ fontWeight: item.month == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td :style="{ fontWeight: item.month == 'Total' ? 600 : 'normal' }" v-if="item.without_treatment"> {{ item.without_treatment }}</td>
                              <td :style="{ fontWeight: item.month == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td :style="{ fontWeight: item.month == 'Total' ? 600 : 'normal' }">{{ item.total }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
   
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-expand-horizontal</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.fourWeeks', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptData.month && receiptData.month.length > 0"
                          :headers="receiptsDataFourWeeksHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptData.fourWeeks"
                          hide-default-footer
                          dense
                          :sort-by="['fourWeeks']"
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td v-if="item.fourWeeks != 'Total'"> {{ $t('general.week', $store.state.locale) }} {{ item.fourWeeks * 4 +1 }} - {{ (item.fourWeeks * 4) + 4}}</td>
                              <td v-else :style="{ fontWeight: item.fourWeeks == 'Total' ? 600 : 'normal' }">{{ $t('general.total', $store.state.locale)  }}</td>

                              <td v-if="item.with_treatment" :style="{ fontWeight: item.fourWeeks == 'Total' ? 600 : 'normal' }"> {{ item.with_treatment }}</td>
                              <td :style="{ fontWeight: item.fourWeeks == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td v-if="item.without_treatment" :style="{ fontWeight: item.fourWeeks == 'Total' ? 600 : 'normal' }"> {{ item.without_treatment }}</td>
                              <td :style="{ fontWeight: item.fourWeeks == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td :style="{ fontWeight: item.fourWeeks == 'Total' ? 600 : 'normal' }"> {{ item.total }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>

                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-week</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.week', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptData.week && receiptData.week.length > 0"
                          :headers="receiptsDataWeekHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptData.week"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td v-if="item.week != 'Total' ">{{ $t('general.week', $store.state.locale) }} {{ item.week }}</td>
                              <td :style="{ fontWeight: item.week == 'Total' ? 600 : 'normal' }" v-else>{{ $t('general.total', $store.state.locale)  }}</td>
                              <td :style="{ fontWeight: item.week == 'Total' ? 600 : 'normal' }" v-if="item.with_treatment"> {{ item.with_treatment }}</td>
                              <td :style="{ fontWeight: item.week == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td :style="{ fontWeight: item.week == 'Total' ? 600 : 'normal' }" v-if="item.without_treatment"> {{ item.without_treatment }}</td>
                              <td :style="{ fontWeight: item.week == 'Total' ? 600 : 'normal' }" v-else>-</td>

                              <td :style="{ fontWeight: item.week == 'Total' ? 600 : 'normal' }">{{ item.total }}</td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ BONNEN Aantallen / -->

                 <!-- / BONNEN Afspraken \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'receiptAppointment'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-4" fluid>
                    <v-layout row wrap>
                      <v-flex md4 xs12 class="pt-0">
                        <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.receipts.receiptsAndAppointments', $store.state.locale) }}</h2>
                      </v-flex>
                      <v-flex md4 xs12 class="pt-0">
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="branchYears"
                          item-text="id"
                          item-value="id"
                          v-model="receiptAppointmentDataFilter.year"
                          label="Periode"
                          single-line
                          @input="getReceiptAppointmentData()"
                          >
                        </v-select>
                      </v-flex>
                      <v-flex md4 xs12 class="pt-0">
                        <v-select
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="menu"
                          hide-details
                          :items="employees"
                          item-text="name"
                          item-value="id"
                          return-object
                          v-model="receiptAppointmentDataFilter.user"
                          :label="$t(`reports.table.employee`, $store.state.locale)"
                          single-line
                          @input="getReceiptAppointmentData()"
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <v-avatar color="#fff" size="28px" class="mr-2">
                              <v-icon>
                                mdi-account
                              </v-icon>
                            </v-avatar>
                            <span>{{ data.item.name }}</span>
                          </template>

                          <template v-slot:item="{item, attrs, on }" class="pa-0 ma-0">
                            <v-list-item v-on="on" v-bind="attrs" style="border-bottom: 1px solid #eee">
                              <v-avatar color="#f9f9f9" size="28px" class="mr-2">
                                <v-icon>
                                  mdi-account
                                </v-icon>
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            
                          </template>
                        </v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>

                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-grid-large</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.quarter', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptAppointmentData.quarter && receiptAppointmentData.quarter.length > 0"
                          :headers="receiptAppointmentDataQuarterHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptAppointmentData.quarter"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ $t('general.quarter', $store.state.locale) }} {{ item.quarter }}</td>
                              <td>{{ item.treatments }}</td>
                              <td>{{ item.appointments }}</td>
                              <td>{{ item.payed.toFixed(0) }}</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('quarter','treatments') }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('quarter','appointments') }}</strong></td>
                              <td></td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
     
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-month</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.month', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptAppointmentData.month && receiptAppointmentData.month.length > 0"
                          :headers="receiptAppointmentDataMonthHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptAppointmentData.month"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ String(item.month) | moment("MMMM")}}</td>
                              <td>{{ item.treatments }}</td>
                              <td>{{ item.appointments }}</td>
                              <td>{{ (item.payed).toFixed(0) }}% </td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('month','treatments') }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('month','appointments') }}</strong></td>
                              <td></td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>

                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-expand-horizontal</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.fourWeeks', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptAppointmentData.month && receiptAppointmentData.month.length > 0"
                          :headers="receiptAppointmentDataFourWeeksHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptAppointmentData.fourWeeks"
                          hide-default-footer
                          dense
                          :sort-by="['fourWeeks']"
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td> {{ $t('general.week', $store.state.locale) }} {{ item.fourWeeks * 4 +1 }} - {{ (item.fourWeeks * 4) + 4}}</td>
                              <td>{{ item.treatments }}</td>
                              <td>{{ item.appointments }}</td>
                              <td>{{ item.payed.toFixed(0) }}%</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('fourWeeks','treatments') }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('fourWeeks','appointments') }}</strong></td>
                              <td></td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>

                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-week</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('general.week', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="receiptAppointmentData.week && receiptAppointmentData.week.length > 0"
                          :headers="receiptAppointmentDataWeekHeader"
                          :items-per-page="itemsPerPage"
                          :items="receiptAppointmentData.week"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ $t('general.week', $store.state.locale) }} {{ item.week }}</td>
                              <td>{{ item.treatments }}</td>
                              <td>{{ item.appointments }}</td>
                              <td>{{ item.payed.toFixed(0) }}%</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('week','treatments') }}</strong></td>
                              <td><strong>{{ getReceiptAppointmentTotal('week','appointments') }}</strong></td>
                              <td></td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  
                  
                 
                </v-container>
                <!-- \ BONNEN Afspraken / -->

                <!-- / BONNEN Lijst \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'receiptList'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-2" fluid>
                    <v-layout row wrap >
                      <v-flex md8 s12 class="pt-0">
                        <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.receipts.receipts', $store.state.locale) }}</h2>
                      </v-flex>
                      <v-flex md4 xs12 class="pt-0">
                        <period-select :limit="['week']" :dataType="'receiptList'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div v-if="getReceiptListItems() > 0">
                    <div v-for="(day, key) in receiptListData" :key="key" >
                      <v-card v-if="day.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-data-table
                          :headers="receiptDataHeader"
                          :items-per-page="itemsPerPage"
                          :items="day"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.created }}u</td>
                              <td>{{ item.clientId }}</td>
                              <td>{{ getEmployeeData(item.userId).name }}</td>
                              <td>{{ item.payedBy }}</td>
                              <td class="text-right">{{ formatReportNumber(item.price) }}</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td class="text-right"><strong>{{ formatReportNumber(getReceiptListTotal(key, 'price')) }}</strong></td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </div>
                  </div>
                  <div class="no-data-message text-center" v-else>
                    <v-avatar
                      size="58"
                      light
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-receipt-text</v-icon>
                    </v-avatar>
                    <p>{{ $t('payment.noReceiptsFound', $store.state.locale) }}</p>
                  </div>
                </v-container>
                <!-- \ BONNEN Lijst / -->

                <!-- / BONNEN Openstaand \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'receiptOpen'">
                  <v-container grid-list-md class="ma-0 pa-0 pt-0 pb-2" fluid>
                    <v-layout row wrap >
                      <v-flex md8 s12 class="pt-0">
                        <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('payment.openReceipts', $store.state.locale) }}</h2>
                      </v-flex>
                      <v-flex md4 xs12 class="pt-0">
                        <period-select :dataType="'registerListOpen'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-layout row wrap>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme"  v-if="openReceiptData && openReceiptData.length > 0">
                        <v-data-table
                          :headers="openReceiptDataHeader"
                          :items-per-page="itemsPerPage"
                          :items="openReceiptData"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.modified }}u</td>
                              <td>{{ item.clientname }}</td>
                              <td class="text-right">{{ formatReportNumber(item.price) }}</td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td><strong>{{ $t('general.total', $store.state.locale) }}</strong></td>
                              <td></td>
                              <td class="text-right"><strong>{{ formatReportNumber(getOpenReceiptTotal('price')) }}</strong></td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                      <div class="no-data-message text-center" v-else>
                        <v-avatar
                          size="58"
                          light
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-receipt-text</v-icon>
                        </v-avatar>
                        <p>{{ $t('payment.noOpenReceiptsFound', $store.state.locale) }}</p>
                      </div>

                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ BONNEN Openstaand / -->

                <!-- / KLANTEN besteding \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'branchRevenue'">
                  <v-layout row wrap >
                    <v-flex md12>
                      <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.clients.dataLastYear', $store.state.locale) }}</h2>
                      <!-- <h2 class="pa-2">{{ $t('reports.clients.dataLastYear', $store.state.locale) }}</h2>  -->
                    </v-flex>

                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                       <h3 class="pa-2">{{ $t('reports.clients.averageRevenue', $store.state.locale) }}</h3>    
                        <apexchart width="100%" height="280" type="bar" :options="branchChartOptions"  :series="branchRevenueAverageData"></apexchart>
                      </v-card>

                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                        <h3 class="pa-2">{{ $t('reports.clients.totalRevenue', $store.state.locale) }}</h3> 
                        <apexchart width="100%" height="280" type="bar" :options="branchChartOptions"  :series="branchRevenueData"></apexchart>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap >
                    <v-flex md4>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                       <h3 class="pa-2 pb-0">{{ $t('reports.clients.totalRevenue', $store.state.locale) }}</h3> 
                       <h2 class="px-2"> {{ formatReportNumber(totalBranchRevenue) }}</h2> 
                        <apexchart width="100%" height="280" type="pie" :options="branchPieChartOptions"  :series="branchVisitorsData"></apexchart>
                      </v-card>
                    </v-flex>

                    <v-flex md4>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme">
                       <h3 class="pa-2 pb-0">{{ $t('reports.clients.totalVisits', $store.state.locale) }}</h3> 
                       <h2 class="px-2">{{ totalBranchVisits }}</h2> 
                        <apexchart width="100%" height="280" type="pie" :options="branchPieChartOptions"  :series="branchVisitorsNumber"></apexchart>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
                <!-- \ KLANTEN besteding / -->
                                 
                <!-- / KLANTEN beste klanten \ -->
                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'branchClients'">
                  <v-layout row wrap class="pa-0 pb-1" >
                    <v-flex md8>
                      <h2 class="pt-1" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}">{{ $t('reports.clients.bestClients', $store.state.locale) }}</h2>
                    </v-flex>
                     <v-flex md4 xs12>
                      <period-select :dataType="'branchClients'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                    </v-flex>
                  </v-layout>
                            
                  <v-layout row wrap v-if="clientData && clientData.length > 0">
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-pie</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.clients.bestsClientsTotalRevenue', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="clientData && clientData.length > 0"
                          :headers="clientDataTotalHeader"
                          :items-per-page="itemsPerPage"
                          :items="clientData"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td class="text-left">{{ item.clientName }}</td>
                              <td class="text-right">{{ item.visits }}</td>
                              <td class="text-right" v-if="item.totalRevenue">{{ formatReportNumber(item.totalRevenue) }}</td>
                              <td class="text-right" v-else>
                                -
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>

                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <v-layout row wrap>
                          <v-flex xs12>
                            <v-avatar
                              size="36"
                              light
                              style="float: left"
                              class="ma-4 mb-2 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-bell-curve</v-icon>
                            </v-avatar>
                            <h3 class="pa-4 mt-1 pb-0">{{ $t('reports.clients.bestsClientsAverageRevenue', $store.state.locale)  }}</h3>
                          </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                        <v-data-table
                          v-if="clientData && clientData.length > 0"
                          :headers="clientDataAverageHeader"
                          :items-per-page="itemsPerPage"
                          :sort-by="['averageRevenue']"
                          :sort-desc="[true]"
                          :items="clientData"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr>
                              <td>{{ item.clientName }}</td>
                              <td class="text-right">{{ item.visits }}</td>
                              <td class="text-right" v-if="item.averageRevenue">{{ formatReportNumber(item.averageRevenue) }}</td>
                              <td class="text-right" v-else>-</td>
                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>

                  <div class="no-data-message text-center" v-else>
                    <v-avatar
                      size="58"
                      light
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-pie</v-icon>
                    </v-avatar>
                    <p>{{ $t('reports.noDataFound', $store.state.locale) }}</p>
                  </div>
                  
                 
                </v-container>
                <!-- \ KLANTEN beste klanten / -->

                <v-container grid-list-md class="ma-0 pa-0" v-show="activeFilter == 'employeeSicknessLeave'">
                    <v-container grid-list-md class="ma-0 pa-0 pt-2 pb-4" fluid>
                    <v-layout row wrap>
                      <v-flex md4 xs12>
                        <period-select :dataType="'employeeSicknessLeave'" @updateDataFilter="updateDataPeriodRefresh($event)"></period-select>
                      </v-flex>
                    </v-layout>
                    </v-container>
                  
                  <v-layout row wrap>
                    <v-flex md12>
                      <h2>{{ $t('reports.employees.sicknessLeave', $store.state.locale) }}</h2>
                    </v-flex>
                    <v-flex md12>
                      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mb-5" :class="$store.state.companyTheme" >
                        <!-- <h3 class="pa-2">{{ $t('general.revenue', $store.state.locale) }}</h3> -->
                        <v-data-table
                          v-if="employeeSicknessLeaves && employeeSicknessLeaves.length > 0"
                          :headers="employeeSicknessLeaveHeader"
                          :items-per-page="itemsPerPage"
                          :items="employeeSicknessLeaves"
                          hide-default-footer
                          dense
                          class="revenue-overview-table"
                          :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                          >
                          <template v-slot:item="{item}">
                            <tr v-for="(event, index) in item.events" :key="index + item.resourceId">
                              <td>
                                <span style="font-size: 12px;" v-if="index == 0 && getEmployeeData(item.resourceId)">{{ getEmployeeData(item.resourceId).name }}</span>
                              </td>
                              <td>
                               {{ index + 1}}
                              </td>
                              <td>
                                {{ event.startdate}}
                              </td>
                              <td>
                                {{ event.enddate}}
                              </td>
                              <td class="text-right">
                                <span style="font-size: 12px;" v-if="event.hours">{{ event.hours}}</span>
                                <span v-else>-</span>
                              </td>

                            </tr>
                          </template>
                        </v-data-table>
                        <div v-else class="pa-4 text-center">
                          {{ $t('reports.noDataFound', $store.state.locale) }}
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>

            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>

    <!-- / New Barometer Dialog \ -->
    <add-barometer  @updateData="newIndicatorAdded" @updateDataEdit="indicatorEditted"></add-barometer>
    <!-- \ New Barometer Dialog / -->

    <!-- / PDF Print Iframe Dialog \ -->
    <v-dialog :key="pdfPrintIframe.changes" v-model="pdfPrintIframe.dialog" width="800" :content-class="`custom-dialog ${$store.state.companyTheme}`" @click:outside="closePdfIframeDialog()">
      <v-card :key="pdfPrintIframe.changes">

        <!-- <iframe v-show="!pdfPrintIframe.loading" :src="pdfPrintIframe.src" id="pdf-print-iframe" style="width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;" ></iframe> -->
        <VueHtml2pdf
        v-show="!pdfPrintIframe.loading"
            :show-layout="true"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="myPDF"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
            <section slot="pdf-content">

              DIKKE VETTE PDF
              <div v-html="pdfPrintIframe.html"></div>
                <!-- PDF Content Here -->
            </section>
        </VueHtml2pdf>


        {{ pdfPrintIframe.changes}}
      </v-card>
    </v-dialog>
    <!-- \ PDF Print Iframe Dialog / -->

  </div>
</template>
    
<script>
import '@firebase/firestore';
import db from "../firebase/init";
import firebase from "firebase/app";
import _ from "lodash";
import { bus } from "../main.js";
import axios from "axios";
import moment from "moment";
import periodSelector from "../components/elements/periodSelector.vue";
import AddBarometer from "../components/modals/AddBarometer";
import { cashConv } from "../modules/quickWins";
import companySelector from "@/components/elements/companySelector.vue";
import { jsPDF } from "jspdf";
import VueHtml2pdf from 'vue-html2pdf'


const MomentRange = require("moment-range");
const Moment = MomentRange.extendMoment(moment);

import algoliasearch from "algoliasearch/lite";
const client = algoliasearch('B4XU2Z5BAW', 'a29d0e9160e36dc8ee83d5888c28cdcc');
const clientIndex = client.initIndex('thrive_clients');


import VueGridLayout from 'vue-grid-layout';
import { DayTimeCols } from '@fullcalendar/timegrid';

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    "period-select": periodSelector,
    "add-barometer" : AddBarometer,
    companySelector: companySelector,
    VueHtml2pdf
  },
  name: "Reports",
  data: function () {
    return {

      pdfPrintIframe:{
        dialog: false,
        loading: false,
        html: "",
        changes: 0,
        src: ""
      },
      today: moment().format("YYYY-MM-DD"),
      loading: true,
      dataLoading: true,
      searchCompanies: "",
      moveGrid: false,
      activeFilter: 'dashboard',
      itemsPerPage: -1,
      layout: new Array(),
      indicators: new Array(), 
      actualChartseries: [
      {
          name: this.$t('reports.legend.downPayments', this.$store.state.locale),
          data: [0, 0, 0, 0, 0, 0,0]
        },
        {
          name: this.$t('reports.legend.treatments', this.$store.state.locale),
          data: [0, 0, 0, 0, 0, 0,0]
        }, 
        {
          name: this.$t('general.products', this.$store.state.locale),
          data: [0, 0, 0, 0, 0, 0,0]
        }, 
        {
          name: this.$t('reports.legend.expected', this.$store.state.locale),
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ],
      selectedYear: null,
      branchYears: new Array(),
      actualChartOptions: {   
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          animations: {
            enabled: true,
            easing: 'liniar',
            speed: 300,
            animateGradually: {
                enabled: false,
                delay: 1200
            },
            dynamicAnimation: {
                enabled: false,
                speed: 1200
            }
          },
        },
        
        
        tooltip: {
          enabled: true,
          shared: true,
          intersect: false,
          fillSeriesColor: true,
          onDatasetHover: {
            highlightDataSeries: true,
          },
          custom: ({ series, seriesIndex, dataPointIndex, w })=> {
            let currentTotal = 0
            let seriesData = new Array()
            let label = ""
            series.forEach((s, index) => {
              let data = w.globals.initialSeries[index].name;
              let color = w.globals.colors[index];
              label = w.globals.labels[dataPointIndex];
              if(s[dataPointIndex] > 0){
                let dataObject = `<div class="custom-tooltip-data-wrapper">
                  <div class="custom-tooltip-data-color" style="background-color: ${color}"></div>
                  <div class="custom-tooltip-data-name">${data}:</div>
                  <div class="custom-tooltip-data-value">&euro; ${s[dataPointIndex]}</div>
                </div>`
                seriesData.push(dataObject);
              }
              currentTotal += s[dataPointIndex];
            })

            let today = label == moment().format("YYYY-MM-DD") ? '<span class="tooltip-today">Vandaag</span>' : "";
            return `
              <div class="custom-data-tooltip">
                <div class="custom-data-tooltip-header ${today !== "" ? "today" :""}">${today} <span class="day">${moment(label).locale('nl').format("dddd")}</span>(${moment(label).format("D MMMM")})</div>
                <div class="custom-data-tooltip-content">
                  ${seriesData.join("")}
                  <div class="custom-tooltip-data-wrapper total ${seriesData.length < 2 ? 'hide-total' :''}">
                    <div class="custom-tooltip-data-color"></div>
                    <div class="custom-tooltip-data-name">${this.$t('general.total', this.$store.state.locale)}:</div>
                    <div class="custom-tooltip-data-value">&euro; ${currentTotal}</div>
                  </div>

                  <div class="custom-tooltip-data-wrapper message ${seriesData.length == 0 ? '' :'hide-message'}">
                    ${this.$t('reports.noRevenue', this.$store.state.locale)}
                  </div>
                </div>
              </div>`
          },
          theme: 'light',
          y: {
            // show: true,
            // formatter: function(value) {
            //   return `€ ${value}`;
            // }
          }
        },
        dataLabels: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }], 
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              enabled: false,
              total: {
                enabled: true,
                style: {
                  fontSize: '12px',
                  fontWeight: 700
                },
                formatter: function(value) {
                  return value > 0 ? `€${value.toFixed(0)}` : '';
                }
              }
            }
          },
        },
        colors: new Array(),   
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            formatter: function(value) {
              return `${moment(value).locale('nl').format("dddd")}`;
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return `€${value.toFixed(0)}`;
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center', 
          fontSize: '12px',
          offsetY: 5,
        },
        fill: {
          opacity: 1
        }
      },
      branchPieChartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        legend: {
          position: 'bottom',
          offsetX: 10,
          offsetY: 0
        },
        labels: [this.$t('general.clients', this.$store.state.locale), this.$t('reports.clients.noClient', this.$store.state.locale)],
        colors: new Array()
      },

      branchChartOptions: { 
        animations: {
          enabled: false,
          speed: 50,
          dynamicAnimation: {
            enabled: false,
            speed: 50
          }
        },  
        chart: {
          stacked: false,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          fillSeriesColor: false,
          theme: 'light',
          y: {
            show: true,
            formatter: function(value) {
              return `€${value.toFixed(2)}`;
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 10,
              offsetY: 10
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        colors: new Array(),
        yaxis: {
          labels: {
            formatter: function(value) {
              return `€${value.toFixed(0)}`;
            }
          }
        },   
        xaxis: {
          type: 'category',
          categories: new Array(),
          tooltip: {
            enabled: false,
          },
          tickAmount: 10,
          labels: {
              rotate: 0,
              style: {
                fontSize: '11px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 500,
            },
          },

        },
      },

      employeeChartOptions: { 
        animations: {
          enabled: false,
          speed: 50,
          dynamicAnimation: {
            enabled: false,
            speed: 50
          }
        },  
        chart: {
          stacked: false,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
          theme: 'light',
          y: {
            show: true,
            formatter: function(value) {
              return `€${value.toFixed(2)}`;
            }
          },
        },
        dataLabels: {
          enabled: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 10,
              offsetY: 10
            }
          }
        }],
        stroke: { curve: 'smooth', lineCap: 'round', width: 3,},
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        colors: new Array(),
        yaxis: {
          labels: {
            formatter: function(value) {
              return `€${value.toFixed(0)}`;
            }
          }
        },   
        xaxis: {
          type: 'category',
          categories: [],
          tooltip: {
            enabled: false,
          },
          labels: {
              rotate: 0,
              style: {
                fontSize: '11px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 500,
            },
          },
          // axisTicks: {
          //     autoskip: false,
          //     maxTicksLimit: 5,
          //     interval: 5
          // }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center', 
          fontSize: '12px',
          offsetY: 5,
        },
      },

      employeeChartValueOptions: {   
        animations: {
          enabled: false,
          speed: 50,
          dynamicAnimation: {
            enabled: false,
            speed: 50
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
          theme: 'light',
          y: {
            show: true,
            formatter: function(value) {
              return `€${value}`;
            }
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 10,
              offsetY: 10
            }
          }
        }],
        stroke: { curve: 'smooth', lineCap: 'round', width: 3,},
        colors: new Array(),
        yaxis: {
          labels: {
            formatter: function(value) {
              return `€${value.toFixed(0)}`;
            }
          }
        },   
        xaxis: {
          type: 'category',
          categories: new Array(),
          tooltip: {
            enabled: false,
          },
          tickAmount: 7,
          labels: {
              rotate: 0,
              style: {
                fontSize: '11px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 500,
            },
          },
          // axisTicks: {
          //   autoskip: false,
          //   maxTicksLimit: 8,
          //   interval: 8
          // }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center', 
          fontSize: '12px',
          offsetY: 5,
        },
        fill: {
          opacity: 1
        }
      },

      employeeSicknessLeaves: new Array(),

      treatmentsDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        employee: null,
        loaded: false
      },
      employeeSicknessLeavesDataFilter: {
        type: "year",
        year: moment().year(),
        number: moment().isoWeek(),
        employee: null,
        loaded: false
      },
      clientData: {
        totalRevenue: new Array(),
        averageRevenue: new Array()
      },
      employeePersonalDataFilter:{
        employee: null,
      },
      totalBranchRevenue: 0,
      totalBranchVisits: 0,

      dashboardSkeletonLoaded: false,
      dashboardFullLoaded: false,

      registerDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        loaded: false
      },
      productsDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        employee: null,
        loaded: false
      },
      dashboardDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        employee: null,
        loaded: false
      },
      employeeProductivityDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        loaded: false
      },
      clientDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        loaded: false
      },
      receiptDataFilter: {
        year: moment().year(),
        user: null,
        loaded: false
      },
      receiptAppointmentDataFilter: {
        year: moment().year(),
        user: null,
        loaded: false
      },
      receiptListDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        loaded: false
      },
      openReceiptDataFilter: {
        type: "week",
        year: moment().year(),
        number: moment().isoWeek(),
        loaded: false
      },

      depositDataFilter: {
        type: "month",
        year: moment().year(),
        number: Number(moment().format("M")),
        loaded: false
      },

      depositData: {
        open: {
          number:0,
          amount: 0
        },
        total: {
          number:0,
          amount: 0
        },
        settled: {
          number:0,
          amount: 0
        },
        compensation: {
          number:0,
          amount: 0,
          data: new Array()
        },
        refunded: {
          number:0,
          amount: 0
        },
        graph: {
          labels: new Array(),
          value: new Array(),
          type: null
        },
        prepayments: new Array(),
        prepayments: new Array(),
        allPrepayments: new Array()
      },


      cashUpDataFilter: {
        day: moment().format("YYYY-MM-DD"),
        loaded: false
      },
      cashUpCalendar: false,
      cashUpData: null,
      branchRevenueAverageData: [
        {
          data: new Array()
        },
        {
          data: new Array()
        }
      ],
      branchRevenueData: [
        {
          data: new Array()
        },
        {
          data: new Array()
        }
      ],
      employeePersonalPerformanceValueData: [
        {
          data: new Array()
        }
      ],
      employeePersonalPerformanceData: [
        {
          name: this.$t('reports.legend.treatments', this.$store.state.locale),
          data: new Array()
        }, {
          name: this.$t('general.products', this.$store.state.locale),
          data: new Array()
        }
      ],
      branchVisitorsNumber: new Array(),
      branchVisitorsData: new Array(),
      treatmentTypes: new Array(),
      productTypes: new Array(),
      treatments: new Array(),
      employees: new Array(),
      users: new Array(),
      products: new Array(),
      barometerChartOptions: new Object(),
      actualChartHeight: '320px',
      actualRevenueLoading: true,
      actualRevenue: {
        estimated: {
          bruto: 0,
          netto: 0
        },
        downPayment: {
          bruto: 0,
          netto: 0
        },
        estimatedTotal: {
          bruto: 0,
          netto: 0
        },
        current: {
          bruto: 0,
          netto: 0
        },
        average: {
          total: {
            bruto: 0,
            netto: 0
          },
          number: 0,
          activeDays: 0
        },
        treatments: 0,
        products: 0
      },
      actualRevenueDates: {
        weekNumber: null,
        startDay: null,
        endDay: null,
        weekType: 'now',
      },
      treatmentData: {
        loaded: false,
        treatments: new Array(),
        categories: new Array(),
        total: {
          number: 0,
          totalNettoRevenue: 0,
          totalBrutoRevenue: 0
        }
      },
      productData: {
        loaded: false,
        products: new Array(),
        categories: new Array(),
        total: {
          number: 0,
          totalNettoRevenue: 0,
          totalBrutoRevenue: 0
        }
      },
      registerData: {
        revenue: new Array(),
        cashUp: {
          data: new Array()
        },
        deficit: {
          data: new Array()
        },
        register: {
          data: new Array()
        },
        expense: new Array()
      },
      receiptData: {
        quarter: new Array(),
        month: new Array(),
        fourWeeks: new Array(),
        week: new Array()
      },
      receiptAppointmentData: {
        quarter: new Array(),
        month: new Array(),
        fourWeeks: new Array(),
        week: new Array()
      },
      receiptListData: new Object(),
      openReceiptData: new Array(),
      employeeProductivityData:{
        revenue: new Array(),
        time: new Array()
      },
      vatViewType: "included",
      vatViewTypes: [
        {
          name: {
            en: "VAT included",
            nl: "Inclusief BTW"
          },
          id: "included"
        },
        {
          name: {
            en: "VAT excluded",
            nl: "Exclusief BTW"
          },
          id: "excluded"
        }
      ],
      vatTypes: new Array(),
      revenueOverview: {},
      vatOverview: {},
      categoriesHeader: [
        {
          text: this.$t('reports.table.category', this.$store.state.locale),
          align: "left",
          sortable: false,
        },
        {
          text: this.$t('reports.table.number', this.$store.state.locale),
          align: "center",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.average', this.$store.state.locale),
          align: "right",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.revenue', this.$store.state.locale),
          align: "right",
          width: "120px",
          sortable: false,
        },
      ],
      depositsHeader: [
        {
          text: this.$t('general.client', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "250px"
        },
        {
          text: this.$t('general.type', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "200px"
        },
        {
          text: this.$t('general.amount', this.$store.state.locale),
          align: "right",
          sortable: false,
          width: "200px"
        },
        {
          text: this.$t('general.extra', this.$store.state.locale),
          align: "left",
          sortable: false,
        },
      ],

      depositsHeaderDevelop: [
        {
          text: this.$t('general.client', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "250px"
        },
        {
          text: this.$t('general.type', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          sortable: false,
        },
        {
          text: this.$t('general.amount', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('general.status', this.$store.state.locale),
          align: "left",
          sortable: false,
        },
        {
          text: "Prepayment DocId",
          align: "left",
          sortable: false,
        },
      ],
      treatmentsHeader: [
        {
          text: this.$t('general.treatments', this.$store.state.locale),
          align: "left",
          
          sortable: false,
        },
        {
          text: this.$t('reports.table.category', this.$store.state.locale),
          align: "left",
          sortable: false,
        },
        {
          text: this.$t('reports.table.number', this.$store.state.locale),
          align: "center",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.average', this.$store.state.locale),
          align: "right",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.revenue', this.$store.state.locale),
          align: "right",
          width: "120px",
          sortable: false,
        },
      ],
      productsHeader: [
        {
          text: this.$t('general.products', this.$store.state.locale),
          align: "left",   
          sortable: false,
        },
        {
          text: this.$t('reports.table.category', this.$store.state.locale),
          align: "left",
          sortable: false,
        },
        {
          text: this.$t('reports.table.number', this.$store.state.locale),
          align: "center",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.average', this.$store.state.locale),
          align: "right",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.revenue', this.$store.state.locale),
          align: "right",
          width: "120px",
          sortable: false,
        },
      ],
      revenueVatHeader: [
        {
          text: this.$t('general.quarter', this.$store.state.locale),
          align: "left",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('general.revenue', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat9vat', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat21revenue', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat21vat', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat', this.$store.state.locale),
          align: "right",
          value: "products",
          sortable: true,
        },
        {
          text: this.$t('reports.table.totalRevenueBruto', this.$store.state.locale),
          align: "right",
          value: "total",
          sortable: false,
        }
      ],
      revenueVatHeaderMonth: [
        {
          text: this.$t('general.month', this.$store.state.locale),
          align: "left",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('general.revenue', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat9vat', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat21revenue', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat21vat', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('reports.table.vat', this.$store.state.locale),
          align: "right",
          value: "products",
          sortable: true,
        },
        {
          text: this.$t('reports.table.totalRevenueBruto', this.$store.state.locale),
          align: "right",
          value: "total",
          sortable: false,
        }
      ],
      receiptsQuarterHeader: [
        {
          text: this.$t('general.quarter', this.$store.state.locale),
          align: "left",
          value: "month",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('general.treatments', this.$store.state.locale),
          align: "left",
          value: "treatment",
          sortable: false,
        },
        {
          text: this.$t('general.products', this.$store.state.locale),
          align: "left",
          value: "products",
          sortable: true,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "left",
          value: "total",
          sortable: false,
        }
      ],
      receiptsMonthHeader: [
        {
          text: this.$t('general.month', this.$store.state.locale),
          align: "left",
          value: "month",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('general.treatments', this.$store.state.locale),
          align: "left",
          value: "treatment",
          sortable: false,
        },
        {
          text: this.$t('general.products', this.$store.state.locale),
          align: "left",
          value: "products",
          sortable: true,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "left",
          value: "total",
          sortable: false,
        }
      ],
      receiptsWeekHeader: [
        {
          text: this.$t('general.week', this.$store.state.locale),
          align: "left",
          value: "month",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('general.treatments', this.$store.state.locale),
          align: "left",
          value: "treatment",
          sortable: false,
        },
        {
          text: this.$t('general.products', this.$store.state.locale),
          align: "left",
          value: "products",
          sortable: true,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "left",
          value: "total",
          sortable: false,
        }
      ],
      revenueHeader:[
        {
          text: this.$t('general.period', this.$store.state.locale),
          align: "left",
          width: "200px",
          sortable: false,
        },
        {
          text: this.$t('general.treatments', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('general.products', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "right",
          sortable: false,
        },
      ],
      registerRevenueHeader: new Array(),
      registerRevenueCashUpHeader: [
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "day",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.pin', this.$store.state.locale),
          align: "right",
          value: "pin",
          sortable: false,
        },
        {
          text: this.$t('reports.table.creditcard', this.$store.state.locale),
          align: "right",
          value: "creditcard",
          sortable: false,
        },
        {
          text: this.$t('reports.table.cashUpDeposit', this.$store.state.locale),
          align: "right",
          value: "cashUpDeposit",
          sortable: false,
        },
        {
          text: this.$t('reports.table.cashUpDepositToBank', this.$store.state.locale),
          align: "right",
          value: "cashUpDepositToBank",
          sortable: false,
        }
      ],
      registerContentHeader: [
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "day",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.before', this.$store.state.locale),
          align: "right",
          value: "cashRounding",
          sortable: false,
        },
        {
          text: this.$t('reports.table.after', this.$store.state.locale),
          align: "right",
          value: "cashContent",
          sortable: false,
        },
        {
          text: this.$t('reports.table.decrease', this.$store.state.locale),
          align: "right",
          value: "surplus",
          sortable: false,
        },
        {
          text: this.$t('reports.table.increase', this.$store.state.locale),
          align: "right",
          value: "deficit",
          sortable: false
        }
      ],

      registerRevenueDeficitHeader: [
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "day",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.cashRounding', this.$store.state.locale),
          align: "right",
          value: "cashRounding",
          sortable: false,
        },
        {
          text: this.$t('reports.table.registerDifference', this.$store.state.locale),
          align: "right",
          value: "cashContent",
          sortable: false,
        },
        {
          text: this.$t('reports.table.surplus', this.$store.state.locale),
          align: "right",
          value: "surplus",
          sortable: false,
        },
        {
          text: this.$t('reports.table.deficit', this.$store.state.locale),
          align: "right",
          value: "deficit",
          sortable: false,
        }
      ],
      registerRevenueExpensesHeader: [
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "day",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.employee', this.$store.state.locale),
          align: "left",
          value: "resource",
          sortable: false,
        },
        {
          text: this.$t('reports.table.typeExpenses', this.$store.state.locale),
          align: "left",
          value: "typeExpenses",
          sortable: false,
        },
        {
          text: this.$t('reports.table.expenseName', this.$store.state.locale),
          align: "left",
          value: "expenseName",
          sortable: false,
        },
        {
          text: this.$t('reports.table.expense', this.$store.state.locale),
          align: "left",
          value: "expense",
          sortable: false,
        }
      ],
      receiptsDataQuarterHeader: [
        {
          text: this.$t('general.quarter', this.$store.state.locale),
          align: "left",
          value: "quarter",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "withTreatment",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withoutTreatment', this.$store.state.locale),
          align: "left",
          value: "withoutTreatment",
          sortable: false,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "left",
          value: "total",
          sortable: false,
        }
      ],
      receiptsDataMonthHeader: [
        {
          text: this.$t('general.month', this.$store.state.locale),
          align: "left",
          value: "month",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "withTreatment",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withoutTreatment', this.$store.state.locale),
          align: "left",
          value: "withoutTreatment",
          sortable: false,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "left",
          value: "total",
          sortable: false,
        }
      ],
      receiptsDataFourWeeksHeader: [
        {
          text: this.$t('general.fourWeeks', this.$store.state.locale),
          align: "left",
          value: "fourWeeks",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "withTreatment",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withoutTreatment', this.$store.state.locale),
          align: "left",
          value: "withoutTreatment",
          sortable: false,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "left",
          value: "total",
          sortable: false,
        }
      ],
      receiptsDataWeekHeader: [
        {
          text: this.$t('general.week', this.$store.state.locale),
          align: "left",
          value: "week",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "withTreatment",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withoutTreatment', this.$store.state.locale),
          align: "left",
          value: "withoutTreatment",
          sortable: false,
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "left",
          value: "total",
          sortable: false,
        }
      ],
      receiptAppointmentDataQuarterHeader: [
        {
          text: this.$t('general.quarter', this.$store.state.locale),
          align: "left",
          value: "quarter",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "treatments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.appointments', this.$store.state.locale),
          align: "left",
          value: "appointments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.payed', this.$store.state.locale),
          align: "left",
          value: "payed",
          sortable: false,
        }
      ],
      receiptAppointmentDataMonthHeader: [
        {
          text: this.$t('general.month', this.$store.state.locale),
          align: "left",
          value: "month",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "treatments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.appointments', this.$store.state.locale),
          align: "left",
          value: "appointments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.payed', this.$store.state.locale),
          align: "left",
          value: "payed",
          sortable: false,
        }
      ],
      receiptAppointmentDataFourWeeksHeader: [
        {
          text: this.$t('general.fourWeeks', this.$store.state.locale),
          align: "left",
          value: "fourWeeks",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "treatments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.appointments', this.$store.state.locale),
          align: "left",
          value: "appointments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.payed', this.$store.state.locale),
          align: "left",
          value: "payed",
          sortable: false,
        }
      ],
      receiptAppointmentDataWeekHeader: [
        {
          text: this.$t('general.week', this.$store.state.locale),
          align: "left",
          value: "week",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.withTreatment', this.$store.state.locale),
          align: "left",
          value: "treatments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.appointments', this.$store.state.locale),
          align: "left",
          value: "appointments",
          sortable: false,
        },
        {
          text: this.$t('reports.table.payed', this.$store.state.locale),
          align: "left",
          value: "payed",
          sortable: false,
        }
      ],
      employeeProductivityRevenueHeader: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "week",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.productivity', this.$store.state.locale),
          align: "right",
          value: "productivity",
          sortable: false,
        },
        {
          text: this.$t('reports.table.hourValue', this.$store.state.locale),
          align: "right",
          value: "hourValue",
          sortable: false,
        },
        {
          text: this.$t('reports.table.value', this.$store.state.locale),
          align: "right",
          value: "value",
          sortable: false,
        },
        {
          text: this.$t('reports.table.hourRevenue', this.$store.state.locale),
          align: "right",
          value: "hourRevenue",
          sortable: false,
        },
        {
          text: this.$t('reports.table.revenue', this.$store.state.locale),
          align: "right",
          value: "revenue",
          sortable: false,
        },
      ],
      employeeProductivityTimeHeader: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "week",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.productivity', this.$store.state.locale),
          align: "right",
          value: "productivity",
          sortable: false,
        },
        {
          text: this.$t('reports.table.presenceTime', this.$store.state.locale),
          align: "right",
          value: "presenceTime",
          sortable: false,
        },
        {
          text: this.$t('reports.table.basicSchedule', this.$store.state.locale),
          align: "right",
          value: "presencePrecentage",
          sortable: false,
        },
        {
          text: this.$t('reports.table.fte', this.$store.state.locale),
          align: "right",
          value: "fte",
          sortable: false,
        }
      ],
      clientDataTotalHeader: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "week",  
          sortable: false,
        },
        {
          text: this.$t('reports.table.visits', this.$store.state.locale),
          align: "right",
          value: "vistits",
          width: "300px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.totalRevenue', this.$store.state.locale),
          align: "right",
          value: "totalRevenue",
          width: "300px",
          sortable: false,
        },
      ],
      employeeSicknessLeaveHeader: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "resource.id",
          sortable: true,
        },
        {
          text: this.$t('reports.table.notification', this.$store.state.locale),
          align: "left",
          sortable: false,
        },
        {
          text: this.$t('general.start', this.$store.state.locale),
          align: "left",
          width: "200px",
          sortable: false,
        },
        {
          text: this.$t('general.end', this.$store.state.locale),
          align: "left",
          width: "200px",
          sortable: false,
        },
        {
          text: this.$t('general.hours', this.$store.state.locale),
          align: "right",
          sortable: false,
        }
      ],
      clientDataAverageHeader: [
        {
          text: this.$t('general.name', this.$store.state.locale),
          align: "left",
          value: "week",
          sortable: false,
        },
        {
          text: this.$t('reports.table.visits', this.$store.state.locale),
          align: "right",
          value: "vistits",
          width: "300px",
          sortable: false,
        },
        {
          text: this.$t('reports.table.averageRevenue', this.$store.state.locale),
          align: "right",
          value: "averageRevenue",
          sortable: false,
          width: "300px",
        }
      ],
      cashUpIncomeHeader: [
        {
          text: this.$t('reports.table.kind', this.$store.state.locale),
          align: "left",
          value: "type",
          sortable: false,
        },
        {
          text: this.$t('general.start', this.$store.state.locale),
          align: "right",
          value: "start",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t('reports.table.counted', this.$store.state.locale),
          align: "right",
          value: "counted",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t('reports.table.income', this.$store.state.locale),
          align: "right",
          value: "income",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t('reports.table.expected', this.$store.state.locale),
          align: "right",
          value: "expected",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t('reports.table.difference', this.$store.state.locale),
          align: "right",
          value: "difference",
          sortable: false,
          width: "120px",
        }
      ],
      cashUpRegisterHeader: [
        {
          text: this.$t('reports.table.kind', this.$store.state.locale),
          align: "left",
          value: "type",
          sortable: false,
        },
        {
          text: this.$t('reports.table.value', this.$store.state.locale),
          align: "right",
          value: "averageRevenue",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "right",
          value: "total",
          sortable: false,
          width: "120px",
        }
      ],
      cashUpCashHeader: [
        {
          text: this.$t('reports.table.kind', this.$store.state.locale),
          align: "left",
          value: "type",
          sortable: false,
        },
        {
          text: this.$t('reports.table.number', this.$store.state.locale),
          align: "center",
          value: "number",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t('reports.table.value', this.$store.state.locale),
          align: "right",
          value: "averageRevenue",
          sortable: false,
          width: "120px",
        },
        {
          text: this.$t('general.total', this.$store.state.locale),
          align: "right",
          value: "total",
          sortable: false,
          width: "120px",
        }
      ],
      openReceiptDataHeader :[
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "visit",
          sortable: true,
          width: "200px"
        },
        { 
          text: this.$t('payment.customer', this.$store.state.locale),
          align: "left",
          value: "clientname",
          sortable: true,
        },
        {
          text: this.$t('general.amount', this.$store.state.locale),
          align: "right",
          value: "price",
          sortable: true,
        },
      ],
      receiptDataHeader :[
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "visit",
          sortable: true,
          width: "200px"
        },
        { 
          text: this.$t('payment.customer', this.$store.state.locale),
          align: "left",
          value: "userId",
          sortable: true,
        },
        { 
          text: this.$t('general.employee', this.$store.state.locale),
          align: "left",
          value: "clientname",
          sortable: true,
        },
        { 
          text: this.$t('payment.method', this.$store.state.locale),
          align: "left",
          value: "payedBy",
          sortable: true,
        },
        {
          text: this.$t('general.amount', this.$store.state.locale),
          align: "right",
          value: "price",
          sortable: true,
        },
      ],
      copyValue: null,
      copyType: null
    }
    
  },

  created() {
    // this.checkPackages()
    this.loadData();
    
  },

  methods: {
    async loadData(){
      this.treatmentsDataFilter.employee = null;
      await Promise.all([this.setBranchYears(),this.loadTreatments(), this.loadTreatmentTypes(), this.loadUsers(), this.loadEmployees(),this.loadProducts(),this.loadProductTypes(), this.loadVatTypes()])
      this.selectReportView('dashboard', 'new');
      this.actualChartOptions.colors = new Array(); // ['#00ADEF', '#687387','#ccc']
      this.actualChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].menuActivePrimary);
      this.actualChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].primary);
      this.actualChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].chartSecondary);
      this.actualChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].chartTertiary);
      this.employeeChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].primary);
      this.employeeChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].secondary);
      this.employeeChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].tertiary);
      this.employeeChartValueOptions.colors.push(this.$themes[this.companyTheme][this.theme].primary);
      this.employeeChartValueOptions.colors.push(this.$themes[this.companyTheme][this.theme].secondary);
      this.branchChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].primary, this.$themes[this.companyTheme][this.theme].chartSecondary)
      this.branchPieChartOptions.colors.push(this.$themes[this.companyTheme][this.theme].primary, this.$themes[this.companyTheme][this.theme].chartSecondary)
      
      let chartOptions = {   
        chart: {
          width: '100%',
          offsetX: 0,
          offsetY: 0,
          sparkline: {
              enabled: true,
          },
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        plotOptions: {
          radialBar: {
            inverseOrder: false,
            startAngle: -90,
            endAngle: 90,
            offsetX: 0,
            offsetY: 0,            
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '18px'
              }
            }
          },
        },
        stroke: { curve: 'smooth', lineCap: 'round'},
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: true,
          strokeDashArray: 6,
          position: 'back',
          xaxis: {
            lines: {
                show: false
            }
          },   
          xaxis: {
            lines: {
                show: true
            }
          }, 
          yaxis: {
            lines: {
                show: false
            }
          },
          markers: { size: 1},
          padding: {
            top: 7,
            right: 0,
            bottom: 5,
            left: 0
          },  
        },
        colors: new Array(),   
        xaxis: {
          labels: {
            show: false,
            offsetX: 0,
            offsetY: 0,
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
            offsetX: 0,
            offsetY: 0
          },
          axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false,
          },
        },
      };
      this.barometerChartOptions = new Object();
      this.barometerChartOptions.primary = _.cloneDeep(chartOptions)
      this.barometerChartOptions.secondary = _.cloneDeep(chartOptions)
      this.barometerChartOptions.tertiary = _.cloneDeep(chartOptions)
      this.barometerChartOptions.quaternary = _.cloneDeep(chartOptions)
      this.barometerChartOptions.primary.colors.push(this.$themes[this.companyTheme][this.theme].primary)
      this.barometerChartOptions.secondary.colors.push(this.$themes[this.companyTheme][this.theme].secondary)
      this.barometerChartOptions.tertiary.colors.push(this.$themes[this.companyTheme][this.theme].tertiary)
      this.barometerChartOptions.quaternary.colors.push(this.$themes[this.companyTheme][this.theme].quaternary)
      this.loading = false;
    },

    updateDataPeriodRefresh(filter){
      this.dataLoading = true;
      // console.log("updateDataPeriodRefresh: ", filter.year, filter.type, filter.number)
      if(filter.dataType == 'treatments'){
        this.treatmentsDataFilter.type = filter.type;
        this.treatmentsDataFilter.year = filter.year;
        this.treatmentsDataFilter.number = filter.number;
        this.getTreatmentData()
      }
      if(filter.dataType == 'products'){
        this.productsDataFilter.type = filter.type;
        this.productsDataFilter.year = filter.year;
        this.productsDataFilter.number = filter.number;
        this.getProductData()
      }
      if(filter.dataType == 'register'){
        this.registerDataFilter.type = filter.type;
        this.registerDataFilter.year = filter.year;
        this.registerDataFilter.number = filter.number;
        this.getRegisterStatusData();
      }
      if(filter.dataType == 'employeePerformance'){
        this.employeeProductivityDataFilter.type = filter.type;
        this.employeeProductivityDataFilter.year = filter.year;
        this.employeeProductivityDataFilter.number = filter.number;
        this.getEmployeeProductivityData();
      }
      if(filter.dataType == 'employeePersonalPerformance'){
        this.getEmployeePersonalPerformanceData();
      }
      if(filter.dataType == 'dashboard'){
        this.dashboardDataFilter.type = filter.type;
        this.dashboardDataFilter.year = filter.year;
        this.dashboardDataFilter.number = filter.number;
        this.getDashboardData();
      }
      if(filter.dataType == 'branchClients'){
        this.clientDataFilter.type = filter.type;
        this.clientDataFilter.year = filter.year;
        this.clientDataFilter.number = filter.number;
        this.getBranchClientData();
      }
      if(filter.dataType == 'employeeSicknessLeave'){
        this.employeeSicknessLeavesDataFilter.type = filter.type;
        this.employeeSicknessLeavesDataFilter.year = filter.year;
        this.employeeSicknessLeavesDataFilter.number = filter.number;
        this.getEmployeeSicknessLeaveData();
      }

      if(filter.dataType == 'receiptList'){
        this.receiptListDataFilter.type = filter.type;
        this.receiptListDataFilter.year = filter.year;
        this.receiptListDataFilter.number = filter.number;
        this.getReceiptListData();
      }

      if(filter.dataType == 'registerListOpen'){
        this.openReceiptDataFilter.type = filter.type;
        this.openReceiptDataFilter.year = filter.year;
        this.openReceiptDataFilter.number = filter.number;
        this.getReceiptOpenData();
      }

      if(filter.dataType == 'deposit'){
        this.depositDataFilter.type = filter.type;
        this.depositDataFilter.year = filter.year;
        this.depositDataFilter.number = filter.number;
        this.getDespositData();
      }
    },

    setBranchYears(){
      this.branchYears = new Array();
      this.selectedYear = Number(moment().format("YYYY"));
      if(this.$store.state.activeBranch.created){
        let currentYear = Number(moment().year())
        let startDateBranch = moment(this.$store.state.activeBranch.created).format("YYYY-MM-DD")

        let numberOfYears = moment().diff(startDateBranch, 'years', false) + 1;
        let activeYears = Array.apply(null, Array(numberOfYears))
        let counter = 0

        activeYears.forEach((year, index)=>{
          this.branchYears.push(currentYear - counter)
          counter++
        })
      }
      else{
        this.branchYears.push(this.selectedYear)
      }
      return
    },

    getFontWeight(item){
      if(item== 'total'){
        return 'bold';
      }
      else{
        return 'normal';
      }
    },

    loadEmployees(){
      this.employees = new Array();
      let allEmployees = {
        id: null,
        name: "Alle medewerkers",
      }
      this.employees.push(allEmployees);
      db.collection("employees")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let employee = doc.data();
            employee.id = doc.id;
            employee.minutes = 0;
            employee.scheduleExceptions = new Array();
            this.employees.push(employee);
          });
        })
    },

    loadUsers(){
      this.users = new Array();
      let allUsers = {
        id: null,
        firstName: "Alle ",
        lastName: "medewerkers"
      }
      this.users.push(allUsers);
      db.collection("users")
        .where("accessCompanies", "array-contains", this.$store.state.activeCompany.id)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let user = doc.data();
            user.id = doc.id;
            this.users.push(user);
          });
        })
    },

    async loadTreatments(){
      this.treatments = new Array();
      await db.collection("treatments")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let treatment = doc.data();
          treatment.id = doc.id;
          this.treatments.push(treatment);
        });
      })
      return
    },

   async loadProducts(){
      this.products = new Array();
      await db.collection("products")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let product = doc.data();
          product.id = doc.id;
          this.products.push(product);
        });
      })
      return
    },

    async loadTreatmentTypes(){
      this.treatmentTypes = new Array();
      await db.collection("treatmentTypes")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let treatmentType = doc.data();
          treatmentType.id = doc.id;
          this.treatmentTypes.push(treatmentType);
        });
      })
      return
    },

    async loadProductTypes(){
      this.productTypes = new Array();
      await db.collection("productCategories")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let productType = doc.data();
          productType.id = doc.id;
          this.productTypes.push(productType);
        });
      })
      return
    },

    async loadVatTypes(){
      this.productTypes = new Array();
      await db.collection("vat")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let vat = doc.data();
          vat.id = doc.id;
          this.vatTypes.push(vat);
        });
      })
      return
    },

    getProductName(id){
      if(id){   
        let name = null;
        let index = _.findIndex(this.products, {'id': id})
        if(index => 0){
          name =  this.products[index].name;
        }
        return name;
      }
      else{
        return null;
      }
    },

    getTreatmentPrice(id){
      let price = {
        netto: 0,
        bruto: 0
      };
      let treatment = this.treatments.find(treatment=> { return treatment.id == id})
      if(treatment && treatment != undefined){
        let vatItem = this.vatTypes.find(vat=> { return vat.id == treatment.vat})
        if(vatItem && vatItem != undefined){
          price.netto = treatment.treatmentPrice 
          price.bruto = Number(treatment.treatmentPrice * ((vatItem.percentage / 100) + 1));

          if(treatment.branchPriceAdjustments && treatment.branchPriceAdjustments[this.$store.state.activeBranch.id] && treatment.branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType){ // There are price adjustments for this branch
            if(treatment.branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType != 'noChanges'){
              price.netto = treatment.branchPriceAdjustments[this.$store.state.activeBranch.id].totalAmountNetto; 
              price.bruto = Number(treatment.branchPriceAdjustments[this.$store.state.activeBranch.id].totalAmountNetto * ((vatItem.percentage / 100)+1));
            }
          }

        } 
      }
      return price
    },

    getTreatmentName(id){
      if(id){   
        let name = null;
        let index = _.findIndex(this.treatments, {'id': id})
        if(index => 0){
          name =  this.treatments[index].treatmentTitle;
        }
        return name;
      }
      else{
        return null;
      }
    },

    userName(id){
      if(id){  
        let name = null;
        let index = _.findIndex(this.users, {'id': id})
        if(index >= 0){
          name =  `${this.users[index].firstName} ${this.users[index].lastName}`;
        }
        return name;
      }
      else{
        return this.$t('reports.employees.allEmployees', this.$store.state.locale);
      }
    },

    employeeName(id){
      if(id){  
        let name = null;
        let index = _.findIndex(this.employees, {'id': id})
        if(index >= 0){
          name =  `${this.employees[index].firstName} ${this.employees[index].lastName}`;
        }
        return name;
      }
      else{
        return this.$t('reports.employees.allEmployees', this.$store.state.locale);
      }
    },

    getProductTypeName(id){
      if(id){  
        let name = null;
        let index = _.findIndex(this.treatmentTypes, {'id': id})
        if(index => 0){
          name =  this.treatmentTypes[index].name;
        }
        return name;
      }
      else{
        return null;
      }
    },

    getTreatmentTypeName(id){
      if(id){  
        let name = null;
        let index = _.findIndex(this.productTypes, {'id': id})
        if(index => 0){
          name =  this.productTypes[index].name[this.$store.state.locale.toLowerCase()];
        }
        return name;
      }
      else{
        return null;
      }
    },
 
    formatReportNumber(number, digits){
      if(number){
        return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: digits == 0 ? 0 : 2, maximumFractionDigits: digits == 0 ? 0 : 2}).format(Number(number));
      }
      else{
        return "-"
      } 
    },

    formatReportDate(data, oldFormat, newFormat){
      let date = null;
      if(moment(data, oldFormat).isValid()){
        date = moment(data, oldFormat).format(newFormat);
      }
      return date;
    },

    selectReportView(filter, type ){
      this.dataLoading = true;
      this.activeFilter = filter;

      if(filter =='dashboard'){
        bus.$emit("setFilterData", this.dashboardDataFilter);
        if(!this.dashboardDataFilter.loaded || type == 'new'){
          this.getDashboardData()
        }
        else{
          this.dataLoading = false;
        }
      }

      if(filter =='revenueCurrent'){
        this.getCurrentRevenue()
      }

      if(filter =='revenueOverview'){
        this.getRevenueOverview()
      }

      if(filter =='revenueVat'){
        this.getVatOverview()
      }

      if(filter == 'deposits'){
        bus.$emit("setFilterData", this.depositDataFilter);
        this.getDespositData()
      }

      if(filter =='salesTreatments'){
        bus.$emit("setFilterData", this.treatmentsDataFilter);
        if(!this.treatmentData.loaded){ // Data alleen laden als er nog niks is geladen
          this.getTreatmentData();
        }
        else{
          this.dataLoading = false;
        }
      }

      if(filter =='salesProducts'){
        bus.$emit("setFilterData", this.productsDataFilter);
        if(!this.productData.loaded){ // Data alleen laden als er nog niks is geladen
          this.getProductData();
        }
        else{
          this.dataLoading = false;
        }
      }

      if(filter == "registerStatus"){
        bus.$emit("setFilterData", this.registerDataFilter);
        if(!this.registerDataFilter.loaded){
          this.getRegisterStatusData();
        }
        else{
          this.dataLoading = false;
        }  
      }

      if(filter == "cashUp"){
        // bus.$emit("setFilterData", this.registerDataFilter);
        if(!this.cashUpDataFilter.loaded){
          this.getCashUpData();
        }
        else{
          this.dataLoading = false;
        }  
      }

      

      if(filter == "receiptNumber"){
        this.getReceiptNumbersData();
      }

      if(filter == "receiptAppointment"){
        this.getReceiptAppointmentData();
      }

      if(filter == "receiptList"){
        this.getReceiptListData();
      }

      if(filter == "receiptOpen"){
        this.getReceiptOpenData();
      }
      

      if(filter == "employeePerformance"){
        bus.$emit("setFilterData", this.employeeProductivityDataFilter);
        if(!this.employeeProductivityDataFilter.loaded){
          this.getEmployeeProductivityData();
        }
        else{
          this.dataLoading = false;
        }
      }

      if(filter == 'employeePersonalPerformance'){
        if(this.employeePersonalDataFilter.employee){
          this.getEmployeePersonalPerformanceData();
        }
        else{ // No employee selected
          this.dataLoading = false;
        }
      }

      if(filter == "branchRevenue"){
        this.getBranchRevenueData();
      }

      if(filter == "branchClients"){
        this.getBranchClientData();
      }
      if(filter == "employeeSicknessLeave"){
        bus.$emit("setFilterData", this.employeeSicknessLeavesDataFilter);
        this.getEmployeeSicknessLeaveData()
      }
    },

    copyTextToClipboard(text, type) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(
        () => {
          this.copyType = type;
          this.copyValue = text;
          setTimeout(() => {
            this.copyType = type;
            this.copyValue = null;
          }, 1000);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    closePdfIframeDialog(){
      var iframe = document.getElementById('pdf-print-iframe');

    console.log("iframe: ", iframe)
      this.pdfPrintIframe.dialog = false;
    },


    async downloadDataToPDF(id){

      this.pdfPrintIframe.dialog = true
      this.pdfPrintIframe.loading = true
      console.log("downloadDataToPDF 1")
      
      console.log("downloadDataToPDF 2:", id)
      let pdfHtml = await document.getElementById(id)
      console.log("downloadDataToPDF 3: ", pdfHtml)

      let html = `
      <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN"
        "http://www.w3.org/TR/html4/strict.dtd">
      <HTML>
        <HEAD>
            <TITLE>My first HTML document</TITLE>
        </HEAD>
        <BODY>
     
          ${ _.cloneDeep(pdfHtml.outerHTML)}
        </BODY>
      </HTML>`;

      

      this.pdfPrintIframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
      this.pdfPrintIframe.html = pdfHtml.outerHTML;
      this.pdfPrintIframe.loading = false
      this.pdfPrintIframe.changes++
      
      setTimeout(()=>{
        this.$refs.html2Pdf.generatePdf()
      },1000)
     
        

//       if(pdfHtml){
//         // const pdf = require('html-pdf');
//         console.log("We gaan door bro!")

//         // pdf.create(pdfHtml).toFile('custom.pdf', (err, res) => {
//         //   if (err) return console.log(err);
//         //   console.log('PDF generated successfully:', res);
//         // });
//         // window.jsPDF = window.jspdf.jsPDF;

//         let options = {
//  orientation: 'p',
//  unit: 'mm',
//  format: 'a4',
//  putOnlyUsedFonts:true
// }
//         const pdf = new jsPDF(options);

//         // pdf.html(html, 10, 10);
//         // pdf.save("a4.pdf");
//         // pdf.html(html, function () {
//         //   pdf.save('Test.pdf');
//         // });

//         pdf.html(html, {
//           callback: function (pdf) {

//           // Save the PDF to a file or display it
//             pdf.save("output.pdf");
//           },
//           x: 10,
//           y: 15,
//           width: 190, //target width in the PDF document
//           windowWidth: 750, //window width in CSS pixels
//           orientation: 'p',
//  unit: 'mm',
//  format: 'a4',
//  putOnlyUsedFonts:true
//         });
//       }
//       else{
//         console.log("Niks te vinden bro!")
//       }
    },



    async getEmployeeSicknessLeaveData(){
      this.employeeSicknessLeaves = new Array();


      let employees = new Object();
      let sicknessLeaves = new Array();
      await new Promise((resolve)=>{ // Get all sickness Leaves in period
        db.collection("schedulesExceptions") 
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("year", "==", this.employeeSicknessLeavesDataFilter.year)
        .where("reason", "==", 'sickness')
        .orderBy("startdate", "asc")
        .get()
        .then(snap => {
          snap.forEach((doc) => {
            let sicknessLeave = doc.data();
            sicknessLeave.id = doc.id;
            sicknessLeave.minutes = 0;
            sicknessLeave.hours = 0;
            sicknessLeaves.push(sicknessLeave);
            if(!employees[sicknessLeave.resourceId]){
              employees[sicknessLeave.resourceId] = {
                resourceId: sicknessLeave.resourceId,
                events: new Array()
              }
            }
            employees[sicknessLeave.resourceId].events.push(sicknessLeave)
          });
        })
        .then(()=>{
          resolve()
        })
      })

      await new Promise((resolve)=>{ // Get all sickness Leaves in period
        let counter = 0
        
        sicknessLeaves.forEach(event=>{
          let employee = this.getEmployeeData(event.resourceId)
          let periodStartDate = event.startdate;
          let periodEndDate = event.enddate;
          let weeks = new Array();
          if(periodStartDate == periodEndDate){
            weeks.push({week: moment(periodStartDate).isoWeek(), year: this.employeeSicknessLeavesDataFilter.year})
          }
          else{
            let startWeek = moment(periodStartDate).isoWeek();
            let endWeek = moment(periodEndDate).isoWeek();
            let numberOfweeks = null;
            let weekCounter = null;
            if(startWeek <= endWeek){
              weekCounter = startWeek;
              numberOfweeks = (endWeek - startWeek) + 1;
            }
            else{
              numberOfweeks = (endWeek - startWeek) + 1; 
              weekCounter = startWeek;
            }
            let weeksArray = Array(numberOfweeks).fill(0);
            weeksArray.forEach(()=>{
              weeks.push({week:weekCounter, year: this.employeeSicknessLeavesDataFilter.year})
              weekCounter++
            })
          }

          

          this.getEmployeeWorkedHours(employee, weeks, periodStartDate, periodEndDate).then((data)=>{
            console.log("DATA!!!: ", data)
            event.minutes = data.minutes
            event.hours = (data.minutes / 60).toFixed(1)
            counter++
            if(counter == sicknessLeaves.length){
              resolve()
            }
          })

        })
      })

      this.employeeSicknessLeaves = Object.values(employees); 

      this.employeeSicknessLeaves =  _.orderBy(this.employeeSicknessLeaves, [function (o) {
        return o.events.length;
      }], ["desc"])
      
      this.dataLoading = false;
    },

    getEmployeeData(id) {
      let employeeData = {};
      let data = _.find(this.employees, ["id", id]);
      if (data) {
        employeeData = data;
      }
      return employeeData;
    },

    async getBranchClientData(){
      this.clientData = new Array();

      let clientRevenueData = null;

      let token = null;
      await firebase.auth().currentUser.getIdToken(true)
      .then(idToken=> { token = idToken })

      let data = JSON.stringify({
        token: token,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        year: this.clientDataFilter.year,
        periodType: this.clientDataFilter.type,
        periodNumber : this.clientDataFilter.number
      })
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getClientsRevenue`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await axios(config) // Get employeeRevenueData
      .then(response=> {
        clientRevenueData = response.data
      })
  
      if(clientRevenueData && clientRevenueData.length > 0){
        for (let i = 0; i < clientRevenueData.length; i++) {
          let data = clientRevenueData[i];
          data.clientName = await this.getClientData(clientRevenueData[i].clientId);
          this.clientData.push(data)
        }
      }
     
      this.dataLoading = false;
    },

    async getClientData(clientId){
      let clientName = null;
      await clientIndex.search(clientId,{ // Get client Data from Algolia
        attributesToRetrieve: ['name', 'surname'], // Get only this fields
        restrictSearchableAttributes: ['objectID'] // Search only on this fields
      })
      .then(({ hits }) => {
        clientName = hits && hits.length > 0 ? `${hits[0].name} ${hits[0].surname}` : "-";
      });
      return clientName;
    },


    async getBranchRevenueData(){
      let token = null;
      await firebase.auth().currentUser.getIdToken(true)
      .then(idToken=> { token = idToken })

      let data = JSON.stringify({
        token: token,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        date: moment().add(1, 'day').format("YYYY-MM-DD")
      })
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getBranchRevenue`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      let branchRevenueAverageData = [
        {
          name: this.$t('general.clients', this.$store.state.locale),
          data: new Array()
        },
        {
          name: this.$t('reports.clients.noClient', this.$store.state.locale),
          data: new Array()  
        },
      ];

      let branchRevenueData = [
        {
          name: this.$t('general.clients', this.$store.state.locale),
          data: new Array()
        },
        {
          name: this.$t('reports.clients.noClient', this.$store.state.locale),
          data: new Array()  
        },
      ];

      let branchVisitorsData = new Array();
      let branchVisitorsNumber  = new Array();

      let avarageRevenueClientTotal = 0;
      let avarageRevenueNoClientTotal = 0;
      let avarageRevenueClientProducts = 0;
      let avarageRevenueNoClientProducts = 0;
      let avarageRevenueClientTreatments = 0;
      let avarageRevenueNoClientTreatments = 0;

      let totalRevenueClientTotal = 0;
      let totalRevenueNoClientTotal = 0;
      let totalRevenueClientTreatments = 0;
      let totalRevenueNoClientTreatments = 0;
      let totalRevenueClientProducts = 0;
      let totalRevenueNoClientProducts = 0;

      let visitsClients = 0;
      let visitsNoClients = 0;

      this.totalBranchRevenue = 0;
      this.totalBranchVisits = 0;

      await axios(config)
      .then(response=> {
        if(response.data && response.data.length){
          console.log("response.data: ", response.data)
          avarageRevenueClientTotal = response.data[0].clientReceipts ? Number((response.data[0].totalRevenueClient / response.data[0].clientReceipts).toFixed(2)) : 0;
          avarageRevenueNoClientTotal = response.data[0].noClientReceipts ? Number((response.data[0].totalRevenueNoClient / response.data[0].noClientReceipts).toFixed(2)) : 0;
          avarageRevenueClientProducts = response.data[0].clientReceipts ? Number((response.data[0].productsRevenueClient / response.data[0].clientReceipts).toFixed(2)) : 0;
          avarageRevenueNoClientProducts = response.data[0].noClientReceipts ? Number((response.data[0].productsRevenueNoClient / response.data[0].noClientReceipts).toFixed(2)) : 0;
          avarageRevenueClientTreatments = response.data[0].clientReceipts ? Number((response.data[0].treatmentsRevenueClient / response.data[0].clientReceipts).toFixed(2)) : 0;
          avarageRevenueNoClientTreatments = response.data[0].noClientReceipts ? Number((response.data[0].treatmentsRevenueNoClient / response.data[0].noClientReceipts).toFixed(2)) : 0;

          totalRevenueClientTotal = Number((response.data[0].totalRevenueClient).toFixed(2));
          totalRevenueNoClientTotal = Number((response.data[0].totalRevenueNoClient).toFixed(2));
          totalRevenueClientTreatments = (response.data[0].treatmentsRevenueClient).toFixed(2);
          totalRevenueNoClientTreatments = (response.data[0].treatmentsRevenueNoClient).toFixed(2);
          totalRevenueClientProducts = (response.data[0].productsRevenueClient).toFixed(2);
          totalRevenueNoClientProducts = (response.data[0].productsRevenueNoClient).toFixed(2);
          visitsClients = response.data[0].clientReceipts ? Number(response.data[0].clientReceipts) : 0;
          visitsNoClients = response.data[0].noClientReceipts ? Number(response.data[0].noClientReceipts) : 0;
        }       
      })
      .catch(error=>{
        console.error("Error getting Branch Revenue Data", error)
      })
      branchRevenueAverageData[0].data.push(avarageRevenueClientTotal,avarageRevenueClientTreatments,avarageRevenueClientProducts);
      branchRevenueAverageData[1].data.push(avarageRevenueNoClientTotal,avarageRevenueNoClientTreatments,avarageRevenueNoClientProducts);

      branchRevenueData[0].data.push(totalRevenueClientTotal,totalRevenueClientTreatments,totalRevenueClientProducts)
      branchRevenueData[1].data.push(totalRevenueNoClientTotal,totalRevenueNoClientTreatments,totalRevenueNoClientProducts)
      branchVisitorsData.push(totalRevenueClientTotal,totalRevenueNoClientTotal)
      branchVisitorsNumber.push(visitsClients,visitsNoClients)

      this.branchChartOptions = {...this.branchChartOptions, ...{
        xaxis: {
          categories: [this.$t('general.total', this.$store.state.locale),this.$t('general.treatments', this.$store.state.locale),this.$t('general.products', this.$store.state.locale)]
        }
      }}

      this.totalBranchRevenue = totalRevenueClientTotal + totalRevenueNoClientTotal;
      this.totalBranchVisits = visitsClients + visitsNoClients;

      this.branchVisitorsData = _.cloneDeep(branchVisitorsData);
      this.branchVisitorsNumber = _.cloneDeep(branchVisitorsNumber);
      this.branchRevenueAverageData = _.cloneDeep(branchRevenueAverageData);
      this.branchRevenueData = _.cloneDeep(branchRevenueData);
      this.dataLoading = false;
    },

    async getEmployeePersonalPerformanceData(){
      this.dataLoading = true;
      let revenuPerHourWeeks = new Array();
      let employeeData = _.find(this.employees, {'id': this.employeePersonalDataFilter.employee});
      let employeePersonalPerformanceData = [
        {
          name: this.$t('reports.legend.treatments', this.$store.state.locale),
          data: new Array()
        },
        {
          name: this.$t('general.products', this.$store.state.locale),
          data: new Array()  
        },
      ];
      let employeePersonalPerformanceValueData = [
        {
          name: this.$t('reports.table.hourRevenue', this.$store.state.locale),
          data: new Array()
        },
      ];
      let dataCategories = new Array();
      let dataCategoriesValueHour = new Array();
      let token = null;

      await firebase.auth().currentUser.getIdToken(true)
      .then(idToken=> {
        token = idToken
      })

      let data = JSON.stringify({
        token: token,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        employee: this.employeePersonalDataFilter.employee,
        date: moment().format("YYYY-MM-DD")
      })
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getEmployeeRevenue`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      let employeePersonalPerformanceDataBQ = new Array();
      await axios(config)
      .then(response=> {
        employeePersonalPerformanceDataBQ = response.data;
        employeePersonalPerformanceData[0].data = response.data.map(a => a.treatments);
        employeePersonalPerformanceData[1].data = response.data.map(a => a.products);
      })
      .catch(error=>{
        console.error("Error getting Employee Personal Performance Data", error)
      })
      await new Promise((resolve)=>{
        let counter = 0;
        if(employeePersonalPerformanceDataBQ.length > 0){
          employeePersonalPerformanceDataBQ.forEach((week)=>{
            dataCategories.push(["Week " + week.week, moment().year(week.year).isoWeek(week.week).startOf('week').format("D MMM") + " - " + moment().year(week.year).isoWeek(week.week).endOf('week').format("D MMM"), week.year]);
            counter++
            if(counter == employeePersonalPerformanceDataBQ.length){
              this.employeeChartOptions.xaxis.categories = _.cloneDeep(dataCategories);
              resolve();
            }
          })
        }
        else{
          resolve();
        }
      })
      .then(()=>{
        let revenuPerHourWeeksRemoveLength = employeePersonalPerformanceDataBQ.length - 15
        revenuPerHourWeeks = new Array();
        employeePersonalPerformanceDataBQ.forEach((week, index)=>{
          if(index > revenuPerHourWeeksRemoveLength){
            revenuPerHourWeeks.push(week)
          }
        })    
      })

      // Gewerkte uren ophalen en omzet per uur berekenen
      await new Promise((resolve)=>{
        let counter = 0;
        revenuPerHourWeeks.forEach((week)=>{
          let periodStartDate = moment().year(week.year).isoWeek(week.week).startOf('week').format("YYYY-MM-DD");
          let periodEndDate = moment().year(week.year).isoWeek(week.week).endOf('week').format("YYYY-MM-DD");
          let weeks = [week];
          this.getEmployeeWorkedHours(employeeData, weeks, periodStartDate, periodEndDate) // Get employee worked hours
          .then((data)=>{
            if(data.minutes && (week.products || week.treatments)){
              let minutes = data.freeMinutes ? (data.minutes - data.freeMinutes)/60 : data.minutes / 60 
              week.revenueHour = Number(((week.products + week.treatments) / minutes).toFixed(2))
            }
            else{
              week.revenueHour = null; 
            }
            dataCategoriesValueHour.push(["Week " + week.week, moment().year(week.year).isoWeek(week.week).startOf('week').format("D MMM") + " - " + moment().year(week.year).isoWeek(week.week).endOf('week').format("D MMM"), week.year]);
            
            counter++
            if(counter == revenuPerHourWeeks.length){
              resolve()
            }
          })
        })
      })
      employeePersonalPerformanceValueData[0].data = revenuPerHourWeeks.map(a => a.revenueHour);

      this.employeeChartOptions = {...this.employeeChartOptions, ...{
          xaxis: {
            categories: dataCategories
          }
      }}

      this.employeeChartValueOptions = {...this.employeeChartValueOptions, ...{
          xaxis: {
            categories: dataCategoriesValueHour
          }
      }}
      
      this.employeePersonalPerformanceData = _.cloneDeep(employeePersonalPerformanceData);
      this.employeePersonalPerformanceValueData = _.cloneDeep(employeePersonalPerformanceValueData);

      this.dataLoading = false;
    },

    async getEmployeeWorkedHours(employee, weeks, periodStartDate, periodEndDate){

      console.log("getEmployeeWorkedHours: ", employee, weeks, periodStartDate, periodEndDate)
      
      let employeeData = {
        minutes: 0,
        freeMinutes: 0,
        basicScheduleMinutes: 0,
        scheduleExceptions: new Array(),
      };

      let exceptions = new Array();
      let pauses = new Array();

      await new Promise((resolve)=>{ // Get all exceptions in period
        let counter = 0;
        weeks.forEach((week)=>{
          let years = new Object(); 
          if(!years[week.year]){ // Om te voorkomen dat execptions opnieuw geladen worden
            years[week.year] = true;
            db.collection("schedulesExceptions") // Get all exceptions selected year
            .where("companyId", "==", this.$store.state.activeCompany.id)
            .where("year", "==", week.year)
            .where("resourceId", "==", employee.id)
            .where("reason", "==", 'leave')
            .get()
            .then(snap => {
              snap.forEach((doc) => {
                let exception = doc.data();
                exception.id = doc.id;
                exceptions.push(exception);
              });
            })
            .then(()=>{
              counter++
              if(counter == weeks.length){
              resolve()
            }
            })
          }
          else{
            counter++
            if(counter == weeks.length){
              resolve()
            }
          }
        })
      })

      await new Promise((resolve)=>{ // Filter all exceptions in period
        let counter = 0;
        if(exceptions.length > 0){
          exceptions.forEach((exception)=>{
            //  .....[//// FILTER PERIOD ////].....
            //  .........[Exception 1].............  Start & End are in period
            //  ...[Exception 2]...................  End is in period
            //  ....................[Exception 3]..  Start is in period
            //  ..[        Exception 4          ]..  Start & End are outside in period

            if(exception.startdate >= periodStartDate && exception.startdate <= periodEndDate || exception.enddate >= periodStartDate && exception.enddate <= periodEndDate || exception.startdate < periodStartDate && exception.enddate > periodEndDate){
              employeeData.scheduleExceptions.push(exception)
            }
            counter++
            if(counter == exceptions.length){
              resolve()
            }
          })
        }
        else{
          resolve()
        }
      });

      await new Promise((resolve)=>{ // Get all pauses in period
        db.collection("bookingen") 
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("dateBooking", ">=", periodStartDate)
        .where("dateBooking", "<=", periodEndDate)
        .where("resourceId", "==", employee.id)
        .where("type", "==", 'pause')
        .get()
        .then(snap => {
          snap.forEach((doc) => {
            let pause = doc.data();
            pause.id = doc.id;
            pauses.push(pause);
          });
        })
        .then(()=>{
          resolve()
        })
      })

      await new Promise((resolve)=>{ // Filter all pauses in period
        if(pauses && pauses.length > 0){
          pauses.forEach((pause)=>{
            let start = moment(pause.start, 'YYYY-MM-DDTHH:mm');
            let end = moment(pause.end, 'YYYY-MM-DDTHH:mm');
            let minutes = end.diff(start, 'minutes');
            employeeData.freeMinutes = minutes + employee.freeMinutes;
          })
        }

        let weekCounter = 0;
        weeks.forEach(week=>{
          let weekdata = null;
          let weekdataBasicSchedule  = null;
          db.collection("schedules")
          .where("year", "==", week.year)
          .where("employeeId", "==", employee.id)
          .where("week", "==", week.week)
          .get()
          .then(snap => {
            if (snap.size > 0) { // Schedule exception found
              snap.forEach((doc) => {
                weekdata = doc.data().schedule;
                if(doc.data().basicSchedule){
                  weekdataBasicSchedule = doc.data().basicSchedule
                }
                else{
                  weekdataBasicSchedule = employee.basicSchedule && employee.basicSchedule[this.$store.state.activeBranch.id] ? employee.basicSchedule[this.$store.state.activeBranch.id] : null;
                }
              });
            }
            else{
              weekdata = employee.basicSchedule && employee.basicSchedule[this.$store.state.activeBranch.id] ? employee.basicSchedule[this.$store.state.activeBranch.id] : null;
              weekdataBasicSchedule = employee.basicSchedule && employee.basicSchedule[this.$store.state.activeBranch.id] ? employee.basicSchedule[this.$store.state.activeBranch.id] : null;
            }
          })
          .then(()=>{
            if(weekdata){
              let weekDays = {
                Mon: 1,
                Tue: 2,
                Wed: 3,
                Thu: 4,
                Fri: 5,
                Sat: 6,
                Sun: 7,
              };

              let weekDaysReversed = {
                1: 'Mon',
                2: 'Tue',
                3: 'Wed',
                4: 'Thu',
                5: 'Fri',
                6: 'Sat',
                7: 'Sun'
              };

              // Pauze eraf trekken
              if(employeeData.scheduleExceptions && employeeData.scheduleExceptions.length > 0){
                employeeData.scheduleExceptions.forEach(exception=>{
                  let startOfWeekSchedule = moment().isoWeek(week).startOf('week').format("YYYY-MM-DD");
                  let endOfWeekSchedule = moment().isoWeek(week).endOf('week').format("YYYY-MM-DD");

                  if(exception.startdate >= startOfWeekSchedule && exception.startdate <= endOfWeekSchedule || exception.enddate >= startOfWeekSchedule && exception.enddate <= endOfWeekSchedule || exception.startdate < startOfWeekSchedule && exception.enddate > endOfWeekSchedule){
                    if(exception.startdate == exception.enddate){ // Is één dag of dagdelen
                      if(exception.time && exception.time.length > 0){ // Is in dagdelen
                        exception.time.forEach(time=>{ // Vrij periode per dag berkenen als vrije tijd
                          let start = moment(time.start, 'HH:mm');
                          let end = moment(time.end, 'HH:mm');
                          let minutes = end.diff(start, 'minutes');
                          employeeData.freeMinutes = minutes + employeeData.freeMinutes;
                        })
                      }
                      else{ // Is gehele dag
                        let exceptionDay = moment(exception.startdate).isoWeekday() // Welke dag is het
                        weekdata[weekDaysReversed[exceptionDay]].forEach(day=>{ // Normale werktijden van deze dag ophalen en berekenen als vrije tijd
                          let start = moment(day.start, 'HH:mm');
                          let end = moment(day.end, 'HH:mm');
                          let minutes = end.diff(start, 'minutes');
                          employeeData.freeMinutes = minutes + employeeData.freeMinutes;
                        })
                      }
                    }
                    else{ // Is een periode
                      const range = Moment.range(moment(exception.startdate).format("YYYY-MM-DD"), moment(exception.enddate).format("YYYY-MM-DD"));
                      let rangeDaysValid = new Array()
                      let rangeDays = Array.from(range.by('day')).map(m => m.format('YYYY-MM-DD')) // Array met dagen in deze periode

                      rangeDays.forEach((exceptionRangeDay)=>{  // Welke dagen van deze vrije periode vallen in deze week?
                        if(exceptionRangeDay >= startOfWeekSchedule && exceptionRangeDay <= endOfWeekSchedule){
                          rangeDaysValid.push(exceptionRangeDay)
                        }
                      })
                      
                      if(exception.time && exception.time.length > 0){ // Is in dagdelen  gedurende de periode
                        rangeDaysValid.forEach((exceptionRangeDay)=>{ // Vrij periode per dag berkenen als vrije tijd
                          exception.time.forEach(time=>{
                            let start = moment(time.start, 'HH:mm');
                            let end = moment(time.end, 'HH:mm');
                            let minutes = end.diff(start, 'minutes');
                            employeeData.freeMinutes = minutes + employeeData.freeMinutes;
                          })
                        })
                      }
                      else{ // Is gehele dag gedurende de periode
                        rangeDaysValid.forEach((exceptionRangeDay)=>{
                          let exceptionDay = moment(exceptionRangeDay).isoWeekday() // Welke dag is het
                          weekdata[weekDaysReversed[exceptionDay]].forEach(day=>{ // Normale werktijden van deze dag ophalen en berekenen als vrije tijd
                            let start = moment(day.start, 'HH:mm');
                            let end = moment(day.end, 'HH:mm');
                            let minutes = end.diff(start, 'minutes');
                            employeeData.freeMinutes = minutes + employeeData.freeMinutes;
                          })
                        })
                      }
                    }

                  }
                })
              }

              for (const [key, value] of Object.entries(weekdata)) {
                if(key == 'Mon' || key == 'Tue' || key == 'Wed' || key == 'Thu' || key == 'Fri' || key == 'Sat' || key == 'Sun'){
                  if(weekdata[key] 
                    && weekdata[key].length > 0 
                    && (moment().day(weekDays[key]).year(week.year).isoWeek(week.week).format("YYYY-MM-DD") >= periodStartDate 
                    && moment().day(weekDays[key]).year(week.year).isoWeek(week.week).format("YYYY-MM-DD") <= periodEndDate) || (!periodEndDate && !periodStartDate)){
                      weekdata[key].forEach(day=>{
                        let start = moment(day.start, 'HH:mm');
                        let end = moment(day.end, 'HH:mm');
                        let minutes = end.diff(start, 'minutes');
                        employeeData.minutes = minutes + employeeData.minutes;
                      })
                  }
                }
              }

              for (const [key] of Object.entries(weekdataBasicSchedule)) { // Oorspronkelijke basisrooster berekenen
                if(key == 'Mon' || key == 'Tue' || key == 'Wed' || key == 'Thu' || key == 'Fri' || key == 'Sat' || key == 'Sun'){
                  if(weekdataBasicSchedule[key] 
                    && weekdataBasicSchedule[key].length > 0 
                    && (moment().day(weekDays[key]).year(week.year).isoWeek(week.week).format("YYYY-MM-DD") >= periodStartDate 
                    && moment().day(weekDays[key]).year(week.year).isoWeek(week.week).format("YYYY-MM-DD") <= periodEndDate) || (!periodEndDate && !periodStartDate)){
                      weekdataBasicSchedule[key].forEach(day=>{
                        let basicScheduleStart = moment(day.start, 'HH:mm');
                        let basicScheduleEnd = moment(day.end, 'HH:mm');
                        let basicScheduleMinutes = basicScheduleEnd.diff(basicScheduleStart, 'minutes');
                        employeeData.basicScheduleMinutes = basicScheduleMinutes + employeeData.basicScheduleMinutes;
                      })
                  }
                }
              }
            }
          })
          .then(()=>{
            weekCounter++
            if(weekCounter == weeks.length){
              resolve()
            }
          });
        });   
      })
      return employeeData
    },
 
    getEmployeeProductivityData(){
      this.dataLoading = true;
      return firebase.auth().currentUser.getIdToken(true)
      .then(idToken=> {
        const employeeProductivityRevenue = new Promise((resolve) =>{this.employeeProductivityRevenue(idToken).then(()=>{ resolve()})});
        // const employeeProductivityTime = new Promise((resolve) =>{this.employeeProductivityTime(idToken).then(()=>{ resolve()})});

        Promise.all([employeeProductivityRevenue]).then(() => {
          this.employeeProductivityDataFilter.loaded = true;
          this.dataLoading = false;
        });
      })
    },

    async employeeProductivityRevenue(idToken){
      let data = JSON.stringify({
        token: idToken,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        year: this.employeeProductivityDataFilter.year,
        periodType: this.employeeProductivityDataFilter.type,
        periodNumber : this.employeeProductivityDataFilter.number
      })

      let employeeRevenueData = null;

      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getProductivityData`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await axios(config) // Get employeeRevenueData
        .then(response=> {
          employeeRevenueData = response.data
        })

      let periodStartDate = null;
      let periodEndDate = null;
      let weeks = new Array();

      if(this.employeeProductivityDataFilter.type == 'week'){
        weeks.push({week: this.employeeProductivityDataFilter.number, year: this.employeeProductivityDataFilter.year})
        let startWeek = moment().isoWeek(this.employeeProductivityDataFilter.number).startOf('week').format("YYYY-MM-DD");
        let endWeek = moment().isoWeek(this.employeeProductivityDataFilter.number).endOf('week').format("YYYY-MM-DD");
        periodStartDate = startWeek;
        periodEndDate = endWeek;
      }
      else if(this.employeeProductivityDataFilter.type == 'fourWeeks'){
        weeks.push({week:(this.employeeProductivityDataFilter.number*4) - 3, year: this.employeeProductivityDataFilter.year} )
        weeks.push({week:(this.employeeProductivityDataFilter.number*4) - 2, year: this.employeeProductivityDataFilter.year})
        weeks.push({week:(this.employeeProductivityDataFilter.number*4) - 1, year: this.employeeProductivityDataFilter.year})
        weeks.push({week:(this.employeeProductivityDataFilter.number*4), year: this.employeeProductivityDataFilter.year})

        let startFourWeek = moment().isoWeek(this.employeeProductivityDataFilter.number * 4).startOf('week').format("YYYY-MM-DD");
        let endFourWeek = moment().isoWeek((this.employeeProductivityDataFilter.number *4 )-3).endOf('week').format("YYYY-MM-DD");
        periodStartDate = startFourWeek;
        periodEndDate = endFourWeek;
      }
      else if(this.employeeProductivityDataFilter.type == 'month'){
        let startMonth = moment(`${this.employeeProductivityDataFilter.year}-${this.employeeProductivityDataFilter.number}-01` , "YYYY-M-D").format("YYYY-MM-DD");
        let endMonth = moment(startMonth).endOf('month').format("YYYY-MM-DD");
        periodStartDate = startMonth;
        periodEndDate = endMonth;
        let startMonthWeek = moment(startMonth).isoWeek();
        let endMonthWeekBefore = moment(moment(endMonth).subtract(7, 'days')).isoWeek();
        let endMonthWeek = moment(endMonth).isoWeek();
        let numberOfweeks = null;
        let counter = null;
        if(startMonthWeek < endMonthWeek){
          counter = startMonthWeek;
          numberOfweeks = (endMonthWeek - startMonthWeek) + 1;
        }
        else{
          if(this.employeeProductivityDataFilter.number == 1){ // Eerst maand
            numberOfweeks = (endMonthWeek - 1) + 1; // Als eerste week van het jaar week 53 of 52 is.
            counter = 1;
          }
          else{
            numberOfweeks = (endMonthWeekBefore - startMonthWeek) + 1; // Als de laatste week van het jaar week 1 is.
            counter = startMonthWeek;
          }
        }

        let weeksArray = Array(numberOfweeks).fill(0);
        weeksArray.forEach(()=>{
          weeks.push({week:counter, year: this.employeeProductivityDataFilter.year})
          counter++
        })
      }
      else if(this.employeeProductivityDataFilter.type == 'quarter'){
        let startQuarter = moment(`${this.employeeProductivityDataFilter.year}`, "YYYY").quarter(this.employeeProductivityDataFilter.number).startOf('quarter').format("YYYY-MM-DD");
        let endQuarter = moment(startQuarter).endOf('quarter').format("YYYY-MM-DD");
        periodStartDate = startQuarter;
        periodEndDate = endQuarter;
        let startQuarterWeek = moment(startQuarter).isoWeek();
        let endQuarterWeekBefore = moment(moment(endQuarter).subtract(7, 'days')).isoWeek();
        let endQuarterWeek = moment(endQuarter).isoWeek();
        let numberOfweeks = null;
        let counter = null;
        if(startQuarterWeek < endQuarterWeek){
          counter = startQuarterWeek;
          numberOfweeks = (endQuarterWeek - startQuarterWeek) + 1;
        }
        else{
          if(this.employeeProductivityDataFilter.number == 1){ // Eerst maand
            numberOfweeks = (endQuarterWeek - 1) + 1; // Als eerste week van het jaar week 53 of 52 is.
            counter = 1;
          }
          else{
            numberOfweeks = (endQuarterWeekBefore - startQuarterWeek) + 1; // Als de laatste week van het jaar week 1 is.
            counter = startQuarterWeek;
          }
        }

        let weeksArray = Array(numberOfweeks).fill(0);
        weeksArray.forEach(()=>{
          weeks.push({week:counter, year: this.employeeProductivityDataFilter.year})
          counter++
        })
      }
      else if(this.employeeProductivityDataFilter.type == 'year'){
        let startYear = moment(`${this.employeeProductivityDataFilter.year}`, "YYYY").startOf('year').format("YYYY-MM-DD");
        let endYear = moment(startYear).endOf('year').format("YYYY-MM-DD");
        periodStartDate = startYear;
        periodEndDate = startYear;
        let startYearWeek = 1;
        let endYearWeekBefore = moment(moment(endYear).subtract(7, 'days')).isoWeek();
        let endYearWeek = moment(endYear).isoWeek();
        let numberOfweeks = null;
        let counter = null;
        if(startYearWeek <= endYearWeek){
          counter = startYearWeek;
          numberOfweeks = (endYearWeek - startYearWeek) + 1;
        }
        else{
          numberOfweeks = (endYearWeekBefore - startYearWeek) + 1; // Als de laatste week van het jaar week 1 is.
          counter = startYearWeek;
        }

        let weeksArray = Array(numberOfweeks).fill(0);
        weeksArray.forEach(()=>{
          weeks.push({week:counter, year: this.employeeProductivityDataFilter})
          counter++
        })
      }

      const configAxiosPOST = {
        headers: {"Content-Type": "application/json"},
      };

      let productivityData = JSON.stringify({
        company: this.$store.state.activeCompany.id,
        branch: [this.$store.state.activeBranch.id],
        start: periodStartDate,
        end: periodEndDate < moment().format("YYYY-MM-DD") ? periodEndDate : moment().format("YYYY-MM-DD"),
        employee: "0",
        timezone: "Europe/Amsterdam"
      })

      let employeeProductivityData = null;

      await axios.post(`https://backend.thrive365.nl/employeeTime`, productivityData, configAxiosPOST)
      .then(response=> {
        employeeProductivityData = response.data
      })
      .catch(error=> {
        console.error("Get EmployeeTime Error: ", error.response.data)
      })

      this.employeeProductivityData.time = new Array();
      this.employeeProductivityData.revenue = new Array();

      await new Promise((resolve)=>{ // Get employee worked hours and calculate average time ,revenue, and value
        let counter = 0;
        this.employees.forEach(employee=>{
          if(employee.id){

            let workedMinutes = _.find(employeeProductivityData.hours, {resourceId: employee.id}) ? _.find(employeeProductivityData.hours, {resourceId: employee.id}).workingMinutes : 0;

            employee.revenue = _.find(employeeRevenueData, {employee: employee.id}) ? _.find(employeeRevenueData, {employee: employee.id}).revenue : 0;
            employee.value = _.find(employeeRevenueData, {employee: employee.id}) ? _.find(employeeRevenueData, {employee: employee.id}).value : 0;

            this.getEmployeeWorkedHours(employee, weeks, periodStartDate, periodEndDate) // Get employee worked hour
            .then((data)=>{

              employee.minutes = data.minutes;
              employee.freeMinutes = data.freeMinutes;
              employee.basicScheduleMinutes = data.basicScheduleMinutes;
              employee.scheduleExceptions = data.scheduleExceptions;

              let employeeDataTime = {
                name: employee.name,
                productivity: workedMinutes ? ((workedMinutes / (employee.minutes - employee.freeMinutes))*100).toFixed(1): 0,
                basicScheduleMinutes: employee.basicScheduleMinutes, 
                presencePrecentage:  employee.minutes && employee.basicScheduleMinutes ?  (((employee.minutes - employee.freeMinutes) / employee.basicScheduleMinutes) *100).toFixed(0) : 0,
                presenceTime: ((employee.minutes - employee.freeMinutes) / 60).toFixed(1),
                fte: (((employee.minutes - employee.freeMinutes) / 60)/(((38*52)/365)*((moment(periodEndDate).diff(moment(periodStartDate), 'days'))+1))).toFixed(2)
              };

              let employeeDataRevenue = {
                name: employee.name,
                productivity: employee.revenue ? ((employee.revenue / ((employee.minutes - employee.freeMinutes) / 60)/ 60)*100).toFixed(0): 0,
                valueHour: employee.value ? (employee.value/ ((employee.minutes - employee.freeMinutes) / 60)).toFixed(2): 0,
                value:  employee.value ? (employee.value).toFixed(2) : 0,
                revenueHour:  employee.revenue ? (employee.revenue/ ((employee.minutes - employee.freeMinutes) / 60)).toFixed(2): 0,
                revenue: employee.revenue ? (employee.revenue).toFixed(2): 0
              };

              this.employeeProductivityData.time.push(employeeDataTime);
              this.employeeProductivityData.revenue.push(employeeDataRevenue);
              

              counter++
              if(counter == this.employees.length){
                resolve()
              }
            })

          }
          else{
            counter++
            if(counter == this.employees.length){
              resolve()
            }
          }
        })
      })
      return;
    },

    async getReceiptNumbersData(){
      this.dataLoading = true;
      const idToken = await firebase.auth().currentUser.getIdToken(true)
        
      const receiptNumberDataQuarter = new Promise((resolve) =>{this.getReceiptNumberData(idToken, 'receiptData', 'quarter').then(()=>{ resolve()})});
      const receiptNumberDataMonth = new Promise((resolve) =>{this.getReceiptNumberData(idToken, 'receiptData', 'month').then(()=>{ resolve()})});
      const receiptNumberDataFourWeeks = new Promise((resolve) =>{this.getReceiptNumberData(idToken,'receiptData', 'fourWeeks').then(()=>{ resolve()})});
      const receiptNumberDataWeek = new Promise((resolve) =>{this.getReceiptNumberData(idToken, 'receiptData', 'week').then(()=>{ resolve()})});

      await Promise.all([receiptNumberDataQuarter, receiptNumberDataMonth, receiptNumberDataFourWeeks, receiptNumberDataWeek])
      this.dataLoading = false;
    },

    /**
     * @param {String} idToken - Firebase token to confirm authenticated user 
     * @param {String} dataVariable - name of variable in data (to use function for more purposes)
     * @param {String} type - Period type (week, month etc.)
     */
    async getReceiptNumberData(idToken, dataVariable, type){
      let data = JSON.stringify({
        token: idToken,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        year: this[`${dataVariable}Filter`].year,
        periodType: type,
        user: dataVariable == 'receiptData' ? this[`${dataVariable}Filter`].user : this[`${dataVariable}Filter`].user ? this[`${dataVariable}Filter`].user.userId : null
      })
      console.log("data: ", data)
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getReceipts`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await axios(config)
      .then(response=> {
        this[dataVariable][type] = response.data
      })
      return 
    },

    // receiptAppointmentDataFilter: {
    //     year: moment().year(),
    //     user: null,
    //     loaded: false
    //   },
    //   receiptListDataFilter: {
    //     type: "week",
    //     year: moment().year(),
    //     number: moment().isoWeek(),
    //     loaded: false
    //   },
    //   openReceiptDataFilter: {
    //     type: "week",
    //     year: moment().year(),
    //     number: moment().isoWeek(),
    //     loaded: false
    //   },

    async getRegisterStatusData(){
      this.dataLoading = true;

      return firebase.auth().currentUser.getIdToken(true)
      .then(idToken=> {
        const registerVatData = new Promise((resolve) =>{this.getRegisterVatData(idToken).then(()=>{ resolve()})});
        const registerCashUpData = new Promise((resolve) =>{this.getRegisterCashUpData(idToken).then(()=>{ resolve()})});
        const registerExpensesData = new Promise((resolve) =>{this.getRegisterExpensesData(idToken).then(()=>{ resolve()})});

        Promise.all([registerVatData, registerCashUpData, registerExpensesData]).then(() => {
          this.registerDataFilter.loaded = true;
          this.dataLoading = false;
        });
      })
    },

    async getReceiptAppointmentData(){
      this.dataLoading = true;
      this.receiptAppointmentData = {
        quarter: new Array(),
        month: new Array(),
        fourWeeks: new Array(),
        week: new Array()
      };

      const idToken = await firebase.auth().currentUser.getIdToken(true)
        
      const receiptNumberDataQuarter = new Promise((resolve) =>{this.getReceiptNumberData(idToken, 'receiptAppointmentData', 'quarter').then(()=>{ resolve()})});
      const receiptNumberDataMonth = new Promise((resolve) =>{this.getReceiptNumberData(idToken, 'receiptAppointmentData', 'month').then(()=>{ resolve()})});
      const receiptNumberDataFourWeeks = new Promise((resolve) =>{this.getReceiptNumberData(idToken,'receiptAppointmentData', 'fourWeeks').then(()=>{ resolve()})});
      const receiptNumberDataWeek = new Promise((resolve) =>{this.getReceiptNumberData(idToken, 'receiptAppointmentData', 'week').then(()=>{ resolve()})});

      await Promise.all([receiptNumberDataQuarter, receiptNumberDataMonth, receiptNumberDataFourWeeks, receiptNumberDataWeek])

      let startDateYear = moment(this.receiptAppointmentDataFilter.year,'YYYY').startOf('year').format("YYYY-MM-DD");
      let endDateYear = moment(this.receiptAppointmentDataFilter.year,'YYYY').endOf('year').format("YYYY-MM-DD");
      let endDateFilter = moment().format("YYYY-MM-DD") < endDateYear ? moment().format("YYYY-MM-DD") : endDateYear ;
      console.log("startDateYear: ", startDateYear)
      console.log("endDateYear: ", endDateYear)
      console.log("endDateFilter: ", endDateFilter)
      let activeMonthsInYearNumber = moment(endDateFilter).diff(startDateYear, 'months', false) + 1;
      let activeWeeksInYearNumber = moment(endDateFilter).diff(startDateYear, 'weeks', false) + 1;


      //let activeMonthsInYear = Array.apply(new Object(), Array(activeMonthsInYearNumber));
      let activeQuartersInYear = [...new Array(Math.ceil(activeMonthsInYearNumber/3))].map((x,i)=> ({'quarter': i + 1, 'appointments' : 0, 'treatments' : 0, 'payed' : 0}));
      let activeMonthsInYear = [...new Array(activeMonthsInYearNumber)].map((x,i)=> ({'month': i + 1, 'appointments' : 0, 'treatments' : 0, 'payed' : 0}));
      let activeFourWeeksInYear = [...new Array(Math.ceil(activeWeeksInYearNumber/4) < 13 ? Math.ceil(activeWeeksInYearNumber/4) : 13)].map((x,i)=> ({'fourWeeks': i, 'appointments' : 0, 'treatments' : 0, 'payed' : 0}));
      let activeWeeksInYear = [...new Array(activeWeeksInYearNumber)].map((x,i)=> ({'week': i + 1, 'appointments' : 0, 'treatments' : 0, 'payed' : 0}));

      let filterEmployee = {}
          filterEmployee.field =  this.receiptAppointmentDataFilter.user ? "employees" : "companyId";
          filterEmployee.filter = this.receiptAppointmentDataFilter.user ? "array-contains" : "==";
          filterEmployee.employee = this.receiptAppointmentDataFilter.user ?  this.receiptAppointmentDataFilter.user.id : this.$store.state.activeCompany.id;

      await new Promise((resolve)=>{
        let monthsCounter = 0;

        // Count for Quarters
        let totalTreatments = 0;
        let totalAppointments = 0;
        let totalPayed = 0;
        activeMonthsInYear.forEach((month, index)=>{
          let periodStartDate = moment(month.month , "M").year(this.receiptAppointmentDataFilter.year).startOf('month').format("YYYY-MM-DD");
          let periodEndDate = moment(month.month , "M").year(this.receiptAppointmentDataFilter.year).endOf('month').format("YYYY-MM-DD");
          month.treatments = this.receiptAppointmentData.month.find((item)=> item.month == month.month) ? this.receiptAppointmentData.month.find((item)=> item.month == month.month).with_treatment : 0
          totalTreatments = this.receiptAppointmentData.month.find((item)=> item.month == month.month) ? this.receiptAppointmentData.month.find((item)=> item.month == month.month).with_treatment + totalTreatments : totalTreatments
          
        
          db.collection("bookingsummary") 
          // .where("companyId", "==", this.$store.state.activeCompany.id)
          .where(filterEmployee.field, filterEmployee.filter, filterEmployee.employee)
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .where("visit", ">=", periodStartDate)
          .where("visit", "<=", periodEndDate)
          .where("type", "==", 'booking')
          .where("deleted", "==", false)
          .get()
          .then(snap => {
            month.appointments = snap.size; // Later omwisselen met count
            totalAppointments = totalAppointments + snap.size
            month.payed =  month.appointments ? (month.treatments / month.appointments) * 100 : month.treatments * 100;
            monthsCounter++
            // Update Quarter Numbers
            if(Number.isInteger(month.month / 3) || monthsCounter == activeMonthsInYear.length){
              console.log("find quarter: ", Math.ceil(month.month / 3))
              let quarter = activeQuartersInYear.find(item=> item.quarter == (Math.ceil(month.month / 3)));
              console.log("quarter: ", quarter)
              if(quarter){
                quarter.treatments = totalTreatments;
                quarter.appointments = totalAppointments;
                quarter.payed = totalAppointments ? (totalAppointments / totalAppointments) * 100 : totalAppointments * 100;
              }
              
              totalTreatments = 0;
              totalAppointments = 0;
              totalPayed = 0;
            }
            
            if(monthsCounter == activeMonthsInYear.length){
              resolve()
            }
          });
        })
      })

      await new Promise((resolve)=>{
        let weekCounter = 0;

        // Count for weeks
        let totalTreatments = 0;
        let totalAppointments = 0;
        let totalPayed = 0;
        activeWeeksInYear.forEach((week, index)=>{
          let periodStartDate = moment().isoWeek(week.week).year(this.receiptAppointmentDataFilter.year).startOf('week').format("YYYY-MM-DD");
          let periodEndDate = moment().isoWeek(week.week).year(this.receiptAppointmentDataFilter.year).endOf('week').format("YYYY-MM-DD");
          week.treatments = this.receiptAppointmentData.week.find((item)=> item.week == week.week) ? this.receiptAppointmentData.week.find((item)=> item.week == week.week).with_treatment : 0;
          totalTreatments = this.receiptAppointmentData.week.find((item)=> item.week == week.week) ? this.receiptAppointmentData.week.find((item)=> item.week == week.week).with_treatment + totalTreatments : totalTreatments
          db.collection("bookingsummary") 
          //.where("companyId", "==", this.$store.state.activeCompany.id)
          .where(filterEmployee.field, filterEmployee.filter, filterEmployee.employee)
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .where("visit", ">=", periodStartDate)
          .where("visit", "<=", periodEndDate)
          .where("type", "==", 'booking')
          .where("deleted", "==", false)
          .get()
          .then(snap => {
            week.appointments = snap.size; // Later omwisselen met count
            totalAppointments = totalAppointments + snap.size
            week.payed =  week.appointments ? (week.treatments / week.appointments) * 100 : week.treatments * 100;
            weekCounter++
             // Update FourWeeks Numbers
             if(Number.isInteger(week.week / 4) || weekCounter == activeWeeksInYear.length){
              let fourWeek = activeFourWeeksInYear.find(item=> item.fourWeeks == (Math.ceil(week.week / 4)));
              if(fourWeek){
                fourWeek.treatments = totalTreatments;
                fourWeek.appointments = totalAppointments;
                fourWeek.payed = totalAppointments ? (totalAppointments / totalAppointments) * 100 : totalAppointments * 100;
              }
              
              totalTreatments = 0;
              totalAppointments = 0;
              totalPayed = 0;
            }
            if(weekCounter == activeMonthsInYear.length){
              resolve()
            }
          });
        })
      })
      
      console.log("activeQuartersInYear: ", activeQuartersInYear)
      console.log("activeMonthsInYear: ", activeMonthsInYear)
      console.log("activeFourWeeksInYear: ", activeFourWeeksInYear)
      console.log("activeWeeksInYear: ", activeWeeksInYear)

      this.receiptAppointmentData.quarter = activeQuartersInYear
      this.receiptAppointmentData.month = activeMonthsInYear
      this.receiptAppointmentData.fourWeeks = activeFourWeeksInYear
      this.receiptAppointmentData.week =activeWeeksInYear
      // treatments
      // appointments
      // payed

      this.dataLoading = false;
    },

    getReceiptAppointmentTotal(type, key){
      let total = 0;
      if(this.receiptAppointmentData[type].length > 0){
        this.receiptAppointmentData[type].forEach(item=>{
          total = item[key] + total
        })
      }
      return total;
    },

    getOpenReceiptTotal( key){
      let total = 0;
      if(this.openReceiptData.length > 0){
        this.openReceiptData.forEach(item=>{
          total = item[key] + total
        })
      }
      return total;
    },

    getReceiptListTotal(type, key){
      let total = 0;
      if(this.receiptListData[type].length > 0){
        this.receiptListData[type].forEach(item=>{
          total = item[key] + total
        })
      }
      return total;
    },

    getReceiptListItems(){
      let items = 0;
      for (const [key, value] of Object.entries(this.receiptListData)) {
        items = items + value.length
      }
      return items
    },


    async getReceiptListData(){
      this.dataLoading = true;
      this.receiptListData = {
        sunday: new Array(),
        monday: new Array(),
        tuesday: new Array(),
        wednesday: new Array(),
        thursday: new Array(),
        friday: new Array(),
        saturday: new Array(),
      };
      let startDate = moment().isoWeek(this.receiptListDataFilter.number).year(this.receiptListDataFilter.year,'YYYY').startOf('week').format("YYYY-MM-DD");
      let endDate = moment().isoWeek(this.receiptListDataFilter.number).year(this.receiptListDataFilter.year,'YYYY').endOf('week').format("YYYY-MM-DD");

      await db.collection("receiptsPaid") 
      .where("branchId", "==", this.$store.state.activeBranch.id)
      .where("visit", ">=", startDate)
      .where("visit", "<=", endDate)
      .get()
      .then(snap => {
        snap.forEach((doc) => {
          let day = (moment(doc.data().created.seconds, "X").locale('en').format("dddd")).toLowerCase();
          console.log("item: ", day)
          let receipt = doc.data();
          receipt.created = doc.data().created ? moment(doc.data().created.seconds, "X").format("DD-MM-YYYY - HH:mm") : null;
          receipt.id = doc.id;
          receipt.userId = doc.data().paymentTableObj.transactionClosedBy.userId;
          receipt.price = doc.data().paymentTableObj.totalBruto;
          receipt.payedBy = doc.data().paymentTableObj.payedBy;

          receipt.downPayment = doc.data().usedModifiers;

          this.receiptListData[day].push(receipt);
        })
      });
      this.dataLoading = false;
    },

    async getReceiptOpenData(){
      this.dataLoading = true;
      this.openReceiptData = new Array();
      let startDateYear = moment(this.receiptAppointmentDataFilter.year,'YYYY').startOf('year').format("YYYY-MM-DD");
      let endDateYear = moment(this.receiptAppointmentDataFilter.year,'YYYY').endOf('year').format("YYYY-MM-DD");
      let startDate = null;
      let endDate = null;
      if(this.openReceiptDataFilter.type == 'week'){
        startDate = moment().isoWeek(this.openReceiptDataFilter.number).year(this.openReceiptDataFilter.year,'YYYY').startOf('week').format("YYYY-MM-DD");
        endDate = moment().isoWeek(this.openReceiptDataFilter.number).year(this.openReceiptDataFilter.year,'YYYY').endOf('week').format("YYYY-MM-DD");
      }
      else if(this.openReceiptDataFilter.type == 'fourWeeks'){
        let startWeekNumber = (this.openReceiptDataFilter.number * 4) - 3;
        let endWeekNumber = this.openReceiptDataFilter.number * 4;
        startDate = moment().isoWeek(startWeekNumber).year(this.openReceiptDataFilter.year,'YYYY').startOf('week').format("YYYY-MM-DD");
        endDate = moment().isoWeek(endWeekNumber).year(this.openReceiptDataFilter.year,'YYYY').endOf('week').format("YYYY-MM-DD");
      }
      else if(this.openReceiptDataFilter.type == 'month'){
        startDate = moment().month(this.openReceiptDataFilter.number).year(this.openReceiptDataFilter.year,'YYYY').startOf('month').format("YYYY-MM-DD");
        endDate = moment().month(this.openReceiptDataFilter.number).year(this.openReceiptDataFilter.year,'YYYY').endOf('month').format("YYYY-MM-DD");
      }
      else if(this.openReceiptDataFilter.type == 'quarter'){
        startDate = moment().quarter(this.openReceiptDataFilter.number).year(this.openReceiptDataFilter.year,'YYYY').startOf('quarter').format("YYYY-MM-DD");
        endDate = moment().quarter(this.openReceiptDataFilter.number).year(this.openReceiptDataFilter.year,'YYYY').endOf('quarter').format("YYYY-MM-DD");
      }
      else if(this.openReceiptDataFilter.type == 'year'){
        startDate = moment().year(this.openReceiptDataFilter.year,'YYYY').startOf('year').format("YYYY-MM-DD");
        endDate = moment().year(this.openReceiptDataFilter.year,'YYYY').endOf('year').format("YYYY-MM-DD");
      }
       
      this.openReceiptData= await this.getReceiptDrafts(startDate, endDate)
      
      this.dataLoading = false;
    },

    async getReceiptDrafts(start, end){
      console.log("getReceiptDrafts start - end", start, " - ", end)
      let receipts = new Array();
      await db.collection("receiptsDraft") 
      .where("branchId", "==", this.$store.state.activeBranch.id)
      .where("visit", ">=", start)
      .where("visit", "<=", end)
      .get()
      .then(snap => {
        snap.forEach((doc) => {
          let receipt = doc.data();
          receipt.visit = moment(doc.data().visit).format("DD-MM-YYYY");
          receipt.modified = doc.data().modified ? moment(doc.data().modified.seconds, "X").format("DD-MM-YYYY - HH:mm") : null;
          receipt.id = doc.id;
          if(doc.data().selectedItems && doc.data().selectedItems.length > 0){
            let price = 0;
            doc.data().selectedItems.forEach(item=>{
              price = item.newPriceTotal ? price + item.newPriceTotal : price;
            })
            receipt.price = price;
            receipts.push(receipt);
          }
          
        });
      })
      return receipts;
    },

    cashUpPrev(){
      this.cashUpDataFilter.day = moment(this.cashUpDataFilter.day).subtract(1, 'day').format("YYYY-MM-DD");
      this.getCashUpData();
    },

    cashUpNext(){
      this.cashUpDataFilter.day = moment(this.cashUpDataFilter.day).add(1, 'day').format("YYYY-MM-DD");
      this.getCashUpData();
    },

    async getCashUpData(){ 
      console.log("Response Cashup 1")
      this.dataLoading = true;

      let data = {
          token: null,
          companyId: this.$store.state.activeCompany.id,
          branchId: this.$store.state.activeBranch.id,
          day: this.cashUpDataFilter.day,
      }

      await firebase.auth().currentUser.getIdToken(true)
      .then(idToken=> {
        data.token = idToken
      })

      console.log("token klaar")

      const config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getDayCashUp`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : JSON.stringify(data)
      };

      console.log("start axios")

      await axios(config)
        .then(response=> {
          console.log("Response Cashup", response.data)
          this.cashUpData = response.data ? response.data : null;
          
          if(this.cashUpData){
            this.calculateCashData();
          }
          
        })
        .catch(error=>{
            console.error("error getting cashup data: ", data)
        })
        console.log("Response Cashup klaar")
        this.dataLoading = false;
    },

    calculateCashData(){
      this.cashUpData.forEach(cashUpData => {
        let cashData = new Array();

        if(cashUpData.oneEuroCent){ cashData.push({type: 'cash', number: cashUpData.oneEuroCent, value: '0.01', total: (cashUpData.oneEuroCent * 0.01).toFixed(2)})}
        if(cashUpData.twoEuroCent){ cashData.push({type: 'cash', number: cashUpData.twoEuroCent, value: '0.02', total: (cashUpData.twoEuroCent * 0.02).toFixed(2)})}
        if(cashUpData.fiveEuroCent){ cashData.push({type: 'cash', number: cashUpData.fiveEuroCent, value: '0.05', total: (cashUpData.fiveEuroCent * 0.05).toFixed(2)})}
        if(cashUpData.tenEuroCent){ cashData.push({type: 'cash', number: cashUpData.tenEuroCent, value: '0.10', total: (cashUpData.tenEuroCent * 0.1).toFixed(2)})}
        if(cashUpData.twentyEuroCent){ cashData.push({type: 'cash', number: cashUpData.twentyEuroCent, value: '0.20', total: (cashUpData.twentyEuroCent * 0.2).toFixed(2)})}
        if(cashUpData.fiftyEuroCent){ cashData.push({type: 'cash', number: cashUpData.fiftyEuroCent, value: '0.50', total: (cashUpData.fiftyEuroCent * 0.5).toFixed(2)})}
        if(cashUpData.oneEuro){ cashData.push({type: 'cash', number: cashUpData.oneEuro, value: '1.00', total: (cashUpData.oneEuro * 1).toFixed(2)})}
        if(cashUpData.twoEuro){ cashData.push({type: 'cash', number: cashUpData.twoEuro, value: '2.00', total: (cashUpData.twoEuro * 2).toFixed(2)})}
        if(cashUpData.fiveEuro){ cashData.push({type: 'cash', number: cashUpData.fiveEuro, value: '5.00', total: (cashUpData.fiveEuro * 5).toFixed(2)})}
        if(cashUpData.tenEuro){ cashData.push({type: 'cash', number: cashUpData.tenEuro, value: '10.00', total: (cashUpData.tenEuro * 10).toFixed(2)})}
        if(cashUpData.twentyEuro){ cashData.push({type: 'cash', number: cashUpData.twentyEuro, value: '20.00', total: (cashUpData.twentyEuro * 20).toFixed(2)})}
        if(cashUpData.fiftyEuro){ cashData.push({type: 'cash', number: cashUpData.fiftyEuro, value: '50.00', total: (cashUpData.fiftyEuro * 50).toFixed(2)})}
        if(cashUpData.hundredEuro){ cashData.push({type: 'cash', number: cashUpData.hundredEuro, value: '100.00', total: (cashUpData.hundredEuro * 100).toFixed(2)})}
        if(cashUpData.twoHundredEuro){ cashData.push({type: 'cash', number: cashUpData.twoHundredEuro, value: '200.00', total: (cashUpData.twoHundredEuro * 200).toFixed(2)})}
        if(cashUpData.fiveHundredEuro){ cashData.push({type: 'cash', number: cashUpData.fiveHundredEuro, value: '500,00', total: (cashUpData.fiveHundredEuro * 500).toFixed(2)})}
        if(cashUpData.pin){ cashData.push({type: 'pin', number: "", value: '', total: (cashUpData.pin).toFixed(2)})}
        if(cashUpData.creditcard){ cashData.push({type: 'creditcard', number: "", value: '', total: (cashUpData.creditcard).toFixed(2)})}

        cashUpData.cash = cashData;

        let register = new Array();

        register.push({
          type: 'cashStatementStart',
          value: null,
          total: cashUpData.previousCashUpSum ? Number(cashUpData.previousCashUpSum) : Number(0)
        })
        register.push({
          type: 'cashIncome',
          value: Number(cashUpData.cashUpSum - cashUpData.previousCashUpSum),
          total: Number(cashUpData.cashUpSum)
        })

        register.push({
          type: 'depositToBank',
          value: cashUpData.cashUpDepositToBank ? Number(-cashUpData.cashUpDepositToBank) : 0, 
          total: Number(cashUpData.cashUpSum - cashUpData.cashUpDepositToBank)
        })

        // if((cashUpData.cashUpSum - cashUpData.cashUpDepositToBank) == cashUpData.previousCashUpSum + cashUpData.cashStatement - cashUpData.cashUpDepositToBank - cashUpData.cashDeficit + cashUpData.cashSurplus){
          register.push({
            type: 'cashStatementEnd',
            value: null,
            total: Number(cashUpData.cashUpSum - cashUpData.cashUpDepositToBank)
          })
        // }
        // else{
        //   register.push({
        //     type: 'cashStatementEnd',
        //     value: null,
        //     total: `<span style="color: red">&euro; ${(cashUpData.cashUpSum - cashUpData.cashUpDepositToBank).toFixed(2)} ${cashUpData.previousCashUpSum + cashUpData.cashStatement - cashUpData.cashUpDepositToBank - cashUpData.cashDeficit + cashUpData.cashSurplus}</span>`
        //   })
        // }


        let employee = this.employees.find(employee => employee.uid == cashUpData.byResource)

        cashUpData.byResourceName = employee ? employee.name : null;

        cashUpData.register = register;


        let income = new Array();

        income.push({
          type: 'cash',
          start: (cashUpData.previousCashUpSum).toFixed(2),
          counted: (cashUpData.cashUpSum).toFixed(2),
          income: (cashUpData.cashUpSum - cashUpData.previousCashUpSum).toFixed(2), 
          expected: (cashUpData.cashUpSum - (cashUpData.cashDeficit ? -cashUpData.cashDeficit : cashUpData.cashSurplus)).toFixed(2),
          difference: (cashUpData.cashDeficit ? -cashUpData.cashDeficit : cashUpData.cashSurplus).toFixed(2)
        })

        income.push({
          type: 'pin',
          start: null,
          counted: (cashUpData.pin).toFixed(2),
          income: (cashUpData.pin).toFixed(2),
          expected: (cashUpData.pin - (cashUpData.pinDeficit ? -cashUpData.pinDeficit : cashUpData.pinSurplus)).toFixed(2),
          difference: (cashUpData.pinDeficit ? -cashUpData.pinDeficit : cashUpData.pinSurplus).toFixed(2)
        })

        income.push({
          type: 'creditcard',
          start: null,
          counted: null,
          counted: (cashUpData.creditcard).toFixed(2),
          income: (cashUpData.creditcard).toFixed(2),
          expected: (cashUpData.creditcard - (cashUpData.creditcardDeficit ? -cashUpData.creditcardDeficit : cashUpData.creditcardSurplus)).toFixed(2),
          difference: (cashUpData.creditcardDeficit ? -cashUpData.creditcardDeficit : cashUpData.creditcardSurplus).toFixed(2)
        })

        cashUpData.income = income;

        cashUpData.totalStart = (cashUpData.cashStatement).toFixed(2);
        cashUpData.totalCounted = (cashUpData.cashUpSum + cashUpData.cashStatement + cashUpData.pin + cashUpData.creditcard).toFixed(2);
        cashUpData.totalIncome = (cashUpData.cashUpSum + cashUpData.pin + cashUpData.creditcard).toFixed(2);
        cashUpData.totalExpected = (+income[0].expected + +income[1].expected + +income[2].expected).toFixed(2);
        cashUpData.totalDifference = (+income[0].difference + +income[1].difference + +income[2].difference).toFixed(2);

      });

    },

    async getRegisterVatData(idToken){
      this.registerData.revenue = new Array();
      let data = JSON.stringify({
        token: idToken,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        year: this.treatmentsDataFilter.year,
        periodType: this.registerDataFilter.type,
        periodNumber : this.registerDataFilter.number
      })
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getRegisterVat`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await axios(config)
      .then(response=> {
        this.registerData.revenue = response.data
      })
      .then(()=>{
        this.registerRevenueHeader = new Array();
        if(this.registerData.revenue.length > 0){
          for (var key of Object.keys(this.registerData.revenue[0])) {
            let headerItem = {
              text: this.$t(`reports.table.${key}`, this.$store.state.locale),
              align: "left",
              value: key,
              width: key == 'day'? "150px": "auto",
              align: key == 'day'? "left": "right",
              sortable: false,
            }
            this.registerRevenueHeader.push(headerItem)
          }
        }
      })
      return 
    },

    async getRegisterCashUpData(idToken){ // SVEN
      this.registerData.cashUp = {
        data: new Array(),
        totalCreditcard: 0,
        totalPin: 0,
        totalDeposit: 0,
        totalToBank: 0
      };
      this.registerData.deficit = {
        data: new Array(),
        totalRounded: 0,
        totalCashUp: 0,
        totalSurplus: 0,
        totalDeficit: 0
      }; 
      this.registerData.register = {
        data: new Array(),
        totalDeficit: 0,
        totalSurplus: 0
      };  
      let data = JSON.stringify({
        token: idToken,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        year: this.treatmentsDataFilter.year,
        periodType: this.registerDataFilter.type,
        periodNumber : this.registerDataFilter.number
      })
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getRegisterCashUp`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await axios(config)
      .then(response=> {
        console.log("getRegisterCashUpData response: ", data, response)
        this.registerData.cashUp.data = response.data;
        this.registerData.deficit.data = response.data;
        this.registerData.register.data = response.data;

        this.registerData.cashUp.totalCreditcard = response.data.reduce((total, item) => total + Number(item.creditcard),0)
        this.registerData.cashUp.totalPin = response.data.reduce((total, item) => total + Number(item.pin),0)
        this.registerData.cashUp.totalDeposit = response.data.reduce((total, item) => total + Number(item.cashUpDepositToBank),0)
        this.registerData.cashUp.totalToBank = response.data.reduce((total, item) => total + Number(item.cashUpDepositToBank + item.pin + item.creditcard),0)

        this.registerData.deficit.totalRounded = response.data.reduce((total, item) => total + Number(item.cashRounding),0)
        this.registerData.deficit.totalCashUp = response.data.reduce((total, item) => total + Number(item.surplus - item.deficit),0)
        this.registerData.deficit.totalSurplus = response.data.reduce((total, item) => total + Number(item.surplus + item.cashRounding),0)
        this.registerData.deficit.totalDeficit = response.data.reduce((total, item) => total + Number(item.deficit - item.cashRounding),0)

        this.registerData.register.totalDeficit = response.data.reduce((total, item) => total + Number(item.endCashUpSum - item.startCashUpSum  > 0 ? item.endCashUpSum - item.startCashUpSum : 0),0)
        this.registerData.register.totalSurplus = response.data.reduce((total, item) => total + Number(item.endCashUpSum - item.startCashUpSum  < 0 ? item.endCashUpSum - item.startCashUpSum : 0),0)
      })
      return 
    },

    /**
     * Aanbetalingen uit geselecteerde periode ophalen
     * Storteren op (Betaald, Verrekend en openstand)
     */
    async getDespositData(){
      // 1. Reset data
      // 2. Data klaar maken om dynamisch periode te selecteren
      // 3. Data ophalen
      // 4. Data sorteren

      // 1. Reset data
      this.depositData.open.number = 0;
      this.depositData.open.amount = 0;
      this.depositData.total.number = 0;
      this.depositData.total.amount = 0;
      this.depositData.settled.number = 0;
      this.depositData.settled.amount = 0;
      this.depositData.compensation.number = 0;
      this.depositData.compensation.amount = 0;
      this.depositData.compensation.data = new Array();
      this.depositData.refunded.number = 0;
      this.depositData.refunded.amount = 0;
      this.depositData.graph.labels = new Array();
      this.depositData.graph.value = new Array();
      this.depositData.prepayments = new Array();
      this.depositData.allPrepayments = new Array();


      // 2. Data klaar maken om dynamisch periode te selecteren
      let type = this.depositDataFilter.type == 'month' ? 'month' : this.depositDataFilter.type;
      let startEndType = this.depositDataFilter.type;
      let startNumber = this.depositDataFilter.type == 'month' ? this.depositDataFilter.number - 1 : this.depositDataFilter.number;
      let endNumber = this.depositDataFilter.type == 'month' ? this.depositDataFilter.number - 1 : this.depositDataFilter.number;
      if(this.depositDataFilter.type == 'week' || this.depositDataFilter.type == 'fourWeeks'){
        type = 'isoWeek';
        startEndType = 'week';
        if(this.depositDataFilter.type == 'fourWeeks'){
          startNumber = this.depositDataFilter.number * 4;
          endNumber = (this.depositDataFilter.number * 4) - 3;
        }
      }

      let firstDayOfPeriod= moment().year(this.depositDataFilter.year)[type](startNumber).startOf(startEndType).format("YYYY-MM-DD");
      let lastDayOfPeriod = moment().year(this.depositDataFilter.year)[type](endNumber).endOf(startEndType).format("YYYY-MM-DD");

      // Set empty Graphdata (numbers based on period type)
      this.depositData.graph.type = this.depositDataFilter.type == 'week' || this.depositDataFilter.type == 'month' ?  'days' : 'weeks';
      let steps = moment(lastDayOfPeriod).diff( moment(firstDayOfPeriod), this.depositData.graph.type);
      this.depositData.graph.labels = [...Array.from({ length: steps }, (value, index) => { // Labels niet voor elke dag weergeven maar elke 4 of 5 dagen
        if(this.depositData.graph.type == 'days'){
          return  (index == 0 || (index + 1) % 5 === 0) ? index + 1 : " ";
        }
        else if(this.depositData.graph.type == 'weeks'){
          return  (index == 0 || (index + 1) % 4 === 0) ? index + 1 : " ";
        }
        else{
          return " "
        }
      })];
      this.depositData.graph.value = Array.from({ length: steps }, (value, index) => 0); // Data standaard op 0 zetten

      let allPrepayments = new Array();
      let prepayments = new Array();

      // 3. Data ophalen
      await db.collection("prepayments")
      .where('companyId', '==', this.$store.state.activeCompany.id)
      .where('timestamp', '>=', new Date(firstDayOfPeriod+"T00:00:00"))
      .where('timestamp', '<=', new Date(lastDayOfPeriod+"T23:59:59"))
      .get()
      .then(snap => {  
        snap.forEach(doc => {
          if(!doc.data().branchId || doc.data().branchId == this.$store.state.activeBranch.id){ // Check only for company level downpayments and downpayments specific for this branch 
            let prepayment = doc.data();
            prepayment.id = doc.id;
            
            allPrepayments.push(prepayment);
          }
        });
      })

      // 4. Data sorteren
      if(allPrepayments && allPrepayments.length > 0){
        
        for (let i = 0; i < allPrepayments.length; i++) {
          allPrepayments[i].clientName = await this.getClientData(allPrepayments[i].clientId)
        }

        await new Promise((resolve)=>{
          let counter = 0;
          allPrepayments.forEach(prepayment=>{
            
            if(prepayment.paymentStatus == 'paid'){ // Aanbetalingen die zijn betaald
              // Set value on GraphData index
              let index = moment(prepayment.timestamp.seconds,'X').diff( moment(firstDayOfPeriod), this.depositData.graph.type);
              if(index <= this.depositData.graph.value.length + 1){
                this.depositData.graph.value[index - 1] = Number(this.depositData.graph.value[index -1]) + Number(prepayment.amount.number);
              }
              this.depositData.total.number++;
              this.depositData.total.amount = this.depositData.total.amount + Number(prepayment.amount.number);
            

            
              // MOgelijke types: partialSettled | settled | compensation
              if(prepayment.status.type == 'settled' || prepayment.status.type == 'partialSettled'){ // Aanbetalingen die zijn verrekend
                // Bij statustype' partialSettled' wordt er een nieuwe prepaymentDoc aangemaakt met het nieuwe te verrekenen bedrag. Daarom word deze meegerekend als settled.
                this.depositData.settled.number++;
                this.depositData.settled.amount = this.depositData.settled.amount + Number(prepayment.amount.number)
              }
              else if(prepayment.status.type == 'compensation'){ // Aanbetalingen wordt gebruikt als compensatie
                this.depositData.compensation.number++;
                this.depositData.compensation.amount = this.depositData.compensation.amount + Number(prepayment.amount.number);
                this.depositData.compensation.data.push(prepayment);
              }
              else if(prepayment.status.type == 'refund'){ // Aanbetaling is betaald en daarna teruggestort.              
                this.depositData.refunded.number++;
                this.depositData.refunded.amount = Number(this.depositData.refunded.amount) + Number(prepayment.amount.number);
              }
              else{ // Downpayment is still open
                prepayments.push(prepayment);
                this.depositData.open.number++;
                this.depositData.open.amount = this.depositData.open.amount + Number(prepayment.amount.number);
              }
              
            }
            
            counter++
            if(counter == allPrepayments.length){
              resolve()
            }
          })
        })
      }

      this.depositData.prepayments = prepayments.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);
      this.depositData.allPrepayments = allPrepayments.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);
      console.log("Loading: false")
      this.dataLoading = false;
    },

    async getRegisterDeficitData(idToken){
      this.registerData.deficit = new Array();
      let data = JSON.stringify({
        token: idToken,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        year: this.treatmentsDataFilter.year,
        periodType: this.registerDataFilter.type,
        periodNumber : this.registerDataFilter.number
      })
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getRegisterDeficit`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await axios(config)
      .then(response=> {
        this.registerData.deficit = response.data
      })
      return
    },

    async getRegisterExpensesData(idToken){
      this.registerData.expenses = new Array();
      let data = JSON.stringify({
        token: idToken,
        companyId: this.$store.state.activeCompany.id,
        branchId: this.$store.state.activeBranch.id,
        year: this.treatmentsDataFilter.year,
        periodType: this.registerDataFilter.type,
        periodNumber : this.registerDataFilter.number
      })
      var config = {
        method: 'post',
        url: `${this.$store.state.reportsApi.url}/getRegisterExpenses`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      await axios(config)
      .then(response=> {
        this.registerData.expenses = response.data
      })
      return
    },

    getTreatmentData(){
      this.dataLoading = true;
      let year = this.treatmentsDataFilter.year;
      let employee = this.treatmentsDataFilter.employee;
      let periodType = this.treatmentsDataFilter.type;
      let periodNumber = this.treatmentsDataFilter.number;
      this.treatmentData.treatments = new Array();
      this.treatmentData.categories = new Array();
      this.treatmentData.total = {
        number: 0,
        totalNettoRevenue: 0,
        totalBrutoRevenue: 0,
        totalNettoValue: 0,
        totalBrutoValue: 0
      };
   
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken=> {
          let data = JSON.stringify({
            token: idToken,
            companyId: this.$store.state.activeCompany.id,
            branchId: this.$store.state.activeBranch.id,
            year: year,
            employee: employee,
            periodType: periodType,
            periodNumber : periodNumber
          })
          var config = {
            method: 'post',
            url: `${this.$store.state.reportsApi.url}/getTreatmentData`,
            headers: {'Content-Type': 'application/json'},
            data : data
          };
          axios(config)
          .then(response=> {
            if(response.data && response.data.length > 0){
              console.log("getTreatmentData: ", response.data)
              let treatments = new Object();
              let categories = new Object();

              response.data.forEach(typeData=>{
                this.treatmentData.total.number = this.treatmentData.total.number + typeData.number;
                this.treatmentData.total.totalNettoRevenue = this.treatmentData.total.totalNettoRevenue + Number(typeData.totalNettoRevenue);
                this.treatmentData.total.totalBrutoRevenue = this.treatmentData.total.totalBrutoRevenue + typeData.totalBrutoRevenue;
                this.treatmentData.total.totalNettoValue = this.treatmentData.total.totalNettoValue + typeData.totalNettoValue;
                this.treatmentData.total.totalBrutoValue = this.treatmentData.total.totalBrutoValue + typeData.totalBrutoValue;
                if(treatments[typeData.treatmentId]){
                  treatments[typeData.treatmentId].number = treatments[typeData.treatmentId].number + typeData.number;
                  treatments[typeData.treatmentId].totalNettoRevenue = treatments[typeData.treatmentId].totalNettoRevenue + typeData.totalNettoRevenue;
                  treatments[typeData.treatmentId].totalBrutoRevenue = treatments[typeData.treatmentId].totalBrutoRevenue + typeData.totalBrutoRevenue;
                }
                else{
                  treatments[typeData.treatmentId] =  _.cloneDeep(typeData);
                }
                if(categories[typeData.category]){
                  categories[typeData.category].number = categories[typeData.category].number + typeData.number;
                  categories[typeData.category].totalNettoRevenue = categories[typeData.category].totalNettoRevenue + typeData.totalNettoRevenue;
                  categories[typeData.category].totalBrutoRevenue = categories[typeData.category].totalBrutoRevenue + typeData.totalBrutoRevenue;
                }
                else{
                  categories[typeData.category] =  _.cloneDeep(typeData);
                }
              })
              
              this.treatmentData.loaded = true;
              this.treatmentData.treatments = Object.keys(treatments).map(i => treatments[i]);
              this.treatmentData.categories = Object.keys(categories).map(i => categories[i]);
            }
          })
          .then(()=>{
            this.dataLoading = false;
          })
          .catch(error=> {
            this.dataLoading = false;
            console.error("Error getting data from bigquery: ", error);
            console.error(error.response.data);
          })
          
        })
      .catch(error=> {
        console.error("Error getting ID Token", error);
      });
    },

    getProductData(){
      this.dataLoading = true;
      let year = this.productsDataFilter.year;
      let employee = this.productsDataFilter.employee;
      let periodType = this.productsDataFilter.type;
      let periodNumber = this.productsDataFilter.number;
      this.productData.products = new Array();
      this.productData.categories = new Array();
      this.productData.total = {
        number: 0,
        totalNettoRevenue: 0,
        totalBrutoRevenue: 0,
        totalNettoValue: 0,
        totalBrutoValue: 0
      };
    
      
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken=> {
          let data = JSON.stringify({
            token: idToken,
            companyId: this.$store.state.activeCompany.id,
            branchId: this.$store.state.activeBranch.id,
            year: year,
            employee: employee,
            periodType: periodType,
            periodNumber : periodNumber
          })
          var config = {
            method: 'post',
            url: `${this.$store.state.reportsApi.url}/getProductData`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          console.log("api data: ", data)
          axios(config)
          .then(response=> {
            if(response.data && response.data.length > 0){
              console.log(response.data)
              let products = new Object();
              let categories = new Object();

              response.data.forEach(typeData=>{
                this.productData.total.number = this.productData.total.number + typeData.number;
                this.productData.total.totalNettoRevenue = this.productData.total.totalNettoRevenue + typeData.totalNettoRevenue;
                this.productData.total.totalBrutoRevenue = this.productData.total.totalBrutoRevenue + typeData.totalBrutoRevenue;
                this.productData.total.totalNettoValue = this.productData.total.totalNettoValue + typeData.totalNettoValue;
                this.productData.total.totalBrutoValue = this.productData.total.totalBrutoValue + typeData.totalBrutoValue;
                if(products[typeData.productId]){
                  products[typeData.productId].number = products[typeData.productId].number + typeData.number;
                  products[typeData.productId].totalNettoRevenue = products[typeData.productId].totalNettoRevenue + typeData.totalNettoRevenue;
                  products[typeData.productId].totalBrutoRevenue = products[typeData.productId].totalBrutoRevenue + typeData.totalBrutoRevenue;
                }
                else{
                  products[typeData.productId] =  _.cloneDeep(typeData);
                }

                if(categories[typeData.category]){
                  categories[typeData.category].number = categories[typeData.category].number  + typeData.number;
                  categories[typeData.category].totalNettoRevenue = categories[typeData.category].totalNettoRevenue + typeData.totalNettoRevenue;
                  categories[typeData.category].totalBrutoRevenue = categories[typeData.category].totalBrutoRevenue + typeData.totalBrutoRevenue;
                }
                else{
                  categories[typeData.category] =  _.cloneDeep(typeData);
                }
              })
              
              this.productData.loaded = true;
              this.productData.products = Object.keys(products).map(i => products[i]);
              this.productData.categories = Object.keys(categories).map(i => categories[i]);
            }
          })
          .then(()=>{
            this.dataLoading = false;
          })
          .catch(error=> {
            this.dataLoading = false;
            console.error("Error getting data from bigquery: ", error);
            console.error(error.response.data);
          })
          
        })
      .catch(error=> {
        console.error("Error getting ID Token", error);
      });
    },

    getRevenueOverview(){
      this.revenueOverview = {
        quarters: [
          { quarter: 1, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { quarter: 2, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { quarter: 3, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { quarter: 4, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
        ],
        months: [
          { name: this.$t('general.january', this.$store.state.locale), month: 1, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.february', this.$store.state.locale), month: 2, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.march', this.$store.state.locale), month: 3, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.april', this.$store.state.locale), month: 4, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.may', this.$store.state.locale), month: 5, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.june', this.$store.state.locale), month: 6, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.july', this.$store.state.locale), month: 7, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.august', this.$store.state.locale), month: 8, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.september', this.$store.state.locale), month: 9, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.october', this.$store.state.locale), month: 10, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.november', this.$store.state.locale), month: 11, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { name: this.$t('general.december', this.$store.state.locale), month: 12, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
        ],

        weeks: [
          { week: 1, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 2, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 3, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 4, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 5, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 6, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 7, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 8, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 9, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 10, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 11, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 12, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 13, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 14, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 15, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 16, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 17, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 18, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 19, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 20, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 21, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 22, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 23, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 24, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 25, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 26, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 27, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 28, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 29, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 30, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 31, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 32, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 33, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 34, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 35, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 36, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 37, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 38, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 39, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 40, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 41, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 42, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 43, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 44, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 45, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 46, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 47, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 48, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 49, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 50, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 51, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
          { week: 52, treatmentsNumber: 0, productsNumber: 0, treatmentsNetto: 0, treatmentsBruto: 0, productsNetto: 0, productsBruto: 0, totalNetto: 0, totalBruto: 0},
        ]
      };

      let year = moment(); // Later dynamisch maken
      let start = moment(year).startOf('year').format('YYYY-MM-DD');
      let end = moment(year).endOf('year').format('YYYY-MM-DD');

      firebase.auth().currentUser.getIdToken(true)
        .then(idToken=> {
          let data = JSON.stringify({
            token: idToken,
            companyId: this.$store.state.activeCompany.id,
            branchId: this.$store.state.activeBranch.id,
            year: this.selectedYear
          })
          var config = {
            method: 'post',
            url: `${this.$store.state.reportsApi.url}/getYearRevenue`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          axios(config)
          .then(response=> {

            if(response.data && response.data.length > 0){
              let weeks = new Object();
              let months = new Object();
              let quarters = new Object();
              let year = {
                treatmentsNetto: 0,
                productsNetto: 0,
                treatmentsBruto: 0,
                productsBruto: 0,
                treatmentsNumber: 0,
                productsNumber: 0,
                totalNetto: 0,
                totalBruto: 0
              };
              response.data.forEach(item=>{
                if(weeks[item.week]){
                    weeks[item.week].treatmentsNetto = item.type == 'treatment' ? weeks[item.week].treatmentsNetto + Number(item.totalNetto) : weeks[item.week].treatmentsNetto,
                    weeks[item.week].productsNetto = item.type == 'product' ? weeks[item.week].productsNetto + Number(item.totalNetto) : weeks[item.week].productsNetto,
                    weeks[item.week].treatmentsBruto = item.type == 'treatment' ? weeks[item.week].treatmentsBruto + Number(item.totalBruto) : weeks[item.week].treatmentsBruto,
                    weeks[item.week].productsBruto = item.type == 'product' ? weeks[item.week].productsBruto + Number(item.totalBruto) : weeks[item.week].productsBruto,
                    weeks[item.week].treatmentsNumber = item.type == 'treatment' ? weeks[item.week].treatmentsNumber + item.number : weeks[item.week].treatmentsNumber,
                    weeks[item.week].productsNumber = item.type == 'product' ? weeks[item.week].productsNumber + item.number : weeks[item.week].productsNumber,
                    weeks[item.week].totalNetto = weeks[item.week].treatmentsNetto + weeks[item.week].productsNetto,
                    weeks[item.week].totalBruto = weeks[item.week].treatmentsBruto + weeks[item.week].productsBruto
                }
                else{
                  weeks[item.week] = {
                    week: item.week,
                    treatmentsNetto: item.type == 'treatment' ? Number(item.totalNetto) : 0,
                    productsNetto: item.type == 'product' ? Number(item.totalNetto) : 0,
                    treatmentsBruto: item.type == 'treatment' ? Number(item.totalBruto) : 0,
                    productsBruto: item.type == 'product' ? Number(item.totalBruto) : 0,
                    treatmentsNumber: item.type == 'treatment' ? Number(item.number) : 0,
                    productsNumber: item.type == 'product' ? Number(item.number) : 0,
                    totalNetto: Number(item.totalNetto),
                    totalBruto: Number(item.totalBruto)
                  }
                }
                if(months[item.month]){
                    months[item.month].treatmentsNetto = item.type == 'treatment' ? months[item.month].treatmentsNetto + Number(item.totalNetto) : months[item.month].treatmentsNetto,
                    months[item.month].productsNetto = item.type == 'product' ? months[item.month].productsNetto + Number(item.totalNetto) : months[item.month].productsNetto,
                    months[item.month].treatmentsBruto = item.type == 'treatment' ? months[item.month].treatmentsBruto + Number(item.totalBruto) : months[item.month].treatmentsBruto,
                    months[item.month].productsBruto = item.type == 'product' ? months[item.month].productsBruto + Number(item.totalBruto) : months[item.month].productsBruto,
                    months[item.month].treatmentsNumber = item.type == 'treatment' ? months[item.month].treatmentsNumber + item.number : months[item.month].treatmentsNumber,
                    months[item.month].productsNumber = item.type == 'product' ? months[item.month].productsNumber + item.number : months[item.month].productsNumber,
                    months[item.month].totalNetto = months[item.month].treatmentsNetto + months[item.month].productsNetto,
                    months[item.month].totalBruto = months[item.month].treatmentsBruto + months[item.month].productsBruto
                }
                else{
                  months[item.month] = {
                    month: item.month,
                    treatmentsNetto: item.type == 'treatment' ? Number(item.totalNetto) : 0,
                    productsNetto: item.type == 'product' ? Number(item.totalNetto) : 0,
                    treatmentsBruto: item.type == 'treatment' ? Number(item.totalBruto) : 0,
                    productsBruto: item.type == 'product' ? Number(item.totalBruto) : 0,
                    treatmentsNumber: item.type == 'treatment' ? Number(item.number) : 0,
                    productsNumber: item.type == 'product' ? Number(item.number) : 0,
                    totalNetto: Number(item.totalNetto),
                    totalBruto: Number(item.totalBruto)
                  }
                }

                if(quarters[item.quarter]){
                  quarters[item.quarter].treatmentsNetto = item.type == 'treatment' ? quarters[item.quarter].treatmentsNetto + Number(item.totalNetto) : quarters[item.quarter].treatmentsNetto,
                  quarters[item.quarter].productsNetto = item.type == 'product' ? quarters[item.quarter].productsNetto + Number(item.totalNetto) : quarters[item.quarter].productsNetto,
                  quarters[item.quarter].treatmentsBruto = item.type == 'treatment' ? quarters[item.quarter].treatmentsBruto + Number(item.totalBruto) : quarters[item.quarter].treatmentsBruto,
                  quarters[item.quarter].productsBruto = item.type == 'product' ? quarters[item.quarter].productsBruto + Number(item.totalBruto) : quarters[item.quarter].productsBruto,
                  quarters[item.quarter].treatmentsNumber = item.type == 'treatment' ? quarters[item.quarter].treatmentsNumber + item.number : quarters[item.quarter].treatmentsNumber,
                  quarters[item.quarter].productsNumber = item.type == 'product' ? quarters[item.quarter].productsNumber + item.number : quarters[item.quarter].productsNumber,
                  quarters[item.quarter].totalNetto = quarters[item.quarter].treatmentsNetto + quarters[item.quarter].productsNetto,
                  quarters[item.quarter].totalBruto = quarters[item.quarter].treatmentsBruto + quarters[item.quarter].productsBruto
                }

                else{
                  quarters[item.quarter] = {
                    quarter: item.quarter,
                    treatmentsNetto: item.type == 'treatment' ? Number(item.totalNetto) : 0,
                    productsNetto: item.type == 'product' ? Number(item.totalNetto) : 0,
                    treatmentsBruto: item.type == 'treatment' ? Number(item.totalBruto) : 0,
                    productsBruto: item.type == 'product' ? Number(item.totalBruto) : 0,
                    treatmentsNumber: item.type == 'treatment' ? Number(item.number) : 0,
                    productsNumber: item.type == 'product' ? Number(item.number) : 0,
                    totalNetto: Number(item.totalNetto),
                    totalBruto: Number(item.totalBruto)
                  }
                }

                year.treatmentsNetto = item.type == 'treatment' ? Number(item.totalNetto) + Number(year.treatmentsNetto) : Number(year.treatmentsNetto);
                year.productsNetto = item.type == 'product' ? Number(item.totalNetto) + Number(year.productsNetto): Number(year.productsNetto);
                year.treatmentsBruto = item.type == 'treatment' ? Number(item.totalBruto) + Number(year.treatmentsBruto) : Number(year.treatmentsBruto);
                year.productsBruto = item.type == 'product' ? Number(item.totalBruto) + Number(year.productsBruto): Number(year.productsBruto);
                year.treatmentsNumber = item.type == 'treatment' ? Number(item.number) + year.treatmentsNumber : Number(year.treatmentsNumber);
                year.productsNumber = item.type == 'product' ? Number(item.number) + year.productsNumber : Number(year.productsNumber);
                year.totalNetto = year.totalNetto + Number(item.totalNetto);
                year.totalBruto = year.totalBruto + Number(item.totalBruto);
       
              })


              this.revenueOverview.quarters.forEach(quarter=>{
                if(quarters[quarter.quarter]){
                  quarter.treatmentsNumber = quarters[quarter.quarter].treatmentsNumber;
                  quarter.productsNumber = quarters[quarter.quarter].productsNumber;
                  quarter.treatmentsNetto = quarters[quarter.quarter].treatmentsNetto;
                  quarter.treatmentsBruto = quarters[quarter.quarter].treatmentsBruto;
                  quarter.productsNetto = quarters[quarter.quarter].productsNetto;
                  quarter.productsBruto = quarters[quarter.quarter].productsBruto;
                  quarter.totalNetto = quarters[quarter.quarter].totalNetto;
                  quarter.totalBruto = quarters[quarter.quarter].totalBruto;
                }
              })

              this.revenueOverview.months.forEach(month=>{
                if(months[month.month]){
                  month.treatmentsNumber = months[month.month].treatmentsNumber;
                  month.productsNumber = months[month.month].productsNumber;
                  month.treatmentsNetto = months[month.month].treatmentsNetto;
                  month.treatmentsBruto = months[month.month].treatmentsBruto;
                  month.productsNetto = months[month.month].productsNetto;
                  month.productsBruto = months[month.month].productsBruto;
                  month.totalNetto = months[month.month].totalNetto;
                  month.totalBruto = months[month.month].totalBruto;
                }
              })

              this.revenueOverview.weeks.forEach(week=>{
                if(weeks[week.week]){
                  week.treatmentsNumber = weeks[week.week].treatmentsNumber;
                  week.productsNumber = weeks[week.week].productsNumber;
                  week.treatmentsNetto = weeks[week.week].treatmentsNetto;
                  week.treatmentsBruto = weeks[week.week].treatmentsBruto;
                  week.productsNetto = weeks[week.week].productsNetto;
                  week.productsBruto = weeks[week.week].productsBruto;
                  week.totalNetto = weeks[week.week].totalNetto;
                  week.totalBruto = weeks[week.week].totalBruto;
                }
              })

            }
          })
          .then(()=>{
            this.dataLoading = false;
          })
          .catch(error=> {
            console.error("Error getting data from bigquery: ", error);
            console.error(error.response.data);
          });
        })
      .catch(error=> {
        console.error("Error getting ID Token", error);
      });
    },

    getVatOverview(){
      this.vatOverview = {
        quarters: [
          { quarter: 1, vatPercentages: {},  totalBruto: 0},
          { quarter: 2, vatPercentages: {},  totalBruto: 0},
          { quarter: 3, vatPercentages: {},  totalBruto: 0},
          { quarter: 4, vatPercentages: {},  totalBruto: 0},
          { quarter: 'total', vatPercentages: {},  totalBruto: 0}
        ],
        months: [
          { name: this.$t('general.january', this.$store.state.locale), month: 1, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.february', this.$store.state.locale), month: 2, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.march', this.$store.state.locale), month: 3, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.april', this.$store.state.locale), month: 4, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.may', this.$store.state.locale), month: 5, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.june', this.$store.state.locale), month: 6, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.july', this.$store.state.locale), month: 7, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.august', this.$store.state.locale), month: 8, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.september', this.$store.state.locale), month: 9, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.october', this.$store.state.locale), month: 10, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.november', this.$store.state.locale), month: 11, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: this.$t('general.december', this.$store.state.locale), month: 12, vatPercentages: {},  totalBruto: 0, totalVat: 0},
          { name: 'total', month: 'total', vatPercentages: {},  totalBruto: 0, totalVat: 0}
        ],
      };

      let year = moment(); // Later dynamisch maken
      let start = moment(year).startOf('year').format('YYYY-MM-DD');
      let end = moment(year).endOf('year').format('YYYY-MM-DD');

      console.log("Start - End: ", start, end);

      // Load Call Logs
      firebase.auth().currentUser.getIdToken(true)
        .then(idToken=> {
          let data = JSON.stringify({
            token: idToken,
            companyId: this.$store.state.activeCompany.id,
            branchId: this.$store.state.activeBranch.id,
            year: this.selectedYear
          })
          var config = {
            method: 'post',
            url: `${this.$store.state.reportsApi.url}/getYearVat`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          axios(config)
          .then(response=> {

            if(response.data && response.data.length > 0){
              console.log("response.data: ", response.data)
              let months = {
                total: {
                  vatPercentages: {},
                  totalBruto: 0
                }
              };
              let quarters = {
                total: {
                  vatPercentages: {},
                  totalBruto: 0
                }
              };
              response.data.forEach(item=>{
                if(months[item.month]){
                  months[item.month].totalBruto = Number(item.totalBruto) + months[item.month].totalBruto;
                  if(months[item.month].vatPercentages[item.percentageVat]){
                    months[item.month].vatPercentages[item.percentageVat].totalNetto = months[item.month].vatPercentages[item.percentageVat].totalNetto + Number(item.totalNetto);
                    months[item.month].vatPercentages[item.percentageVat].totalVat = months[item.month].vatPercentages[item.percentageVat].totalVat + Number(item.totalVat);
                  }
                  else{
                    months[item.month].vatPercentages[item.percentageVat] = {
                      totalNetto: Number(item.totalNetto),
                      totalVat: Number(item.totalVat)
                    };
                  } 
                }
                else{
                  months[item.month] = new Object();
                  months[item.month].totalBruto = Number(item.totalBruto);
                  months[item.month].vatPercentages = new Object();
                  months[item.month].vatPercentages[item.percentageVat] = {
                    totalNetto: Number(item.totalNetto),
                    totalVat: Number(item.totalVat)
                  };
                }
                // Calculate total months
                months.total.totalBruto = months.total.totalBruto + Number(item.totalBruto);
                months.total.totalVat = months.total.totalVat + Number(item.totalVat);

                if(months.total.vatPercentages[item.percentageVat]){
                  months.total.vatPercentages[item.percentageVat].totalNetto = months.total.vatPercentages[item.percentageVat].totalNetto + Number(item.totalNetto);
                  months.total.vatPercentages[item.percentageVat].totalVat =  months.total.vatPercentages[item.percentageVat].totalVat + Number(item.totalVat)
                }
                else{
                  months.total.vatPercentages[item.percentageVat] = {
                    totalNetto: Number(item.totalNetto),
                    totalVat: Number(item.totalVat)
                  };
                }

                if(quarters[item.quarter]){
                  quarters[item.quarter].totalBruto = Number(item.totalBruto) + quarters[item.quarter].totalBruto;
                  if(quarters[item.quarter].vatPercentages[item.percentageVat]){
                    quarters[item.quarter].vatPercentages[item.percentageVat].totalNetto = quarters[item.quarter].vatPercentages[item.percentageVat].totalNetto + Number(item.totalNetto);
                    quarters[item.quarter].vatPercentages[item.percentageVat].totalVat = quarters[item.quarter].vatPercentages[item.percentageVat].totalVat + Number(item.totalVat);
                  }
                  else{                  
                    quarters[item.quarter].vatPercentages[item.percentageVat] = {
                      totalNetto: Number(item.totalNetto),
                      totalVat: Number(item.totalVat)
                    };
                  } 
                }
                else{
                  quarters[item.quarter] = new Object();
                  quarters[item.quarter].totalBruto = Number(item.totalBruto);
                  quarters[item.quarter].vatPercentages = new Object();
                  quarters[item.quarter].vatPercentages[item.percentageVat] = {
                    totalNetto: Number(item.totalNetto),
                    totalVat: Number(item.totalVat)
                  };
                }

                // Calculate total Quarters
                quarters.total.totalBruto = quarters.total.totalBruto + Number(item.totalBruto);
                quarters.total.totalVat = quarters.total.totalVat + Number(item.totalVat);
                if(quarters.total.vatPercentages[item.percentageVat]){
                  quarters.total.vatPercentages[item.percentageVat].totalNetto = quarters.total.vatPercentages[item.percentageVat].totalNetto + Number(item.totalNetto);
                  quarters.total.vatPercentages[item.percentageVat].totalVat =  quarters.total.vatPercentages[item.percentageVat].totalVat + Number(item.totalVat)
                }
                else{
                  quarters.total.vatPercentages[item.percentageVat] = {
                    totalNetto: Number(item.totalNetto),
                    totalVat: Number(item.totalVat)
                  };
                }
              })


              this.vatOverview.quarters.forEach(quarter=>{
                if(quarters[quarter.quarter]){
                  quarter.vatPercentages = quarters[quarter.quarter].vatPercentages;
                  quarter.totalBruto = quarters[quarter.quarter].totalBruto;
                }
              })

              this.vatOverview.months.forEach(month=>{
                if(months[month.month]){
                  month.vatPercentages = months[month.month].vatPercentages;
                  month.totalBruto = months[month.month].totalBruto;
                }
              })
            }
          })
          .then(()=>{
            this.dataLoading = false;
          })
          .catch(error=> {
            console.error("Error getting data from bigquery: ", error);
            console.error(error.response.data);
          });
        })
      .catch(error=> {
        console.error("Error getting ID Token", error);
      });
    },


    async getCurrentRevenue(type){
      this.actualRevenueLoading = true;
      this.actualChartseries[0].name = this.$t('reports.legend.downPayments', this.$store.state.locale);
      this.actualChartseries[1].name = this.$t('reports.legend.treatments', this.$store.state.locale);
      this.actualChartseries[2].name = this.$t('general.products', this.$store.state.locale);
      this.actualChartseries[3].name = this.$t('reports.legend.expected', this.$store.state.locale);
      this.actualChartseries[0].data = new Array();
      this.actualChartseries[1].data = new Array();
      this.actualChartseries[2].data = new Array();
      this.actualChartseries[3].data = new Array();
      this.actualChartOptions.xaxis.categories = new Array();
      this.actualRevenue.products = 0;
      this.actualRevenue.treatments = 0;
      this.actualRevenue.estimated.bruto = 0;
      this.actualRevenue.estimated.netto = 0;
      this.actualRevenue.estimatedTotal.bruto = 0;
      this.actualRevenue.estimatedTotal.netto = 0;
      this.actualRevenue.average.total.bruto = 0;
      this.actualRevenue.average.total.netto = 0;
      this.actualRevenue.current.bruto = 0;
      this.actualRevenue.current.netto = 0;

      let selectedWeek = moment().isoWeek();
      let selectedYear = moment().year();

      if(type && this.actualRevenueDates.weekNumber){
        selectedWeek = type == 'next' ? moment().year(selectedYear).isoWeek(this.actualRevenueDates.weekNumber).add(1, 'week').isoWeek() : moment().year(selectedYear).isoWeek(this.actualRevenueDates.weekNumber).subtract(1, 'week').isoWeek();
        if(type == 'next' && (selectedWeek < this.actualRevenueDates.weekNumber)){ // Check if week is in next year
          selectedYear++
        }
        if(type == 'previous' && (selectedWeek > this.actualRevenueDates.weekNumber)){ // Check if week is in previous year
          selectedYear--
        }
      }

      this.actualRevenueDates = {
        weekNumber: selectedWeek,
        startDay: moment().year(selectedYear).isoWeek(selectedWeek).startOf('week').format("dddd D MMMM"),
        endDay: moment().year(selectedYear).isoWeek(selectedWeek).endOf('week').format("dddd D MMMM"),
        weekType: (selectedYear == moment().year() && selectedWeek == moment().isoWeek()) ? 'now' : (selectedYear < moment().year() || selectedWeek < moment().isoWeek()) ? 'past' : 'future'
      }
      
      let days = [ 
        {format: 'Mon', number: 1, name: this.$t('general.monday', this.$store.state.locale)},
        {format: 'Tue', number: 2, name: this.$t('general.tuesday', this.$store.state.locale)},
        {format: 'Wed', number: 3, name: this.$t('general.wednesday', this.$store.state.locale)},
        {format: 'Thu', number: 4, name: this.$t('general.thursday', this.$store.state.locale)},
        {format: 'Fri', number: 5, name: this.$t('general.friday', this.$store.state.locale)},
        {format: 'Sat', number: 6, name: this.$t('general.saturday', this.$store.state.locale)},
        {format: 'Sun', number: 7, name: this.$t('general.sunday', this.$store.state.locale)}
      ];

      let viewType = this.vatViewType == 'included' ? "bruto" : "netto";
      let week = await this.calculateActualRevenueWeek(selectedWeek, selectedYear) // Get selected week data

      this.actualRevenue.estimated.bruto = week.totalExpectedBruto;
      this.actualRevenue.estimated.netto = week.totalExpectedNetto;
      this.actualRevenue.estimatedTotal.view = this.vatViewType == 'included' ?  week.totalExpectedBruto : week.totalExpectedNetto + week.totalExpectedNetto;

      this.actualRevenue.estimatedTotal.bruto = week.totalExpectedNetto + week.totalBruto;
      this.actualRevenue.estimatedTotal.netto = week.totalExpectedNetto + week.totalNetto;
      this.actualRevenue.estimatedTotal.view = this.vatViewType == 'included' ? week.totalExpectedBruto + week.totalBruto : week.totalExpectedNetto + week.totalNetto;

      this.actualRevenue.current.bruto = week.totalBruto;
      this.actualRevenue.current.netto = week.totalNetto;
      this.actualRevenue.current.view = this.vatViewType == 'included' ? week.totalBruto : week.totalNetto;

      this.actualRevenue.downPayment.bruto = week.totalDownPaymentsBruto;
      this.actualRevenue.downPayment.netto = week.totalDownPaymentsNetto;

      this.actualRevenue.average.total.bruto = week.totalBruto;
      this.actualRevenue.average.total.netto = week.totalNetto;
      this.actualRevenue.treatments = week.totalTreatments;
      this.actualRevenue.products = week.totalProducts;
   

      await new Promise((resolve)=>{
        let counter = 0
        days.forEach(day=>{ // Loop trough days of the week
          this.actualChartOptions.xaxis.categories.push(moment().year(selectedYear).isoWeek(selectedWeek).day(day.number).format("YYYY-MM-DD"));
          this.actualChartseries[0].data.push(Number(week[day.format].downPayment[viewType]).toFixed(0));
          this.actualChartseries[1].data.push(Number(week[day.format].treatment[viewType]).toFixed(0));
          this.actualChartseries[2].data.push(Number(week[day.format].product[viewType]).toFixed(0));
          this.actualChartseries[3].data.push(Number(week[day.format].estimated[viewType]).toFixed(0));
          counter++
          if(counter == days.length){
            resolve()
          }
        })
      })
      
      this.actualRevenueLoading = false;
      this.dataLoading = false;
      // this.actualRevenue.average.number = Number(this.actualRevenue.average.total.bruto / this.actualRevenue.average.activeDays).toFixed(2);
      this.actualRevenue.estimated.bruto = Number(this.actualRevenue.estimated.bruto).toFixed(2);
     
    },

    async calculateActualRevenueWeek(weekNumber, year){

      let firstDayOfWeek = moment().year(year).isoWeek(weekNumber).startOf('week').format("YYYY-MM-DD");
      let lastDayOfWeek = moment().year(year).isoWeek(weekNumber).endOf('week').format("YYYY-MM-DD");
      let firstDayOfWeekForcast = moment().format("YYYY-MM-DD") > firstDayOfWeek ? moment().format("YYYY-MM-DD") : firstDayOfWeek;

      let weekData = {
        totalNetto: 0,
        totalBruto: 0,
        totalProducts: 0,
        totalTreatments: 0,
        totalExpectedNetto: 0,
        totalExpectedBruto: 0,
        totalExpectedTreatments: 0,
        totalDownPaymentsNetto: 0,
        totalDownPaymentsBruto: 0,
        Mon: { estimated : { bruto: 0, netto: 0 }, treatment: { bruto: 0, netto: 0 }, product: { bruto: 0, netto: 0 }, downPayment: { bruto: 0, netto: 0 } },
        Tue: { estimated : { bruto: 0, netto: 0 }, treatment: { bruto: 0, netto: 0 }, product: { bruto: 0, netto: 0 }, downPayment: { bruto: 0, netto: 0 } },
        Wed: { estimated : { bruto: 0, netto: 0 }, treatment: { bruto: 0, netto: 0 }, product: { bruto: 0, netto: 0 }, downPayment: { bruto: 0, netto: 0 } },
        Thu: { estimated : { bruto: 0, netto: 0 }, treatment: { bruto: 0, netto: 0 }, product: { bruto: 0, netto: 0 }, downPayment: { bruto: 0, netto: 0 } },
        Fri: { estimated : { bruto: 0, netto: 0 }, treatment: { bruto: 0, netto: 0 }, product: { bruto: 0, netto: 0 }, downPayment: { bruto: 0, netto: 0 } },
        Sat: { estimated : { bruto: 0, netto: 0 }, treatment: { bruto: 0, netto: 0 }, product: { bruto: 0, netto: 0 }, downPayment: { bruto: 0, netto: 0 } },
        Sun: { estimated : { bruto: 0, netto: 0 }, treatment: { bruto: 0, netto: 0 }, product: { bruto: 0, netto: 0 }, downPayment: { bruto: 0, netto: 0 } },
      }

      let paidReceipts = new Array()
      let openBookingSummaries = new Array()

      await db.collection("receiptsPaid")
      .where('branchId', '==', this.$store.state.activeBranch.id)
      .where('visit', '>=', firstDayOfWeek)
      .where('visit', '<=', lastDayOfWeek)
      .get()
      .then(snap => {  
        snap.forEach(doc => {
          paidReceipts.push(doc.data())
        });
      })

      if(moment().format("YYYY-MM-DD") <= lastDayOfWeek){
        await db.collection("bookingsummary")
        .where('branchId', '==', this.$store.state.activeBranch.id)
        .where('paid', '==', false)
        .where('deleted', '==', false)
        .where('visit', '>=', firstDayOfWeekForcast)
        .where('visit', '<=', lastDayOfWeek)
        .get()
        .then(snap => {  
          snap.forEach(doc => {
            openBookingSummaries.push(doc.data())
          });
        })
      }

      paidReceipts.forEach((receipt)=>{
        receipt.selectedItems.forEach(item=>{
          if(item.type == 'treatment'){
            weekData.totalTreatments++
            weekData[moment(receipt.visit).locale("EN").format('ddd')].treatment.netto = weekData[moment(receipt.visit).locale("EN").format('ddd')].treatment.bruto + item.newPriceTotalNetto;
            weekData[moment(receipt.visit).locale("EN").format('ddd')].treatment.bruto = weekData[moment(receipt.visit).locale("EN").format('ddd')].treatment.bruto + item.newPriceTotal;
          }
          else{
            weekData.totalProducts++
            weekData[moment(receipt.visit).locale("EN").format('ddd')].product.netto = weekData[moment(receipt.visit).locale("EN").format('ddd')].product.bruto + item.newPriceTotalNetto;
            weekData[moment(receipt.visit).locale("EN").format('ddd')].product.bruto = weekData[moment(receipt.visit).locale("EN").format('ddd')].product.bruto + item.newPriceTotal;
          }
         

          let downPaymentBruto = 0;
          let downPaymentNetto = 0;

          if(item.discountValueByModifiers && item.discountValueByModifiers.length > 0){

            let downPaymentModifierIds = receipt.usedModifiers.filter((modifier)=> { return modifier.type == 'downPayment'})
            item.discountValueByModifiers.forEach((modifier)=>{
              if(downPaymentModifierIds.find((downPaymentModifier)=> { return modifier.modifierId == downPaymentModifier.id }) != undefined){
                downPaymentBruto = Number(modifier.discountValue);
                downPaymentNetto = Number(((modifier.discountValue/(100 + modifier.vatPercentage)) * 100).toFixed(2));
              }
            })
          }

          weekData[moment(receipt.visit).locale("EN").format('ddd')].downPayment.bruto = weekData[moment(receipt.visit).locale("EN").format('ddd')].downPayment.bruto + downPaymentBruto;
          weekData[moment(receipt.visit).locale("EN").format('ddd')].downPayment.netto = weekData[moment(receipt.visit).locale("EN").format('ddd')].downPayment.netto + downPaymentNetto;

          weekData.totalDownPaymentsNetto = weekData.totalDownPaymentsNetto + downPaymentBruto;
          weekData.totalDownPaymentsBruto = weekData.totalDownPaymentsBruto + downPaymentNetto;
          weekData.totalNetto = weekData.totalNetto + item.newPriceTotalNetto + downPaymentNetto;
          weekData.totalBruto = weekData.totalBruto + item.newPriceTotal + downPaymentBruto;
        })
      })


      openBookingSummaries.forEach((bookingSummary)=>{
        if(bookingSummary.treatmentId && bookingSummary.status != 'completed'){    
          weekData.totalExpectedTreatments++
          let price = this.getTreatmentPrice(bookingSummary.treatmentId);
          weekData.totalExpectedNetto = weekData.totalExpectedNetto + price.netto;
          weekData.totalExpectedBruto = weekData.totalExpectedBruto + price.bruto;
          weekData[moment(bookingSummary.visit).locale("EN").format('ddd')].estimated.netto = weekData[moment(bookingSummary.visit).locale("EN").format('ddd')].estimated.netto + price.netto;
          weekData[moment(bookingSummary.visit).locale("EN").format('ddd')].estimated.bruto = weekData[moment(bookingSummary.visit).locale("EN").format('ddd')].estimated.bruto + price.bruto;
        }
      })
      
      return weekData
    },


    async calculateActualRevenue(date){
      let status = 'past';
      let today = moment().format("YYYY-MM-DD");
      let revenue = {
        estimated : {
          bruto: 0,
          netto: 0
        },
        treatment: {
          bruto: 0,
          netto: 0
        },
        product: {
          bruto: 0,
          netto: 0
        },
        downPayment: {
          bruto: 0,
          netto: 0
        }
      }
      if(today < date){
        // Toekomst
        status = 'future'
      }
      else if(today == date){
        // Vandaag
        status = 'present'
      }

      if(status != 'future'){
        // console.log("geen toekomst: ", date)
        this.actualRevenue.average.activeDays++;
        await db.collection("receiptsPaid")
        .where('companyId', '==', this.$store.state.activeCompany.id)
        .where('branchId', '==', this.$store.state.activeBranch.id)
        .where('visit', '==', date)
        .get()
        .then(snap => {  
          snap.forEach(doc => {
            doc.data().selectedItems.forEach(item=>{
              if(item.type == 'treatment'){
                revenue.treatment.netto = revenue.treatment.netto + item.newPriceTotalNetto;
                revenue.treatment.bruto = revenue.treatment.bruto + item.newPriceTotal;
                this.actualRevenue.treatments++
                this.actualRevenue.estimated.bruto = item.newPriceTotal + this.actualRevenue.estimated.bruto;
                this.actualRevenue.estimated.netto = item.newPriceTotalNetto + this.actualRevenue.estimated.netto;
                this.actualRevenue.average.total.bruto = item.newPriceTotal + this.actualRevenue.average.total.bruto;
                this.actualRevenue.average.total.netto = item.newPriceTotalNetto + this.actualRevenue.average.total.netto;  
              }
              else{
                revenue.product.netto = revenue.product.netto + item.newPriceTotalNetto;
                revenue.product.bruto = revenue.product.bruto + item.newPriceTotal;
                this.actualRevenue.products++
                this.actualRevenue.estimated.bruto = item.newPriceTotal + this.actualRevenue.estimated.bruto;
                this.actualRevenue.estimated.netto = item.newPriceTotalNetto + this.actualRevenue.estimated.netto;
                this.actualRevenue.average.total.bruto = item.newPriceTotal + this.actualRevenue.average.total.bruto;
                this.actualRevenue.average.total.netto = item.newPriceTotalNetto + this.actualRevenue.average.total.netto;
              }
              if(item.discountValueByModifiers && item.discountValueByModifiers.length > 0){
                item.discountValueByModifiers.forEach((modifier)=>{
                  if(modifier.type == 'downPayment'){
                    revenue.downPayment.bruto = revenue.downPayment.netto + modifier.discountValue;
                    revenue.downPayment.netto = revenue.downPayment.netto + (modifier.discountValue/(100 + modifier.vatPercentage)) * 100 ;
                  }
                })
              }
            });
          });
        })
        .catch(error=>{
          console.log('Error getting receipts: ', error)
        })
      }
      
      if(status != 'past'){
        console.log("niet het verleden: ", date)
        await db.collection("receiptsDraft")
          .where('companyId', '==', this.$store.state.activeCompany.id)
          .where('branchId', '==', this.$store.state.activeBranch.id)
          .where('draft', '==', true)
          .where('visit', '==', date)
          .get()
          .then(snap => {  
            snap.forEach(doc => {
              // console.log("doc.data draft:", doc.data())
              if(doc.data().selectedItems && doc.data().selectedItems.length >0){
                doc.data().selectedItems.forEach(item=>{
                  if(item.type == 'treatment'){
                    revenue.estimated.netto = revenue.estimated.netto + item.newPriceTotalNetto;
                    revenue.estimated.bruto = revenue.estimated.bruto + item.newPriceTotal;
                    this.actualRevenue.estimated.bruto = item.newPriceTotal + this.actualRevenue.estimated.bruto;
                    this.actualRevenue.estimated.netto = item.newPriceTotalNetto + this.actualRevenue.estimated.netto;
                  }
                  else{
                    revenue.estimated.netto = revenue.estimated.netto + item.newPriceTotalNetto;
                    revenue.estimated.bruto = revenue.estimated.bruto + item.newPriceTotal;
                    this.actualRevenue.estimated.bruto = item.newPriceTotal + this.actualRevenue.estimated.bruto;
                    this.actualRevenue.estimated.netto = item.newPriceTotalNetto + this.actualRevenue.estimated.netto;
                  }
                });
              }
            });
          })
          .catch(error=>{
            console.log('Error getting receipts: ', error)
          })
      }
      console.log("revenue: ", revenue)
      return revenue;
    },

    addNewIndicator(){
      let highestIndicator = null;
      
      if(this.indicators && this.indicators.length > 0){
        let indicators = _.cloneDeep(this.indicators);
        indicators.forEach((indicator)=>{
          if(highestIndicator){
            if(indicator.y > highestIndicator.y){
              highestIndicator = indicator
            }
            else if(indicator.y == highestIndicator.y){
              if(indicator.x > highestIndicator.x){
                highestIndicator = indicator
              }
            }
            else if(indicator.x == highestIndicator.x){
              if(indicator.w > highestIndicator.w){
                highestIndicator = indicator
              }
            }
          }
          else{
            highestIndicator = indicator
          }
        })
      }
      else{
        highestIndicator = {
          w: 0,
          x: 0,
          y: 0
        }
      }
      

      console.log("highestIndicator: ", highestIndicator)
      let coordinates = {
        w: highestIndicator.w,
        x: highestIndicator.x,
        y: highestIndicator.y
      }
      bus.$emit("addIndicator", coordinates);
    },

    newIndicatorAdded(indicator){ // New indicator added to database
      this.dataLoading = true;
      let data = new Array();
      this.indicators.push(indicator);
      data.push(indicator)
      this.getIndicatorData(data);
    },

    indicatorEditted(indicator){  // Indicator edited in database
      this.dataLoading = true;
      let data = new Array();
      let index = _.findIndex(this.indicators, {'i': indicator.i});
      if(index >= 0){ // Change old data with new data;
        this.indicators[index] = indicator;
      }
      data.push(indicator)
      this.getIndicatorData(data);
    },

    editIndicator(indicator){  // Edit indicator in dialog
      indicator.menu = false;
      bus.$emit("editIndicator", indicator);
      console.log("Indicator edited")
    },

    movedIndicator(indicator){ // Indicator is moved in Grid
      db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("indicators")
        .doc(indicator.i)
        .set({
            x: indicator.x,
            y: indicator.y,
          },{merge: true})
        .catch((err) => {
          console.error("Error moving indicator", err);
        });
    },

    getIndicatorData(indicators){
      new Promise((resolve)=>{
        let counter = 0;
        if(indicators.length > 0){
          firebase.auth().currentUser.getIdToken(true)
          .then(idToken=> {
            indicators.forEach(indicator=>{
              let data = JSON.stringify({
                token: idToken,
                companyId: this.$store.state.activeCompany.id,
                branchId: this.$store.state.activeBranch.id,
                year: this.dashboardDataFilter.year,
                employee: this.dashboardDataFilter.employee,
                periodType: this.dashboardDataFilter.type,
                periodNumber : this.dashboardDataFilter.number,
                data: indicator.data,
                dataType: indicator.data,
                graphType: indicator.dataType,
                dataFilter: indicator.dataFilter,
                treatments: indicator.treatments ? indicator.treatments : null,
                products: indicator.products ? indicator.products : null,
                treatmentTypes: indicator.treatmentTypes ? indicator.treatmentTypes : null,
                productTypes: indicator.productTypes ? indicator.productTypes : null
              })

              var config = {
                method: 'post',
                url: `${this.$store.state.reportsApi.url}/getIndicatorData`,
                headers: {'Content-Type': 'application/json'},
                data : data
              };

              axios(config)
              .then(response=> {
                // console.log("response getIndicatorData: ", response)
                if(response.data){
                  indicator.number = Number(_.sumBy(response.data, row => {return row.number;})).toFixed(0);
                  indicator.revenue = Number(_.sumBy(response.data, row => {return row.revenue;})).toFixed(0);
                  if(indicator.graphType != 'number'){
                    console.log("response.data.map(a => a.revenue): ", response.data.map(a => a.revenue))
                
                    if(indicator.dataType != 'numbers'){
                      indicator.chartData = [
                        {data: response.data.map(a => a.revenue)}
                      ]
                    } 
                    else{
                      indicator.chartData = [
                        {data: response.data.map(a => a.number)}
                      ]
                    }
                  }

                  if(indicator.useGoal){
                    let dailyGoal;
                    if(indicator.goalPeriod == 'month'){
                      dailyGoal = indicator.goalNumber / 30;
                    }
                    else if(indicator.goalPeriod == 'fourWeeks'){
                      dailyGoal = indicator.goalNumber / 28;
                    }
                    else if(indicator.goalPeriod == 'week'){
                      dailyGoal = indicator.goalNumber / 7;
                    }

                    let daysInPeriod

                    if(this.dashboardDataFilter.type == 'year'){
                      daysInPeriod = 365;
                    }
                    if(this.dashboardDataFilter.type == 'quarter'){
                      daysInPeriod = 91.25;
                    }
                    if(this.dashboardDataFilter.type == 'month'){
                      daysInPeriod = moment(this.dashboardDataFilter.year+"-"+this.dashboardDataFilter.number, "YYYY-MM").daysInMonth();
                    }
                    else if(this.dashboardDataFilter.type == 'fourWeeks'){
                      daysInPeriod = 28;
                    }
                    else if(this.dashboardDataFilter.type == 'week'){
                      daysInPeriod = 7;
                    }

                    // console.log("dailyGoal: ", dailyGoal)
                    // console.log("daysInPeriod: ", daysInPeriod)

                  
                    let achievedNumber = indicator.dataType == 'numbers' ? indicator.number : indicator.revenue;
                    let goalNumber = daysInPeriod * dailyGoal; // 

                    // console.log("achievedNumber: ", achievedNumber)
                    // console.log("goalNumber: ", goalNumber)


                    if(indicator.goalType == 'number'){
                      indicator.goal = ((achievedNumber / goalNumber) * 100).toFixed(0);
                      indicator.goalRadial = new Array();
                      indicator.goalRadial.push((((achievedNumber / goalNumber) * 100).toFixed(0)));
                    }

                  }

                }
              })
              .then(()=>{
                counter++
                if(counter== indicators.length){
                  resolve()
                }
              })
              .catch(error=> {
                counter++
                if(counter== indicators.length){
                  resolve()
                }
                console.error("Error getting indicator data from bigquery: ", error);
                console.error(error.response.data);
              })
            })
          })
          .catch(error=> {
            console.error("Error getting ID Token", error);
          });
        }
        else{
          resolve()
        }
      })
      .then(()=>{
        this.dashboardDataFilter.loaded = true;
        this.dashboardFullLoaded = true;
        this.dataLoading = false;
      })
    },

    getRegisterRevenueTotal(key){
      let total = 0;
      this.registerData.revenue.forEach(revenue=>{
        total = revenue[key] ? total + revenue[key] : total;
      })
      return total > 0 ?  this.formatReportNumber(total ): "-";
    },

    getRegisterRevenueExpensesTotal(){
      let total = 0;
      this.registerData.expenses.forEach(expense=>{
        total = total + expense.expense ;
      })
      return total > 0 ?  this.formatReportNumber(total ): "-";
    },

    getRevenueTotal(key){
      let total = 0;
      this.revenueOverview.quarters.forEach(quarter=>{
        total = quarter[key] ? total + quarter[key] : total;
      })
      return total > 0 ?  this.formatReportNumber(total ): "-";
    },

    getDashboardData(){
      this.dataLoading = true;
      this.dashboardSkeletonLoaded = false;
      this.dashboardFullLoaded = false;
      let type = "company"; // Later verschil maken in barometers per company en per branch;
      let collection = type == 'company' ? 'companies' : 'branches';
      let docId = type == 'company' ? this.$store.state.activeCompany.id : this.$store.state.activeBranch.id;
      this.indicators = new Array();

      db.collection(collection)
      .doc(docId)
      .collection("indicators")
      .where("active", "==", true)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let indicator = doc.data();
          indicator.i = doc.id;
          this.indicators.push(indicator);
        });
      })
      .then(()=>{
        this.dashboardSkeletonLoaded = true;
        // this.dataLoading = false;
        this.getIndicatorData(this.indicators);   
      })  
      .catch((err) => {
        console.error("Error getting indicators", err);
      });
    },
  },

  computed: {
    selectedView(){
        return id => {
        if(this.activeFilter == id){
          return true;
        }
        else {
          return false;
        }
      }
    },

    employeesSingle(){
      let employeesSingle = new Array();
      this.employees.forEach(employee=>{
        if(employee.id){
          employeesSingle.push(employee)
        }
      })
      return employeesSingle;
    },

    filteredCompanies(){
      if (this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies;
      }
    },

    version() {
      return this.$store.state.version;
    },
    companyName(){
      return this.$store.state.companyName;
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userRoleNumber(){
      return this.$store.state.userRoleNumber;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    companySubscription() {
      return this.$store.state.companySubscription;
    },
    companyPackages() {
      return this.$store.state.companyPackages;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>

<style>
  .captialize-first-word{
    font-size: 12px;
  }
  .captialize-first-word:first-letter {
    text-transform: capitalize!important
  }
  .revenue-overview-table td{
    font-size: 12px!important;
    padding: 0 8px!important;
    height: 38px;
  }

  .revenue-overview-table table,
  .revenue-overview-table.v-data-table{
    background-color: rgba(0,0,0,0)!important;
    background: none!important
  }

  .revenue-overview-table td,
  .revenue-overview-table td span,
  .revenue-overview-table td p,
  .revenue-overview-table td div{
    font-size: 12px!important;
    vertical-align: middle
  }
  .revenue-overview-table th{
    padding: 0 8px!important;
    height: 38px;
  }
  .revenue-overview-table th span{
    font-weight: bold;
  }

  .vue-grid-item.vue-grid-placeholder {
    background: var(--primary-color);
  }

  .custom-tooltip-data-color{
    width: 18px;
    height: 18px;
    margin: 5px 5px 0 0;
    display: block;
    float: left;
    border-radius: 5px;
  }

  .custom-tooltip-data-name{
    display: block;
    float: left;
    font-weight: bold;
    width: 100px;
    font-size: 11px;
    height: 28px;
    line-height: 28px
  }
  .custom-tooltip-data-value{
    display: block;
    float: left;
    width: 60px;
    font-size: 11px;
    height: 28px;
    text-align: right;
    line-height: 28px
  }

  .custom-tooltip-data-wrapper{
    width: 185px;
    height: 28px;
  }
  .custom-tooltip-data-wrapper.hide-total,
  .custom-tooltip-data-wrapper.hide-message{
    display: none
  }

  .custom-tooltip-data-wrapper.message{
    font-size: 12px;
    height: 28px;
    line-height: 28px
  }
  .custom-tooltip-data-wrapper.total{
    border-top: 1px solid #333
  }

  .custom-tooltip-data-wrapper:after{
    clear: both;
    content: "";
    display: block;
  }

  .custom-data-tooltip-header,
  .custom-data-tooltip-header .day{
    background: var(--background-color);
    font-weight: bold;
    padding: 5px;
    font-size: 12px;
  }

  /* .custom-data-tooltip-header.today,
  .custom-data-tooltip-header.today .day{
    background: var(--primary-color);
    color: #fff;
  } */
  .custom-data-tooltip-header.today .day,
  .custom-data-tooltip-header .day{
    text-transform: capitalize!important;
  }

  .custom-data-tooltip-content{
    padding: 5px;
  }

  .custom-tooltip-data-wrapper.total .custom-tooltip-data-value{
    font-weight: bold;
  }

  .actual-chart-height .apexcharts-text.apexcharts-xaxis-label {
    text-transform: capitalize
  }

  .tooltip-today{
    background-color: var(--tertiary-color);
    color: #fff;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: bold
  }


</style>