import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import axios from "axios";
import "@firebase/firestore";
import firebase from "firebase/app";
import "firebase/auth";
import router from "../router";
import db from "../firebase/init.js";
import i18n, { selectedLocale } from "i18n";
// import { stat } from "original-fs";
import { createNewLog } from '@/modules/customLogger'

Vue.use(Vuex);

export default new Vuex.Store({ 
  state: {
    // Session Settings
    critcalDataLoaded: false,
    activeCompany: new Object(),
    activeBranch: new Object(),
    presentBranchEmployees: new Array(),
    activeBranchEmployees: new Array(),
    activeBranchEmployeesLastUpdated: null,
    companyBranches: new Array(),
    businessHours: new Array(),
    settingsBranch: new Object(),
    activeCompanyTags: new Array(),
    companyCustomTreatmentTypes: new Array(),
    brancheMessagesSettings: null,
    error: false,
    errorData: null,
    menuOpen: false,
    snackbarInfo: {text: "test", color: "success", timeout: '3000', show: false },
    nfcId: null,
    connectedEmployee: null,    
    version: null,
    locale: selectedLocale,
    nativeLanguage: "nl",
    posPresets: new Array(),
    themeModus: 'light',
    generalTreatmentTypes: new Array(),

    // Origin logged in Userdata
    userId: null,
    userUid: null,
    userRole: null,
    userRoleNumber:null,
    userName: null,
    userPhoto: null,
    userFirstName: null,
    userLastName: null,
    userCompanies: new Array(),
    userBranches: new Array(),
    userLanguage: null,
    userCompany: null,
    userRules: null,
    userAbb: null,

    // Active Userdata (can change on takeover)
    activeUserId: null,
    activeUserUid: null,
    activeUserRole: null,
    activeUserRoleNumber:null,
    activeUserName: null,
    activeUserPhoto: null,
    activeUserFirstName: null,
    activeUserLastName: null,
    activeUserCompanies: new Array(),
    activeUserBranches: new Array(),
    activeUserLanguage: null,
    activeUserCompany: null,
    activeUserRules: null,
    activeUserAbb: null,

    // Active company data
    companyTheme: 'thrive',
    companySubscription: null,
    companyPackages: null,
    companyLanguages: new Array(),
    allCompanyEmployees: new Array(),

    // Cut, Copy and Save
    treatmentCopyId: null, // BookingSummary Id saved for pasting on different day
    treatmentCutId: null, // BookingSummary Id saved for pasting on different day
    clientSaveData : null, // Clientdata Saved form Widgetattemt to use in BookingWidgetCalendar

    // Developer Settings
    demoMode: false,
    connectionsApi: {
      live: true,
      url: 'https://connections.thrive365.nl'
    },
    messagesApi:{
      live: true,
      url: 'https://messages.thrive365.nl'
    },
    reportsApi:{
      live: true,
      url: 'https://data.thrive365.nl'
    },
    locationsApi:{
      live: true,
      url: 'https://locations.thrive365.nl'
    },
    restApi:{
      live: true,
      url: 'https://appoint.thrive365.nl'
    },
    uploadApi:{
      live: true,
      url: 'https://upload.thrive365.nl'
    },
    calendarApi:{
      live: true,
      url: 'https://rest.thrive365.nl'
    },
    errorApi:{
      live: true,
      url: 'https://stack.thrive365.nl'
    },
    calendarApiCache: false,
    vatItems: new Array(),

    // DEPRACTED
    companyName: null,
    token: null,
    preferredBranch: null,

  },

  mutations: {
    authUser(state, userData) {
      state.userName = userData.userName;
      state.userPhoto = userData.userPhoto;
      state.token = userData.token;
      state.userRole = userData.userRole;
      state.userRoleNumber = userData.userRoleNumber;
      state.userBranches = userData.userBranches;
      state.userCompany = userData.userCompany;
      state.locale = userData.userLanguage
      state.userLanguage = userData.userLanguage
      state.userId = userData.userId;
      state.userFirstName = userData.userFirstName;
      state.userLastName = userData.userLastName;
      state.userAbb = userData.userAbb;
      state.nativeLanguage = userData.userLanguage ? userData.userLanguage.toLowerCase(): 'nl';
      state.userUid = userData.userUid;

      state.activeUserName = userData.userName;
      state.activeUserPhoto = userData.userPhoto;
      state.token = userData.token;
      state.activeUserRole = userData.userRole;
      state.activeUserRoleNumber = userData.userRoleNumber;
      state.activeUserBranches = userData.userBranches;
      state.activeUserCompany = userData.userCompany;
      state.activeUserId = userData.userId;
      state.activeUserFirstName = userData.userFirstName;
      state.activeUserLastName = userData.userLastName;
      state.activeUserAbb = userData.userAbb;
      state.activeUserLanguage = userData.userLanguage;
      state.activeUserUid = userData.userUid;
    },

    setBusinessHours(state, businessHours) {
      state.businessHours = businessHours;
    },

    settingBranch(state, settingsBranch) {
      state.settingsBranch = settingsBranch;
    },


    connectionsApiSet(state, status) {
      state.connectionsApi = status.status;
    },

    messagesApiSet(state, status) {
      state.messagesApi = status.status;
    },

    locationsApiSet(state, status) {
      state.locationsApi = status.status;
    },

    reportsApiSet(state, status) {
      state.reportsApi = status.status;
    },

    restApiSet(state, status) {
      state.restApi = status.status;
    },

    calendarApiSet(state, status) {
      state.calendarApi = status.status;
    },

    uploadApiSet(state, status) {
      state.uploadApi = status.status;
    },

    errorApiSet(state, status) {
      state.errorApi = status.status;
    },

    calendarApiCacheSet(state, status) {
      state.calendarApiCache = status.status;
    },

    updateLocale(state, newLocale) {
      state.locale = newLocale.newLocale;
    },

    setUserRules(state, userRules) {
      state.userRules = userRules.userRules;
    },

    version(state, versionData) {
      state.version = versionData.version;
    },

    addPosPreset(state, posPreset) {
      state.posPresets.push(posPreset);
    },

    setSnackBar(state, snackbarData) {
      state.snackbarInfo = snackbarData;
    },

    authUserError(state, userData) {
      state.error = userData.error;
      state.errorData = userData.errorData;
    },

    menuOpen(state, menuData) {
      state.menuOpen = menuData.status;
    },

    companyData(state, companyData) {
      state.companyName = companyData.companyName;
      state.companySubscription = companyData.companySubscription;
      state.companyTheme = companyData.companyTheme;
      state.companyLanguages = companyData.companyLanguages;
    },

    connectEmployee(state, employee) {
      state.connectedEmployee = employee;
      state.activeUserId = employee.userId;
      state.activeUserRole = employee.userRole;
      state.activeUserUid = employee.uid;
      state.activeUserRoleNumber = employee.userRoleNumber;
      state.activeUserName = employee.id;
      state.activeUserPhoto = employee.photo;
      state.activeUserFirstName = employee.name;
      state.activeUserLastName = employee.surname;
      state.activeUserCompanies = employee.accessCompanies;
      state.activeUserBranches = employee.branches;
      state.activeUserLanguage = employee.language;
      state.activeUserCompany = employee.company;
      state.activeUserRules = employee.userRules ? employee.userRules : null;
      state.activeUserAbb = employee.userAbb;
      state.nativeLanguage = employee.language ? employee.language.toLowerCase(): 'nl';
    },

    disconnectEmployee(state) {
      state.connectedEmployee = false;
      state.activeUserId = state.userId;
      state.activeUserRole = state.userRole;
      state.activeUserRoleNumber = state.userRoleNumber
      state.activeUserName = state.userName;
      state.activeUserUid = state.userUid;
      state.activeUserPhoto = state.userPhoto;
      state.activeUserFirstName = state.userFirstName;
      state.activeUserLastName = state.userLastName;
      state.activeUserCompanies = state.userCompanies;
      state.activeUserBranches = state.userBranches;
      state.activeUserLanguage = state.userLanguage;
      state.activeUserCompany = state.userCompany;
      state.activeUserRules = state.userRules;
      state.activeUserAbb = state.userAbb;
      state.nativeLanguage = state.userLanguage? state.userLanguage.toLowerCase(): 'nl';
    },

    connectNFC(state, id) {
      state.nfcId = id.id;
    },

    disconnectNFC(state) {
      state.connectedEmployee = null;
      state.locale = _.cloneDeep(state.userLanguage);
      state.nfcId = null;
    },

    userPhoto(state, userPhoto) {
      state.userPhoto = userPhoto.userPhoto;
    },

    clearAuthData(state) {
      state.userName = null;
      state.userPhoto - null;
      state.token = null;
      state.userRole = null;
      state.companyTheme = 'thrive';
      state.themeModus = 'light';
      state.userRoleNumber = null;
      state.userCompanies = null;
      state.userBranches = null;
      state.userCompany = null;
      state.companyBranches = new Array();
      state.userId = null;
      state.userFirstName = null;
      state.userLastName = null;
      state.userAbb = null;
      state.error = false;
      state.errorData = null;
      state.userRules = null;
      state.critcalDataLoaded = false;
      state.snackbarInfo = {text: "test", color: "success", timeout: '3000', show: false };
      state.activeCompanyTags = new Array();
      state.companyCustomTreatmentTypes = new Array();
      state.allCompanyEmployees = new Array();
      state.presentBranchEmployees = new Array();
      state.brancheMessagesSettings = null;
      state.treatmentCopyId = null;
      state.treatmentCutId = null;
      state.clientSaveData = null;
      state.vatItems = new Array();
    },

    setThemeModus(state, modus) {
      state.themeModus = modus.modus;
    },

    setVatItems(state, items) {
      state.vatItems = items.vatItems;
    },

    demoMode(state, demoData) {
      state.demoMode = demoData.status;
    },

    setUserCompaniesData(state, data) {
      state.userCompanies = data.userCompanies;
      state.activeCompany = data.activeCompany;
      state.activeCompanyTags = data.activeCompanyTags;
      
    },

    setActiveCompany(state , data) {
      state.activeCompany = data.activeCompany;
      state.activeCompanyTags = data.activeCompanyTags;
      state.companyCustomTreatmentTypes = data.companyCustomTreatmentTypes.sort((a, b) => a.name.localeCompare(b.name));
      return
    },

    setActiveBranch(state , data) {
      state.activeBranch = data.activeBranch;
      return 
    },

    setUserBranches(state , data) {
      state.userBranches = data.userBranches;
      state.activeUserBranches = data.userBranches;
      return 
    },

    setBrancheMessagesSettings(state , data) {
      state.brancheMessagesSettings = data.settings;
      return 
    },

    setBranches(state , data) {
      state.userBranches = data.userBranches;
      state.activeUserBranches = data.userBranches;
      return 
    },

    setCompanyCustomTreatmentTypes(state , data) {
      state.companyCustomTreatmentTypes = data.companyCustomTreatmentTypes;
    },

    setGeneralTreatmentTypes(state , data) {
      state.generalTreatmentTypes = data.generalTreatmentTypes;
    },

    setActiveBranchEmployees(state , data) {
      state.activeBranchEmployees = data.activeBranchEmployees;
      state.activeBranchEmployeesLastUpdated = moment().format("YYYY-MM-DD");
    },

    setPresentBranchEmployees(state , data) {
      state.presentBranchEmployees = data.presentBranchEmployees;
    },
    
    setActiveCompanyTags(state , data) {
      state.activeCompanyTags = data.activeCompanyTags; 
    },

    setAllCompanyEmployees(state , data) {
      state.allCompanyEmployees = data.allCompanyEmployees;
    },

    companyPackages(state, companyDataPackages) {
      let companyPackages = {
        type: null,
        list: new Object()
      };

      function getObjectSize(obj) {
        var size = 0, key;
        for (key in obj) {
          if (obj.hasOwnProperty(key)) size++;
        }
        return size;
      }

      if (getObjectSize(companyDataPackages) > 0) { // Packages gevonden. Nu kijken of ze nog actief zijn.
        let activePackages = 0;
        let today = moment().unix();
        let counter = 0;
        // Door Object heen loopen met Promise
        new Promise((resolve, reject) => {
          for (var key in companyDataPackages) {
            if (companyDataPackages[key].endDate) { // Kijken of pakket opgezegd is.
              if (companyDataPackages[key].endDate.seconds > today) {
                // Pakket is opgezegd. Kijken of de einddatum in de toekomst ligt
                companyPackages.list[key] = true;
                activePackages++;
              }
            } else {
              companyPackages.list[key] = true;
              activePackages++;
            }
            counter++;
            if (counter == getObjectSize(companyDataPackages)) {
              resolve();
            }
          }
        })
        .then(() => {
          if (activePackages > 0) { // BEtaalde paketten
            companyPackages.type = "paidVersion";
          }
          else { // Geen actieve pakketen gevonden. De gebruiker maakt gebruik van de gratis versie
            companyPackages.type = "freeVersion";
          }
        });
      }
      else {// Geen pakketen gevonden. De gebruiker maakt gebruik van de gratis versie
        companyPackages.type = "freeVersion";
      }
      state.companyPackages = companyPackages;
    },
  },

  actions: {
    menuStatus({ commit }, status) {
      commit("menuOpen", {
        status: status,
      });
    },

    companyPackages({ commit }, companyPackages) {
      commit("companyPackages",companyPackages);
    },

    setSnackBar({ commit }, snackbarData) {
      commit("setSnackBar", {
        snackbarData: snackbarData,
      });
    },

    /** Remove the cache of branch after changes in employees availability
     * @param branchId = Firebase DocId of the Branch
     * @param start = Startdate of the cache removal formatted as YYYY-MM-DD
     * @param end = Enddate of the cache removal formatted as YYYY-MM-DD
     * @example - await this.$store.dispatch('removeCalendarResourceCache', {branchId: 'lxNH331M7l5SPEGrbqPs', start:"2024-05-28", end:"2024-05-29"}))
     */
    async removeCalendarResourceCache({},data){
      if(data.branchId && data.start){ // Check if required data is present
        let startDate = moment(data.start);
        let endDate = data.end ? moment(data.end): moment(data.start);

        if(startDate.isValid() && endDate.isValid()){ // Check if both dates are valid
          let dates = new Array();
          for (let m = moment(startDate); m.isBefore(moment(endDate).add(1, 'days')); m.add(1, 'days')) { // Create array of dates from startDate to endDate
            dates.push(m.format('YYYY-MM-DD'));
          }          
          if(dates && dates.length > 0){
            await new Promise((resolve)=>{
              let counter = 0;
              dates.forEach((date)=>{
                db.collection("fetchedResourceCache")
                .doc(data.branchId)
                .collection("dates")
                .doc(date)
                .delete()
                .then(()=>{
                  counter++
                  if(counter == dates.length){
                    resolve()
                  }
                })
                .catch((error) => {
                  console.error(`Error removing fetchedResourceCache from ${data.branchId} at ${date}: `, error);
                  counter++
                  if(counter == dates.length){
                    resolve()
                  }
                });
              })
            })
          }
          return `Succes`
        }
        else{
          return "Invalid date"
        }
      }
      else{
        return "No all required data"
      }
    },

    async getBranchMessageSettings({ commit, state }) {
      let brancheMessagesSettings = null;
      await db.collection("branches")
      .doc(state.activeBranch.id)
      .collection("messages")
      .doc("settings")
      .get()
      .then(doc=>{
        if(doc.exists){
          brancheMessagesSettings = doc.data()
        }   
      })
      .catch(err=>{
        console.error("Error getting Branch Messages Settingsdata:", err);
      })

      await commit("setBrancheMessagesSettings", {
        settings : brancheMessagesSettings
      })

      return
    },

    async downloadBranchTime({ commit, state }) {
      // console.log("Active Branch ID: ", state.activeBranch.id);
      let businessHoursBranch = new Array();
      let settingsBranch = new Object();
      const getBusinessHours = async () => {
        try {
          const doc = await db.collection("branches")
          .doc(state.activeBranch.id)
          .collection("businessHours")
          .doc("default")
          .get();

          if(doc && doc.exists){
            settingsBranch = doc.data();
            businessHoursBranch = [
              { daysOfWeek: [0], startTime: settingsBranch.branchTime.Sun.start, endTime: settingsBranch.branchTime.Sun.end },
              { daysOfWeek: [1], startTime: settingsBranch.branchTime.Mon.start, endTime: settingsBranch.branchTime.Mon.end },
              { daysOfWeek: [2], startTime: settingsBranch.branchTime.Tue.start, endTime: settingsBranch.branchTime.Tue.end },
              { daysOfWeek: [3], startTime: settingsBranch.branchTime.Wed.start, endTime: settingsBranch.branchTime.Wed.end },
              { daysOfWeek: [4], startTime: settingsBranch.branchTime.Thu.start, endTime: settingsBranch.branchTime.Thu.end },
              { daysOfWeek: [5], startTime: settingsBranch.branchTime.Fri.start, endTime: settingsBranch.branchTime.Fri.end },
              { daysOfWeek: [6], startTime: settingsBranch.branchTime.Sat.start, endTime: settingsBranch.branchTime.Sat.end },
            ];
          }
          else{
            settingsBranch = {
              branchTime: {
                Mon: {start: "00:00", end: "00:00"},
                Tue: {start: "00:00", end: "00:00"},
                Wed: {start: "00:00", end: "00:00"},
                Thu: {start: "00:00", end: "00:00"},
                Fri: {start: "00:00", end: "00:00"},
                Sat: {start: "00:00", end: "00:00"},
                Sun: {start: "00:00", end: "00:00"}
              }
            }
            businessHoursBranch = [
              { daysOfWeek: [0], startTime: "00:00", endTime: "00:00" },
              { daysOfWeek: [1], startTime: "00:00", endTime: "00:00" },
              { daysOfWeek: [2], startTime: "00:00", endTime: "00:00" },
              { daysOfWeek: [3], startTime: "00:00", endTime: "00:00" },
              { daysOfWeek: [4], startTime: "00:00", endTime: "00:00" },
              { daysOfWeek: [5], startTime: "00:00", endTime: "00:00" },
              { daysOfWeek: [6], startTime: "00:00", endTime: "00:00" },
            ];
          }
    
          commit('settingBranch', settingsBranch);
          commit('setBusinessHours', businessHoursBranch);
        }
        catch (err) {
          console.error("Error Loading Branch BusinessHours data: ", err);
        }
      };
  
    
  
      await getBusinessHours();
      console.log("All Branch Settings Loaded");
      return
    },

    /**
     * Triggered when company data is changed
     * @param {string} companyId 
     * @returns 
     */
    async updateActiveCompData({ commit }, companyId) {
      console.log("updateActiveCompData")
      let activeCompany = new Object();
      await db.collection("companies")
      .doc(companyId)
      .get()
      .then(doc => {
        activeCompany = doc.data();
        activeCompany.id = doc.id;
      })

      state.activeCompany = activeCompany
      await Promise.all([dispatch('getCustomTreatmentTypes'), dispatch('getActiveCompanyTags'), dispatch('getActiveCompanyEmployees')])
      return
    },

    /**
     * Triggered when company data is changed
     * @param {string} branchId 
     * @returns 
     */
    async updateActiveBranchData({ commit, dispatch }, branchId) {
      await dispatch("getActiveBranchData", branchId) // Set Active Branch Data
      await this.dispatch("downloadBranchTime"); // Download branch Time
      return
    },

    async changeActiveCompData({ commit, state, dispatch }, companyId) {
      let activeCompany = new Object();
      await db.collection("companies")
      .doc(companyId)
      .get()
      .then(doc => {
        activeCompany = doc.data();
        activeCompany.id = doc.id;
        window.localStorage.setItem('selectedThriveCompany', doc.id);
      })
      let localStorageBrachKey = `selectedThriveBranch${activeCompany.id}`;
      let savedBranch = window.localStorage.getItem(localStorageBrachKey) ? window.localStorage.getItem(localStorageBrachKey) : null;
      let savedBranchData = null;
      let userBranches = new Array();

      await db.collection("branches")
      .where("companyId", "==", activeCompany.id)
      .where("active", "==", true)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let branch = new Object();
          branch.name = doc.data().name;
          branch.id = doc.id;
          userBranches.push(branch);
          if(savedBranch){
            if(branch.id == savedBranch){
              savedBranchData = doc.data();
              savedBranchData.id = doc.id;
            }
          }
          else{
            savedBranch = doc.id;
            savedBranchData = doc.data();
            savedBranchData.id = doc.id;
          }
        });
      })
      .catch((error)=>{
        console.error("Error getting company data: ", error)
      })
      state.activeCompany = activeCompany
      state.companyBranches = userBranches;
      
      
      await commit("setUserBranches", {
        userBranches: userBranches
      })
      console.log("Set active Branch 2")
      await commit("setActiveBranch", {
        activeBranch: savedBranchData
      })
      await Promise.all([dispatch('getCustomTreatmentTypes'), dispatch('getActiveCompanyTags'), dispatch("downloadBranchTime"), dispatch("getBranchMessageSettings"), dispatch("getActiveBranchEmployees", 'change'),dispatch("getPresentBranchEmployees")])
      return
    },

    async changeActiveBranchData({ commit, state, dispatch }, branchId) {
      console.log("changeActiveBranchData")
      let add = state.userBranches.filter(branch=> {return branchId == branch.id}).length > 0 ? false : true;
      let userBranches = state.userBranches;

      let activeBranch = new Object();
      await db.collection("branches")
      .doc(branchId)
      .get()
      .then(doc => {
        activeBranch = doc.data();
        activeBranch.id = doc.id;
        if(add){
          let branch = {
            id: doc.id,
            name: doc.data().name
          };
          userBranches.push(branch)
          commit("setUserBranches", {
            userBranches: userBranches
          })
        }
        let localStorageBrachKey = `selectedThriveBranch${state.activeCompany.id}`;
        window.localStorage.setItem(localStorageBrachKey, doc.id);
      })
      console.log("Set active Branch 3")
      await commit("setActiveBranch", {
        activeBranch: activeBranch
      })

      await Promise.all([dispatch("downloadBranchTime"),dispatch("getBranchMessageSettings"), dispatch("getPresentBranchEmployees", "change"),dispatch("getActiveBranchEmployees",'change'),dispatch("getPresentBranchEmployees",'change')])

      return
    },

    async loadGeneralVat({ commit }) {
      let vatItems = new Array();
      await db.collection("vat").get()
      .then((snap)=>{
        snap.forEach((doc) => {
          let vat = doc.data();
          vat.id = doc.id;
          vatItems.push(vat);
        })
      })

      await commit("setVatItems", {
        vatItems: vatItems
      })
      return
    },
 
    setThemeModus({ commit }, modus) {
      commit("setThemeModus", {
        modus: modus
      });
    },

    demoMode({ commit }, status) {
      commit("demoMode", {
        status: status
      });
    },

    async tryAutoLogin({ commit, dispatch, state }, userData) {    
      // console.log("tryAutoLogin")
      if (state.userId == null) {
        let userId = null;
        let userUid = null;
        let userName = null;
        let userPhoto = null;
        let userFirstName = null;
        let userLastName = null;
        let userRole = null;
        let userRoleNumber = null;
        let userCompanies = new Array();
        let userBranches = new Array();
        let userCompany = null;
        let userAbb = null;
        let userPermissions = null;
        let userLanguage = 'NL';
        let employeeId = null;
        let userRules = null;
        
        let userRoleNumbers = {
          developer: 0,
          superadmin: 1,
          admin: 2,
          manager: 3,
          branch: 4, 
          employee: 5
        };

        dispatch('loadGeneralVat')  
        dispatch('getGeneralTreatmentTypes')   
        
        
        await db.collection("users")
        .where("user_id", "==", userData.user.uid)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            userUid = doc.data().user_id;
            userName = doc.id;
            userId = doc.id;
            userPhoto = doc.data().userPhoto ? doc.data().userPhoto: null;
            userFirstName = doc.data().firstName;
            userLastName = doc.data().lastName;
            userRole = doc.data().userRole;
            userBranches = doc.data().branches ? doc.data().branches : null;
            userRoleNumber = userRoleNumbers[doc.data().userRole] >= 0 ? userRoleNumbers[doc.data().userRole] : 10;
            userPermissions = doc.data().userPermissions;
            userLanguage = doc.data().language ? doc.data().language : 'NL';
            userCompanies = doc.data().accessCompanies;
            userCompany = doc.data().companyId;
            employeeId = doc.data().employeeId;
            userAbb = doc.data().firstName.charAt(0) + doc.data().lastName.charAt(0);
            userRules = doc.data().userRules ? doc.data().userRules : null;
          })
        })
        .catch((error)=>{
          console.error("Error getting user data: ", error)
        })

        let activeUserCompanies = new Array();   
        let savedCompany = window.localStorage.getItem('selectedThriveCompany') ? window.localStorage.getItem('selectedThriveCompany') : null;
        let savedCompanyData = null;    
        let counter = 0;
        await new Promise((resolve)=>{
          userCompanies.forEach(company=>{
            db.collection("companies")
            .doc(company)
            .get()
            .then(doc => {
              let company = new Object();
              company.name = doc.data().name;
              company.id = doc.id;
              activeUserCompanies.push(company);
              if(savedCompany && userCompanies.filter(activeUserCompany=> {return savedCompany == activeUserCompany}).length > 0 ){
                if(company.id == savedCompany){
                  savedCompanyData = doc.data();
                  savedCompanyData.id = doc.id;
                }
              }
              else{
                if(company.id == userCompany){
                  savedCompanyData = doc.data();
                  savedCompanyData.id = doc.id;
                  window.localStorage.setItem('selectedThriveCompany', doc.id);
                }
              }
              if(company.id == userCompany){
                state.companyName = doc.data().name;
                state.companySubscription = doc.data().subscription;
                state.companyTheme = doc.data().theme ? doc.data().theme : 'thrive';
                state.companyLanguages = doc.data().languages;
                this.dispatch("companyPackages", doc.data().subscription.packages)
                
                if (state.userRole == 'branch') {
                  if (state.userRules) {
                    if (!state.userRules.companyRules) {
                      state.activeIserRules = savedCompanyData.userRules;
                    }
                  }
                }
              } 
              
              counter++
              if(counter == userCompanies.length){
                // console.log("resolve")
                resolve()
              }
            });
          })
        })
        
        state.activeCompany = savedCompanyData;
        state.userCompanies = activeUserCompanies;
        state.activeUserCompanies = activeUserCompanies;
        let localStorageBrachKey = `selectedThriveBranch${savedCompanyData.id}`;
        state.companyLanguages = savedCompanyData.languages ? savedCompanyData.languages : state.companyLanguages;
        let savedBranch = window.localStorage.getItem(localStorageBrachKey) ? window.localStorage.getItem(localStorageBrachKey) : null;
        let savedBranchData = null;
        let activeUserBranches = new Array();
        let companyBranches = new Array();
        let branchCounter = 0;
        


        Promise.all([dispatch('getCustomTreatmentTypes'), dispatch('getActiveCompanyTags'), dispatch('getActiveCompanyEmployees')])
        
  
        if(!state.userBranches || state.userBranches.length == 0){ // Er is nog geen branchdata geladen
          await db.collection("branches")
          .where("companyId", "==", savedCompanyData.id)
          .where("active", "==", true)
          .get()
          .then((snap) => {
            snap.forEach((doc) => {
              let branch = new Object();
              branch.name = doc.data().name;
              branch.id = doc.id;
              activeUserBranches.push(branch);
              companyBranches.push(branch);
              branchCounter++
              if(savedBranch){ // Er al een branchId opgeslagen die gebruikt moet worden
                if(branch.id == savedBranch || (!savedBranchData && branchCounter == snap.size)){ // Opgeslagen branchId is een match of dit is de laatste branch en er is nog geen match geweest. 
                  savedBranchData = doc.data();
                  savedBranchData.id = doc.id;
                }
              }
              else{
                savedBranch = doc.id;
                savedBranchData = doc.data();
                savedBranchData.id = doc.id;
              }
            });
          })
          .catch((error)=>{
            console.error("Error getting branch data: ", error)
          })
        }
        else{
          console.log("state.userBranches[0]: ", state.userBranches[0])
          await db.collection("branches").doc(state.userBranches[0])
          .get()
          .then((doc) => {
            savedBranch = doc.id;
            savedBranchData = doc.data();
            savedBranchData.id = doc.id;
          })
          .catch((error)=>{
            console.error("Error getting branch data: ", error)
          })
        }

        state.userBranches = activeUserBranches;
        state.companyBranches = companyBranches;
        state.activeUserBranches = activeUserBranches;
        state.activeBranch = savedBranchData;
        
        let loggedInUserData = {
          userId: userId,
          userUid: userUid,
          userPhoto: userPhoto,
          userName: userName,
          userFirstName: userFirstName,
          userLastName: userLastName,
          userRole: userRole,
          userRoleNumber: userRoleNumber,
          userLanguage: userLanguage,
          userPermissions: userPermissions,
          userCompany: userCompany,
          userBranches: activeUserBranches,   
          userRules: userRules,    
          userAbb: userAbb,
        };    
        
        Promise.all([dispatch("downloadBranchTime"), dispatch("getBranchMessageSettings")])

        commit("authUser", loggedInUserData);
        await Promise.all([dispatch("getActiveBranchEmployees",'new'),dispatch("getPresentBranchEmployees",'new')])
        state.critcalDataLoaded = true;
       
       

        if (employeeId) { // Alle data van employee binnenhalen. Ook welke branch hij of zij werkt.
          db.collection("employees")
          .doc(employeeId)
          .get()
          .then((doc) => {
            commit("userPhoto", {
              userPhoto: doc.data().employeePhoto,
            });
          })
          .catch((error)=>{
            console.error("Error getting employee data: ", error)
          })
        }
        return 
      }
    },

    async getCustomTreatmentTypes({ state, commit }) {
      let companyCustomTreatmentTypes = new Array();
      await db.collection("companies")
      .doc(state.activeCompany.id)
      .collection("customTreatmentTypes")
      .where("deleted.deleted", "==", false)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let type = doc.data();
          type.id = doc.id;
          companyCustomTreatmentTypes.push(type)
        })
      })
      companyCustomTreatmentTypes = companyCustomTreatmentTypes.sort((a, b) => a.name.localeCompare(b.name));

      commit("setCompanyCustomTreatmentTypes", {
        companyCustomTreatmentTypes: companyCustomTreatmentTypes
      })
      return
    },

    async getGeneralTreatmentTypes({ commit }) {
      let generalTreatmentTypes = new Array();
      await db.collection("treatmentTypes")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let type = doc.data();
          type.id = doc.id;
          generalTreatmentTypes.push(type)
        })
      })
      generalTreatmentTypes = generalTreatmentTypes.sort((a, b) => a.weight < b.weight ? -1 : 1)

      commit("setGeneralTreatmentTypes", {
        generalTreatmentTypes: generalTreatmentTypes
      })
      return
    },


    async getActiveCompanyTags({ state, commit }) {
      let activeCompanyTags = new Array();
      await db.collection("companies")
      .doc(state.activeCompany.id)
      .collection("tags")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let tag = doc.data();
          tag.id = doc.id;
          activeCompanyTags.push(tag)
        })
      })
      commit("setActiveCompanyTags", {
        activeCompanyTags: activeCompanyTags
      })
      return
    },

    async getActiveCompanyEmployees({ state, commit }) { // Get all company employees ever
      let allCompanyEmployees = new Array();
      await db.collection("employees")
      .where("companyId","==",state.activeCompany.id)
      .where("deleted.deleted","==",false)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let employee = doc.data();
          employee.id = doc.id;
          allCompanyEmployees.push(employee)
        })
      })
      commit("setAllCompanyEmployees", {
        allCompanyEmployees: allCompanyEmployees
      })
      return
    },

    async getActiveBranchData({ commit }, branchId) {
      let activeBranch = new Object();
      await db.collection("branches")
      .doc(branchId)
      .get()
      .then(doc => {
        activeBranch = doc.data();
        activeBranch.id = doc.id;
      })
      await commit("setActiveBranch", {
        activeBranch: activeBranch
      })
      return
    },

    /** Get Active Branch Employees 
     * (for schedule Time Changes and Employee select in Pay.vue)
     */

    async getPresentBranchEmployees({state, commit}){
      let presentBranchEmployees = new Array();
      try {
        const response = await axios.post(`${state.calendarApi.url}/getDayResources`, {
          date: moment().format("YYYY-MM-DD"),
          branchId: state.activeBranch.id,
          forceNewCache: true 
        }, 
        {
          timeout: 5000,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        presentBranchEmployees = response.data.employeeSchedules;
      }
      catch (error) {
        console.error("Error getDayResources API Fetching Resources in $store", error)
      }

      commit("setPresentBranchEmployees", {
        presentBranchEmployees: presentBranchEmployees
      })
    },

    //Wipe out Cache API for cached Fetched Resources
    async clearFetchedResourceCache({state, commit}){

      try {
        const response = await axios.post(`${state.calendarApi.url}/clearCacheDayResources`, {
          branchId: state.activeBranch.id,
        })
        console.log("response Clear Fetched Resource Cache: ", response)
      }
      catch (error) {
        console.error("Error clearing cache in $store", error)
      }

    },

    

    async getActiveBranchEmployees({state, commit}, type){
      if(!state.activeBranchEmployeesLastUpdated || state.activeBranchEmployeesLastUpdated != moment().format("YYYY-MM-DD") || type == 'change'){
        let activeBranchEmployees = new Array();
        await db.collection("employees")
        .where(`branches`, "array-contains", state.activeBranch.id) //Dynamisch maken
        .where("deleted.deleted","==",false)
        .where("active", "==", true)
        .where("employeeContractStart", "<=", moment().format("YYYY-MM-DD"))
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let employee = {
              id: doc.id,
              firstName: doc.data().firstName ? doc.data().firstName : "",
              lastName: doc.data().lastName ? doc.data().lastName : "",
              name: doc.data().name ? doc.data().name : "",
              publicName: doc.data().publicName ? doc.data().publicName : "",
              basicSchedule: doc.data().basicSchedule && doc.data().basicSchedule[state.activeBranch.id] ? doc.data().basicSchedule[state.activeBranch.id] : new Object(),
              id: doc.id,
              employeePhoto: doc.data().employeePhoto ? doc.data().employeePhoto : null
            } 
            activeBranchEmployees.push(employee);
          });
        });

        if(activeBranchEmployees && activeBranchEmployees.length > 0){

          //Show only employees that are still in contract
          // activeBranchEmployees = activeBranchEmployees.filter(employee => employee.employeeContractEnd == 'noEndDate' || employee.employeeContractEnd >= moment().format("YYYY-MM-DD"));

          await new Promise((resolve)=>{
            let counter = 0;
            activeBranchEmployees.forEach((employee)=>{
              db.collection("schedules")
              .where("employeeId", "==", employee.id) //Device ID
              .where("week", "==", moment().isoWeek())
              .where("year", "==", moment().year())
              .where("branchId", "==", state.activeBranch.id)
              .get()
              .then((snapshot) => {
                if (!snapshot.empty) {
                  snapshot.forEach((doc) => {
                    employee.basicSchedule = doc.data().schedule
                  })
                }
                counter++
                if(counter == activeBranchEmployees.length){
                  resolve()
                }
              })
            })
          })
        }
        commit("setActiveBranchEmployees", {
          activeBranchEmployees: activeBranchEmployees
        })
        return
      }
      else{
        return
      }
      
    },

    connectEmployee({ commit }, resource) {
      commit("connectEmployee", resource);
      commit("updateLocale", {
        newLocale: resource.language,
      });
    },

    connectNFC({ commit }, id) {
      commit("connectNFC", {
        id: id,
      });
    },

    connectionsApiSet({ commit }, status) {
      if(status == 'develop'){
        commit("connectionsApiSet", {
          status: {
            live: false,
            url: 'http://localhost:3005'
          },
        });
      }
      else{
        commit("connectionsApiSet", {
          status: {
            live: true,
            url: 'https://connections.thrive365.nl'
          },
        });
      }
    },

    messagesApiSet({ commit }, status) {
      if(status == 'develop'){
        commit("messagesApiSet", {
          status: {
            live: false,
            url: 'http://localhost:3003',
          },
        });
      }
      else{
        commit("messagesApiSet", {
          status: {
            live: true,
            url: 'https://messages.thrive365.nl'
          },
        });
      }
    },

    reportsApiSet({ commit }, status) {
      if(status == 'develop'){
        commit("reportsApiSet", {
          status: {
            live: false,
            url: 'http://localhost:9992',
          },
        });
      }
      else{
        commit("reportsApiSet", {
          status: {
            live: true,
            url: 'https://data.thrive365.nl'
          },
        });
      }
    },

    locationsApiSet({ commit }, status) {
      if(status == 'develop'){
        commit("locationsApiSet", {
          status: {
            live: false,
            url: 'http://localhost:3004',
          },
        });
      }
      else{
        commit("locationsApiSet", {
          status: {
            live: true,
            url: 'https://locations.thrive365.nl'
          },
        });
      }
    },

    restApiSet({ commit }, status) {     
      if(status == 'develop'){
        commit("restApiSet", {
          status: {
            live: false,
            url: 'http://localhost:3000'
            // url: 'http://127.0.0.1:3000',
          },
        });
      }
      else{
        commit("restApiSet", {
          status: {
            live: true,
            url: 'https://api.thrive365.nl'
          },
        });
      }
    },

    uploadApiSet({ commit }, status) {     
      if(status == 'develop'){
        commit("uploadApiSet", {
          status: {
            live: false,
            url: 'http://localhost:8181',
          },
        });
      }
      else{
        commit("uploadApiSet", {
          status: {
            live: true,
            url: 'https://upload.thrive365.nl'
          },
        });
      }
    },

    calendarApiSet({ commit }, status) {     
      if(status == 'develop'){
        commit("calendarApiSet", {
          status: {
            live: false,
            url: 'http://localhost:8811',
          },
        });
      }
      else{
        commit("calendarApiSet", {
          status: {
            live: true,
            url: 'https://rest.thrive365.nl'
          },
        });
      }
    },

    errorApiSet({ commit }, status) {     
      if(status == 'develop'){
        commit("errorApiSet", {
          status: {
            live: false,
            url: 'http://localhost:3002',
          },
        });
      }
      else{
        commit("errorApiSet", {
          status: {
            live: true,
            url: 'https://stack.thrive365.nl'
          },
        });
      }
    },

    calendarApiCacheSet({ commit }, status) {     
      commit("calendarApiCacheSet", {
        status
      });
    },

    

    disconnectNFC({ commit }) {
      commit("disconnectNFC");
      commit("disconnectEmployee");
    },

    changeLanguage({ commit }, language) {
      commit("updateLocale", {
        newLocale: language,
      });
    },

    login({ commit }, loginData) {
      let firebasePersistence = loginData.remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION // User wil be remebered in cache if loginData.remember = true 
      firebase
      .auth()
      .setPersistence(firebasePersistence)
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(loginData.email, loginData.password)
          .then(() => {
            commit("authUser", {
              authenticated: true,
            });
            commit("authUserError", {
              error: false,
              errorData: null,
            });
            // router.replace("/home");
          })
          .then(() => {
            let userFirstNameLogin = null;
            let userLastNameLogin = null;
            let userRoleLogin = null;
            let userCompanyLogin = null;
            //There is not Catch here to get the errors, please add it
            firebase.auth().onAuthStateChanged((user) => {
              try{
              if (user) {
                db.collection("users")
                .where("user_id", "==", user.uid)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    userFirstNameLogin = doc.data().firstName;
                    userLastNameLogin = doc.data().lastName;
                    userRoleLogin = doc.data().userRole;
                    userCompanyLogin = doc.data().companyId;
                  });
                })
                .then(() => {
                  let logData = {
                    superAdminAction: false, // Is action only accessible by superadmin?
                    actionType: "userAction",
                    action: "userLogin",
                    actionItem: `${userFirstNameLogin} ${userLastNameLogin}`,
                    extra:[]
                  };
                  createNewLog("info", logData);
                })
                .catch((error)=>{
                  console.error("Error getting user data: ", error)
                })
              }
            } catch (error) {
              console.error("Error in login: ", error);
            }
            })
          
          })

          .catch((error) => {
            console.error("firebase Auth Sign-in error: ", error);
            commit("authUserError", {
              error: true,
              errorData: error,
            });
            let logError = error;
            let logEmail = loginData.email;
            let logPassword = loginData.password;
            // console.error("Login error: ", error);
            // console.error("logError: ", logError);
            let logData = {
              superAdminAction: false, // Is action only accessible by superadmin?
              actionType: "userAction",
              action: "userLogin",
              actionItem: logEmail,
              extra:[
                {
                  name: "email",
                  value: logEmail
                },
                {
                  name: "password",
                  value: logPassword
                },
                {
                  name: "error",
                  value: JSON.stringify(error)
                }
              ]
            };
            createNewLog("error", logData);
          });
      })
      .catch((error) => {
        console.error(error);
      });
    },

    async userLogout({ commit }) {
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "userLogout",
        actionItem: `${this.state.userFirstName} ${this.state.userLastName}`,
        extra:[]
      };
      createNewLog("info", logData);
      
      firebase.auth().signOut()
      .then(() => {
        commit("clearAuthData");
        commit("companyData", {
          companyName: null,
          companySubscription: null,
        });
      })
      router.replace("/login");
    },
  },
});
